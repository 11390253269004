import apiCore from '@/api/api.common';
import assign from 'lodash/assign';
// import { Cache } from 'axios-extensions';
// import util from "@/util";

// const SIXTY_MINUTES = 1000 * 60 * 60;
// const CAPACITY = 100;
// const azureCache = new Cache({ ttl: SIXTY_MINUTES, max: CAPACITY });
const dftOptions = { useCache: true };

const repo = {
  apiCore: apiCore,
  getUser: getUser,
  getUserCount: getUserCount,
  getLangs: getLangs,
  getLogs: getLogs,
  getTransactionLogs: getTransactionLogs,
  updateLangValueById: updateLangValueById,
  putUser: putUser,
  updatePhoto: updatePhoto,
  updateUserById: updateUserById,
  groupLookup: groupLookup,
  forceOnboard: forceOnboard,
  forceOffboard: forceOffboard,
  company: {
    get: getCompany,
    user: {
      get: getUsersByCompanyId,
    },
  },
  employeeManagement: {
    onboard: {
      get: getOnboard,
      post: postOnboard,
      put: putOnboard,
      delete: deleteOnboard,
    },
    onboardPreset: {
      get: getOnboardPreset,
      post: postOnboardPreset,
      put: putOnboardPreset,
      delete: deleteOnboardPreset,
      detect: detectOnboardPreset,
    },
    offboard: {
      get: getOffboard,
      post: postOffboard,
      put: putOffboard,
      delete: deleteOffboard,
    },
  },
  config: {
    category: {
      get: getConfigCategory,
      post: postConfigCategory,
      patch: patchConfigCategory,
      delete: deleteConfigCategory,
    },
    client: {
      get: getConfigClient,
      post: postConfigClient,
      patch: patchConfigClient,
      delete: deleteConfigClient,
    },
    collaboration: {
      get: getConfigCollaboration,
      post: postConfigCollaboration,
      patch: patchConfigCollaboration,
      delete: deleteConfigCollaboration,
    },
    jobRole: {
      get: getConfigJobRole,
      post: postConfigJobRole,
      patch: patchConfigJobRole,
      delete: deleteConfigJobRole,
    },
    officeLocation: {
      get: getConfigOfficeLocation,
      post: postConfigOfficeLocation,
      patch: patchConfigOfficeLocation,
      delete: deleteConfigOfficeLocation,
    },
    jobTitle: {
      get: getConfigJobTitle,
      post: postConfigJobTitle,
      patch: patchConfigJobTitle,
      delete: deleteConfigJobTitle,
    },
    department: {
      get: getConfigDepartment,
      post: postConfigDepartment,
      patch: patchConfigDepartment,
      delete: deleteConfigDepartment,
    },
    employeeType: {
      get: getConfigEmployeeType,
      post: postConfigEmployeeType,
      patch: patchConfigEmployeeType,
      delete: deleteConfigEmployeeType,
    },
    options: {
      get: getConfigOptions,
      post: postConfigOptions,
      patch: patchConfigOptions,
      delete: deleteConfigOptions,
    },
    hris: {
      post: syncHRIS,
      delete: resetHRISMappings,
      resetHRISMappings: resetHRISMappings,
      uploadADPCreds: uploadADPCreds,
    },
    targets: {
      get: getFieldTargets,
    },
    mappings: {
      get: getHRISMappings,
      post: postHRISMappings,
      put: putHRISMappings,
      delete: deleteHRISMappings,
    },
    viaProps: {
      get: getViaProps,
      post: postViaProps,
      patch: patchViaProps,
      delete: deleteViaProps,
    },
    paylocity: {
      get: getPaylocityProperties,
    },
    proficiency: {
      get: getConfigProficiency,
      post: postConfigProficiency,
      patch: patchConfigProficiency,
      delete: deleteConfigProficiency,
    },
    project: {
      get: getConfigProject,
      post: postConfigProject,
      patch: patchConfigProject,
      delete: deleteConfigProject,
    },
    skill: {
      get: getConfigSkill,
      post: postConfigSkill,
      patch: patchConfigSkill,
      delete: deleteConfigSkill,
    },
    source: {
      get: getConfigSource,
      post: postConfigSource,
      patch: patchConfigSource,
      delete: deleteConfigSource,
    },
    application: {
      get: getApplication,
      post: postApplication,
      patch: patchApplication,
      delete: deleteApplication,
    },
    license: {
      get: getLicense,
      getGroups: getLicenseGroups,
      getUsersByLicense: getUsersByLicense,
      getLicensesByUser: getLicensesByUser,
      post: postLicense,
      patch: patchLicense,
      delete: deleteLicense,
    },
    device: {
      get: getDevice,
      post: postDevice,
      patch: patchDevice,
      delete: deleteDevice,
    },
  },
};

function applyOpts(options) {
  return Object.assign(options || dftOptions);
}

function getUser(options) {
  return apiCore.get('/core/eix/user', applyOpts(options));
}

function getUserCount(options) {
  return apiCore.get('/core/eix/user/count', applyOpts(options));
}

function getLangs(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/tenants/personalized/keys', applyOpts(optionsObj));
}

function getLogs(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/logs', applyOpts(optionsObj));
}

function getTransactionLogs(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/logs/transaction/latest', applyOpts(optionsObj));
}

function groupLookup(options) {
  return apiCore.get('/core/eix/groupLookup', applyOpts(options));
}

function updateLangValueById(key, payload) {
  return apiCore.patch(`/core/tenants/personalized/keys/${key}`, payload);
}

function putUser(payload) {
  return apiCore.put('/core/eix/user', payload);
}
function updateUserById(id, payload) {
  return apiCore.put(`/core/eix/user/${id}`, payload);
}
function updatePhoto(id, payload) {
  return apiCore.post(`/core/eix/user/${id}/photo`, payload);
}

/* Config Data - Category, Client, Collaboration, JobRole, JobTitle ....  */
/* Config Category */
function getConfigCategory(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/category', applyOpts(optionsObj));
}
function postConfigCategory(payload) {
  return apiCore.post('/core/eix/config/category', payload);
}
function patchConfigCategory(id, payload) {
  return apiCore.patch(`/core/eix/config/category${id}`, payload);
}
function deleteConfigCategory(id) {
  return apiCore.delete(`/core/eix/config/category/${id}`);
}

/* Config Client */
function getConfigClient(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/client', applyOpts(optionsObj));
}
function postConfigClient(payload) {
  return apiCore.post('/core/eix/config/client', payload);
}
function patchConfigClient(id, payload) {
  return apiCore.patch(`/core/eix/config/client/${id}`, payload);
}
function deleteConfigClient(id) {
  return apiCore.delete(`/core/eix/config/client/${id}`);
}

/* Config Collaboration */
function getConfigCollaboration(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/collaboration', applyOpts(optionsObj));
}
function postConfigCollaboration(payload) {
  return apiCore.post('/core/eix/config/collaboration', payload);
}
function patchConfigCollaboration(id, payload) {
  return apiCore.patch(`/core/eix/config/collaboration/${id}`, payload);
}
function deleteConfigCollaboration(id) {
  return apiCore.delete(`/core/eix/config/collaboration/${id}`);
}

/* Config JobRole */
function getConfigJobRole(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/jobRole', applyOpts(optionsObj));
}
function postConfigJobRole(payload) {
  return apiCore.post('/core/eix/config/jobRole', payload);
}
function patchConfigJobRole(id, payload) {
  return apiCore.patch(`/core/eix/config/jobRole/${id}`, payload);
}
function deleteConfigJobRole(id) {
  return apiCore.delete(`/core/eix/config/jobRole/${id}`);
}

/* Config OfficeLocation */
function getConfigOfficeLocation(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/officeLocation', applyOpts(optionsObj));
}
function postConfigOfficeLocation(payload) {
  return apiCore.post('/core/eix/config/officeLocation', payload);
}
function patchConfigOfficeLocation(id, payload) {
  return apiCore.patch(`/core/eix/config/officeLocation/${id}`, payload);
}
function deleteConfigOfficeLocation(id) {
  return apiCore.delete(`/core/eix/config/officeLocation/${id}`);
}

/* Config JobTitle */
function getConfigJobTitle(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/jobTitle', applyOpts(optionsObj));
}
function postConfigJobTitle(payload) {
  return apiCore.post('/core/eix/config/jobTitle', payload);
}
function patchConfigJobTitle(id, payload) {
  return apiCore.patch(`/core/eix/config/jobTitle/${id}`, payload);
}
function deleteConfigJobTitle(id) {
  return apiCore.delete(`/core/eix/config/jobTitle/${id}`);
}

/* Department Source */
function getConfigDepartment(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/department', applyOpts(optionsObj));
}
function postConfigDepartment(payload) {
  return apiCore.post('/core/eix/config/department', payload);
}
function patchConfigDepartment(id, payload) {
  return apiCore.patch(`/core/eix/config/department/${id}`, payload);
}
function deleteConfigDepartment(id) {
  return apiCore.delete(`/core/eix/config/department/${id}`);
}

/* Employee Type Source */
function getConfigEmployeeType(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/employeeType', applyOpts(optionsObj));
}
function postConfigEmployeeType(payload) {
  return apiCore.post('/core/eix/config/employeeType', payload);
}
function patchConfigEmployeeType(id, payload) {
  return apiCore.patch(`/core/eix/config/employeeType/${id}`, payload);
}
function deleteConfigEmployeeType(id) {
  return apiCore.delete(`/core/eix/config/employeeType/${id}`);
}

/* Config Options */
function getConfigOptions(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/options', applyOpts(optionsObj));
}
function postConfigOptions(payload) {
  return apiCore.post('/core/eix/config/options', payload);
}
function patchConfigOptions(id, payload) {
  return apiCore.patch(`/core/eix/config/options/${id}`, payload);
}
function deleteConfigOptions(id) {
  return apiCore.delete(`/core/eix/config/options/${id}`);
}

/* Config HRIS Mappings */
function getHRISMappings(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/hris/fieldMap', applyOpts(optionsObj));
}
function postHRISMappings(payload) {
  return apiCore.post('/core/eix/config/hris/fieldMap', payload);
}
function putHRISMappings(id, payload) {
  return apiCore.put(`/core/eix/config/hris/fieldMap/${id}`, payload);
}
function deleteHRISMappings(id) {
  return apiCore.delete(`/core/eix/config/hris/fieldMap/${id}`);
}

/* Config HRIS Field Targets */
function getFieldTargets() {
  return apiCore.get('/core/eix/config/hris/fieldTargets');
}

/* Config HRIS Actions */
function syncHRIS(id, payload) {
  return apiCore.post(`/core/eix/config/hris/syncData/${id}`, payload);
}

function resetHRISMappings(id, payload) {
  return apiCore.post(`/core/eix/config/hris/resetHRISMappings/${id}`, payload);
}

/* Config Montra Props */
function getViaProps(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/options/viaProps/user', applyOpts(optionsObj));
}
function postViaProps(payload) {
  return apiCore.post('/core/eix/config/options/viaProps/user', payload);
}
function patchViaProps(id, payload) {
  return apiCore.patch(`/core/eix/config/options/viaProps/${id}`, payload);
}
function deleteViaProps(id) {
  return apiCore.delete(`/core/eix/config/options/viaProps/${id}`);
}

/* Config Montra Props */
function getPaylocityProperties(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get(`/paylocity/company/properties`, applyOpts(optionsObj));
}

/* Config Proficiency */
function getConfigProficiency(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/proficiency', applyOpts(optionsObj));
}
function postConfigProficiency(payload) {
  return apiCore.post('/core/eix/config/proficiency', payload);
}
function patchConfigProficiency(id, payload) {
  return apiCore.patch(`/core/eix/config/proficiency/${id}`, payload);
}
function deleteConfigProficiency(id) {
  return apiCore.delete(`/core/eix/config/proficiency/${id}`);
}

/* Config Project */
function getConfigProject(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/project', applyOpts(optionsObj));
}
function postConfigProject(payload) {
  return apiCore.post('/core/eix/config/project', payload);
}
function patchConfigProject(id, payload) {
  return apiCore.patch(`/core/eix/config/project/${id}`, payload);
}
function deleteConfigProject(id) {
  return apiCore.delete(`/core/eix/config/project/${id}`);
}

/* Config Skill */
function getConfigSkill(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/skill', applyOpts(optionsObj));
}
function postConfigSkill(payload) {
  return apiCore.post('/core/eix/config/skill', payload);
}
function patchConfigSkill(id, payload) {
  return apiCore.patch(`/core/eix/config/skill/${id}`, payload);
}
function deleteConfigSkill(id) {
  return apiCore.delete(`/core/eix/config/skill/${id}`);
}

/* Config Source */
function getConfigSource(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/source', applyOpts(optionsObj));
}
function postConfigSource(payload) {
  return apiCore.post('/core/eix/config/source', payload);
}
function patchConfigSource(id, payload) {
  return apiCore.patch(`/core/eix/config/source/${id}`, payload);
}
function deleteConfigSource(id) {
  return apiCore.delete(`/core/eix/config/source/${id}`);
}

function getCompany(payload) {
  return apiCore.get(`/core/companies/`, payload);
}
function getUsersByCompanyId(id, payload) {
  return apiCore.get(`/core/eix/companyId/${id}/user`, payload);
}

/* Employee Management Data - Onboarding, Offboarding ....  */
/* Onboarding */
function getOnboard(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/onboard', applyOpts(optionsObj));
}
function postOnboard(payload) {
  return apiCore.post('/core/eix/onboard', payload);
}
function putOnboard(id, payload) {
  return apiCore.put(`/core/eix/onboard/${id}`, payload);
}
function deleteOnboard(id) {
  return apiCore.delete(`/core/eix/onboard/${id}`);
}
function forceOnboard(id) {
  return apiCore.post(`/core/eix/onboard/forceNow/${id}`);
}
function forceOffboard(id) {
  return apiCore.post(`/core/eix/offboard/forceNow/${id}`);
}

/* Onboarding */
function getOnboardPreset(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/onboard/presets', applyOpts(optionsObj));
}
function postOnboardPreset(payload) {
  delete payload.id;
  return apiCore.post('/core/eix/onboard/presets', payload);
}
function putOnboardPreset(id, payload) {
  return apiCore.put(`/core/eix/onboard/presets/${id}`, payload);
}
function deleteOnboardPreset(id) {
  return apiCore.delete(`/core/eix/onboard/presets/${id}`);
}

function detectOnboardPreset(id) {
  return apiCore.get(`/core/eix/onboard/presets/detect/${id}`);
}

/* Offboarding */
function getOffboard(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/offboard', applyOpts(optionsObj));
}
function postOffboard(payload) {
  return apiCore.post('/core/eix/offboard', payload);
}
function putOffboard(id, payload) {
  return apiCore.put(`/core/eix/offboard/${id}`, payload);
}
function deleteOffboard(id) {
  return apiCore.delete(`/core/eix/offboard/${id}`);
}

/* Onboarding: Application */
function getApplication(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/onboarding/application', applyOpts(optionsObj));
}
function postApplication(payload) {
  return apiCore.post('/core/eix/config/onboarding/application', payload);
}
function patchApplication(id, payload) {
  return apiCore.patch(`/core/eix/config/onboarding/application/${id}`, payload);
}
function deleteApplication(id) {
  return apiCore.delete(`/core/eix/config/onboarding/application/${id}`);
}

/* Onboarding: License */
// function getLicense(options) {
//   let optionsObj = { ...options };
//   optionsObj["useCache"] = false;
//   return apiCore.get(
//     "/core/eix/config/onboarding/license",
//     applyOpts(optionsObj)
//   );
// }
// function postLicense(payload) {
//   return apiCore.post("/core/eix/config/onboarding/license", payload);
// }
// function patchLicense(id, payload) {
//   return apiCore.patch(`/core/eix/config/onboarding/license/${id}`, payload);
// }
// function deleteLicense(id) {
//   return apiCore.delete(`/core/eix/config/onboarding/license/${id}`);
// }

/**
 * Onboarding: License
 * allows configuration of Azure licenses and
 * at a later date the enabled / disabled status
 * for Services within a license
 * */

/**
 * getLicense will return all licenses available to the Tenant
 * licenses and subLicences are shown at the same "level" in this call
 * */
function getLicense(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/license', applyOpts(optionsObj));
}
/**
 * getLicenseGroups will return all "top level" licenses available to the Tenant
 * essentially filtering out all subLicenses that share the same skuPartNumber
 * */
function getLicenseGroups(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/license/partNumberGroups', applyOpts(optionsObj));
}

/**
 * getUsersByLicense takes a skuId and returns the vUser info for the users that have that
 * skuId assigned to them
 * */
function getUsersByLicense(skuId, payload) {
  return apiCore.get(`/core/eix/config/license/${skuId}/users/`, payload);
}
/**
 * getLicensesByUser will get license info directly from AD for the o365UserId specified
 * */
function getLicensesByUser(o365UserId, payload) {
  return apiCore.get(`/core/eix/config/license/user/${o365UserId}`, payload);
}

function postLicense(payload) {
  console.log('postLicense is a noop at the moment. all licenses are created by Azure / MS');
  return apiCore.post('/core/eix/config/onboarding/license', payload);
}
/**
 * patchLicense takes the o365UserId that should be updated as well as a
 * payload that can contain the following:
 *  {
 * 	"addLicenses": [
 *     { "disabledPlans": [], "skuId": "c42b9cae-ea4f-4ab7-9717-81576235ccac" }
 *    ],
 *    "removeLicenses": []
 *  }
 *  where addLicenses is a list of license _skuId_s to add to the user
 *  and disabledPlans within that object is a list of _planId_s within
 *  that license to disable
 *  removeLicenses is a list of license skuIds to remove from the user
 *
 *  NOTE the difference in id's required here
 * */
function patchLicense(o365UserId, payload) {
  return apiCore.patch(`/core/eix/config/license/users/${o365UserId}`, payload);
}

function deleteLicense(id) {
  return apiCore.delete(`/core/eix/config/onboarding/license/${id}`);
}

/* Onboarding: Device */
function getDevice(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/eix/config/onboarding/device', applyOpts(optionsObj));
}
function postDevice(payload) {
  return apiCore.post('/core/eix/config/onboarding/device', payload);
}
function patchDevice(id, payload) {
  return apiCore.patch(`/core/eix/config/onboarding/device/${id}`, payload);
}
function deleteDevice(id) {
  return apiCore.delete(`/core/eix/config/onboarding/device/${id}`);
}

/* HRIS: ADP */
function uploadADPCreds(id, payload) {
  return apiCore.post(`/adp/uploadcreds/${id}`, payload);
}

export default repo;
