export default [
  {
    id: 16250,
    name: 'User: Workstation',
    description: 'Computer, Phone, Printer, Peripherals',
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-01T15:07:58.693',
    modifiedBy: 55921,
    modifiedOn: '2019-05-20T14:28:39.347',
  },
  {
    id: 16251,
    name: 'User: Software',
    description: '',
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-01T15:08:06.693',
    modifiedBy: 55921,
    modifiedOn: '2019-05-20T15:31:42.153',
  },
  {
    id: 16382,
    name: 'Networking: Firewall',
    description: '',
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-15T16:19:18.843',
    modifiedBy: 55921,
    modifiedOn: '2019-05-16T09:01:04.953',
  },
  {
    id: 16383,
    name: 'Networking: Switch',
    description: '',
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-15T16:38:14',
    modifiedBy: 55921,
    modifiedOn: '2019-05-16T09:00:50.203',
  },
  {
    id: 16384,
    name: 'User: Account',
    description: '',
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-16T08:42:50.467',
    modifiedBy: 55921,
    modifiedOn: '2019-05-20T12:23:31.317',
  },
  {
    id: 16385,
    name: 'Onboarding / Build',
    description: '',
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-16T08:43:48.697',
    modifiedBy: 55921,
    modifiedOn: '2019-05-20T12:07:33.773',
  },
  {
    id: 16386,
    name: 'Networking: Access Point',
    description: '',
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-16T08:45:08.513',
    modifiedBy: 55921,
    modifiedOn: '2019-05-16T09:00:07.727',
  },
  {
    id: 16387,
    name: 'Server',
    description: '',
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-16T08:59:50.617',
    modifiedBy: 55921,
    modifiedOn: '2019-05-20T14:23:16.613',
  },
  {
    id: 16412,
    name: 'Other',
    description: '',
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T12:09:21.45',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 16413,
    name: 'Office Move/Add/Change',
    description: '',
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T12:11:16.017',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 16414,
    name: 'Offboarding',
    description: '',
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T12:28:38.893',
    modifiedBy: 55921,
    modifiedOn: '2019-05-20T13:24:47.883',
  },
  {
    id: 16415,
    name: 'Networking: External',
    description: '',
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T13:51:55.543',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: null,
    name: 'Empty',
    description: 'None Selected',
    isActive: true,
    createdBy: 55921,
    createdOn: '',
    modifiedBy: 55921,
    modifiedOn: '',
  },
];
