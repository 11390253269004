<template>
  <div :key="cartItemsTotal" class="d-flex" style="min-height: 34px">
    <v-toolbar-items class="align-center" @click.stop="toggleCart()">
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-badge v-if="cartItemsTotal > 0" floating location="top right" color="warning">
            <template v-if="cartItemsTotal > 0" #badge>
              {{ cartItemsTotal }}
            </template>
            <v-icon v-bind="props" size="medium" class="clickable d-sm-flex mx-1"> fal fa-shopping-cart </v-icon>
          </v-badge>
          <div v-if="cartItemsTotal === 0">
            <v-icon v-bind="props" size="medium" class="clickable d-sm-flex mx-1"> fal fa-shopping-cart </v-icon>
          </div>
        </template>
        <span>Cart</span>
      </v-tooltip>
    </v-toolbar-items>
    <v-divider vertical class="ma-2" />
  </div>
</template>

<script>
import store from '@/store/state';

export default {
  name: 'FulfillmentComponent',
  components: {},
  data: () => ({
    cartItemsTotal: store.state.cartItems?.total || 0,
    cartVersion: 5,
  }),
  watch: {
    '$store.state.cartItems.total': {
      deep: true,
      handler() {
        this.cartItemsTotal = store.state.cartItems?.total || 0;
      },
    },
  },
  created() {
    try {
      let ls = store.getters['cartVersion'];
      //let ls = JSON.parse(localStorage.getItem('via-cart'));
      if (ls !== this.cartVersion || ls === null) {
        console.log('Cart version mismatch, clearing cart');
        store.commit('emptyCart', this.cartVersion);
      }
      ls = store.getters['cartItems'];
      //console.log('cartItems is', ls);
    } catch (err) {
      console.log(`store.getters['cartVersion'] failed`, err);
      store.commit('emptyCart', this.cartVersion);
      console.log('Cart version mismatch, clearing cart');
      // let ls = store.getters['cartItems'];
      // console.log('cartItems is', ls);
    }
  },
  methods: {
    toggleCart() {
      store.commit('toggleCart');
    },
  },
};
</script>
