import apiCore from '@/api/api.common';
// import { Cache } from 'axios-extensions';

// const FIVE_MINUTES = 1000 * 60 * 5;
// const CAPACITY = 100;
// const metricCache = new Cache({ ttl: FIVE_MINUTES, max: CAPACITY });
const dftOptions = { useCache: true };

const repo = {
  apiCore: apiCore,
  getscanLogs,
};

function applyOpts(options) {
  return Object.assign(options || dftOptions);
}
function getscanLogs(options) {
  return apiCore.get('/core/pixellot', applyOpts(options));
}

export default repo;
