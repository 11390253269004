<template>
  <v-toolbar-items v-if="!loading" class="align-center">
    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <v-icon v-bind="props" size="medium" class="mx-2 d-sm-flex"> fal fa-bell </v-icon>
      </template>
      <span>Alerts</span>
    </v-tooltip>
    <div class="mx-2 text-center link" @click="navDetail('Alerts')">
      <div class="text-subtitle-1">
        {{ alerts.activeDeviceCount || 0 }}
      </div>
      <div class="text-caption" style="color: smoke">Device</div>
    </div>
    <div class="mx-2 text-center link" @click="navDetail('Alerts')">
      <div class="text-subtitle-1">
        {{ alerts.activeSecurityCount || 0 }}
      </div>
      <div class="text-caption" style="color: smoke">Security</div>
    </div>
  </v-toolbar-items>
</template>
<script>
import alertRepo from '@/api/core/alertRepository';
export default {
  name: 'TenantAlerts',
  components: {},
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.loading = true;
    this.alerts = alertRepo
      .activeCountsUniq({ params: {} }) //
      .then((resp) => {
        if (resp && resp.status === 200) {
          // Object.assign(data.Results, resp.data.Results || {});
          const devices = resp.data.Results.filter((d) => d.activeDeviceCount > 0);
          const security = resp.data.Results.filter((d) => d.activeSecurityCount > 0);
          this.alerts = {
            activeDeviceCount: devices.length || 0,
            activeSecurityCount: security.length || 0,
          };
          this.loading = false;
        }
      });
  },
  methods: {
    navDetail(state) {
      const target = {
        name: 'assets',
        query: { actionFilter: state },
      }; // query: Date.now() required to ensure nav request is unique - otherwise error trying to nav to the same page
      this.$router.push(target);
    },
  },
};
</script>
