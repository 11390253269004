export function normalizeList(string) {
  return string ? string.replace(/, /g, ',').trim().split(',') : '';
}

// Core helper to find union of admin/user permissions
export function effectiveDomain(masterObject, adminArray, userArray) {
  const union = [...new Set([...adminArray, ...userArray])];
  const domain = masterObject.filter((r) => union.includes(r.value)); // lookup string arrays in masterObject (viaFeatures or viaRoles)
  domain.forEach((i) => (adminArray.includes(i.value) ? (i.disabled = false) : (i.disabled = true))); // any items a user has above a admin, set as readOnly
  return domain;
}
/**
 * Based on the admin & users roles, return a list of permissions allowed to be assigned.
 * In cases where a user has permissions outside of the admin, mark as disabled = true.
 * @param {*} adminRoles string array of roles the assigning admin is assigned to.
 * @param {*} userRoles string array of roles the target user is currently assigned to.
 * @returns List of roles, with extended property of 'disabled' where user exceeds permissions of the admin.
 */
export function effectiveRolesDomain(adminRoles, userRoles) {
  return effectiveDomain(viaRoles, adminRoles, userRoles);
}

/**
 * Based on the admin & users features, return a list of permissions allowed to be assigned.
 * In cases where a user has permissions outside of the admin, mark as disabled = true.
 * @param {*} adminFeatures string array of features the assigning admin is assigned to.
 * @param {*} userFeatures string array of features the target user is currently assigned to.
 * @returns List of features, with extended property of 'disabled' where user exceeds permissions of the admin.
 */
export function effectiveFeaturesDomain(adminFeatures, userFeatures) {
  return effectiveDomain(viaFeatures, adminFeatures, userFeatures);
}

export function evalLevels(user, items) {
  
  //if roles_permissions exists, then they should eval levels differently
  let userPermissions = '';

  if(typeof user?.permissionsString === 'string'){
    userPermissions = user?.permissionsString.split(',');
  } else {
    if(user?.permissionsString) {
      userPermissions = user?.permissionsString;
    }
  }

  let clone = [];
  // Object.assign(clone, items);
  clone = items.filter((m) => {
    //// filter the children items for the right auth features
    if (m.children && m.children.length > 0) {
      m.children = evalLevels(user, m.children);
    }
    if (userPermissions?.includes('super_admin')) return true;
    if (excludeExtension(user, m)) return false;
    return hasArrayIntersection(userPermissions, m.permissions);
  });
  return clone;
}

export function evalLevel(user, item) {
  let userPermissions = [];
  if(user?.permissions) {
    userPermissions = user?.permissions?.map((p) => p.permissionCode);
  }
  if (userPermissions.includes('super_admin')) return true;
  return hasArrayIntersection(userPermissions, item.meta.permissions);
}

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_intersection
export function hasArrayIntersection(source, target) {
  source = source || [];
  target = target || [];

  if (target.length == 0) {
    return true;
  }
  const lcSource = source.map((name) => name?.trim());
  const lcTarget = target.map((name) => name?.trim());

  const arrays = [lcSource, lcTarget];

  try {
    return arrays.reduce((a, b) => a.filter((c) => b.includes(c))).length > 0;
  } catch (e) {
    //console.error(e.message);
  }
  return false;
}

// Hardcoded permissions excluded to all (less Development)
export function excludeExtension(user, item) {
  const excludeExtensions = [];
  // const excludeExtensions = ['Cloud', 'Cloud_O365', 'Cloud_Azure', 'workplaces'];
  const features = item.permissions;
  const subjectArray = Array.isArray(features) ? features : [features];
  /* Custom eval for exclusion logic   */
  if (item.name == 'employees' && user.o365UserId) {
    return true;
  }

  // If we have defined extensions - process here
  if (excludeExtensions.length > 0 && features.length > 0) {
    return hasArrayIntersection(excludeExtensions, subjectArray);
  } else {
    return false;
  }
}

export function countChildItems(item) {
  const children = item.items || [];
  const counts = {
    totalItems: children.length,
    totalItemsWithChildren: children.length,
  };

  if (children.length > 0) {
    item.items.forEach((i) => {
      const grandchild = i.items || [];
      counts.totalItemsWithChildren += grandchild.length;
    });
  }
  //console.log(`${item.title}  totalItems: ${counts.totalItems}  totalItemsWithChildren: ${counts.totalItemsWithChildren}`);
  return counts;
}
