<template>
  <v-bottom-sheet v-model="help" location="right" disable-resize-watcher style="z-index: 999999">
    <v-card rounded="0">
      <v-card-title>
        <span>Help</span>
        <div class="flex-grow-1" />
        <v-btn variant="text" @click="toggleHelp">
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <splitpanes
          vertical
          :push-other-panes="false"
          class="default-theme saved-filter-height"
          @resize="changeSubPaneSizes($event)"
          style="height: calc(60vh - 117px)"
        >
          <pane class="overflow-y pa-4" :size="filter1size" min-size="5">
            <v-text-field
              v-model="search"
              label="Search"
              placeholder="Search"
              hide-details
              prepend-inner-icon="fal fa-search"
              density="compact"
              variant="outlined"
              class="mb-4"
              clearable
            />
            <div v-if="foundKeywords?.length > 0">
              Found Keywords:
              <v-list v-if="!loading"  class="py-0">
                <v-list-item class="clickable" v-for="keyword in foundKeywords" @click="getMore(keyword.path)" :class="{ 'selectedSearchQuery': keyword.title === searchResults.title }" prepend-icon="fa fa-link" :title="keyword.title">
                </v-list-item>
              </v-list>
              <v-progress-linear v-if="loading" indeterminate color="primary" />
            </div>
            <div v-else>
              No results found.
            </div>
          </pane>
          <pane class="overflow-y pa-4" :size="filter2size" min-size="5" style="overflow: auto">
            Search Results
            <Markdown :source="searchResults.content" />
          </pane>
          <pane class="overflow-y pa-4" :size="filter3size" min-size="5">
            <div v-if="relatedKeywords.length > 0">
              Tags:
              <div v-if="!loading">
                <v-list lines="one" v-for="keyword in relatedKeywords" class="py-0">
                  <v-list-item class="clickable" density="compact" @click="search = keyword.tag" :title="keyword.tag" prepend-icon="fal fa-link"></v-list-item>
                </v-list>
              </div>
            </div>            
          </pane>
        </splitpanes> 
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import store from '@/store/state';
import Markdown from 'vue3-markdown-it';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

import { RepositoryFactory } from '@/api/repositoryFactory.js';

const admin = RepositoryFactory.get('admin');

export default {
  name: 'AppHelp',
  components: {
    Splitpanes,
    Pane,
    Markdown
  },
  data: () => ({
    search: '',
    filter1size: 20,
    filter2size: 60,
    filter3size: 20,
    foundKeywords: [],
    searchResults: {
      title: null,
      description: null,  
      content: '',    
    },
    relatedKeywords: [],
    loading: false,
  }),
  watch: {
    search: function (val) {
      if (val?.length > 2) {
        this.getKeywords(val);
      } else {
        this.foundKeywords = [];
        this.relatedKeywords = [];
        this.searchResults = { title: 'No information found.' };
      }
    },
    help: function (val) {
      this.search = this.helpQuery;
    },
  },
  computed: {
    help: {
      get() {
        return store.state.help || false;
      },
    },
    helpQuery: {
      get() {
        return store.state.helpQuery || '';
      },
    },
  },
  methods: {
    replaceRelativeLinks(text) {
      const baseUrl = 'https://wiki.montra.io';
      const regex = /src="(\/[^"]+)"/g;
      return text.replace(regex, `src="${baseUrl}$1"`);
    },
    toggleHelp() {
      store.commit('toggleHelp');
    },
    changeSubPaneSizes(newSize) {
      this.filter1size = newSize[0].size;
      this.filter2size = newSize[1].size;
      this.filter3size = newSize[2].size;
    },
    async getKeywords(keyword) {
      this.loading = true;
      await admin.wikijs.searchWikiJSPage(keyword).then(async (resp) => {
        if (resp.data?.Results) {
          this.foundKeywords = resp.data?.Results.filter((result) => result.path.includes('home/documentation'));
          this.loading = false;
          this.getMore(this.foundKeywords[0]?.path);
        }
      });
    },
    async getMore(path) {
      this.loading = true;
      await admin.wikijs.getWikiPageByPath({ path: path }).then(async (resp) => {
        if (resp.data?.Results) {
          this.searchResults = resp.data?.Results;
          this.searchResults.content = this.replaceRelativeLinks(resp.data?.Results?.content);
          this.relatedKeywords = resp.data?.Results?.tags;
        } else {
          this.searchResults = { title: 'No information found.' };
        }
        this.loading = false;
      });
    },
  },
};
</script>