import { format, formatDistance, parseISO } from 'date-fns';

function processISODateString(isoString) {
  if (!isoString) {
    return '';
  }
  /* if (!isoString.endsWith('Z')) {
    isoString += 'Z';
  } */
  return parseISO(isoString);
}
function date(fmt = 'MMMM d, yyyy', isoString) {
  const valDate = processISODateString(isoString);
  if (!valDate) {
    return '';
  }
  return format(valDate, fmt);
}
function dateDiff(baseDate, options, isoString) {
  if (!baseDate) {
    baseDate = Date.now();
  }
  if (!options) {
    options = { addSuffix: true };
  }
  if (isoString) {
    const valDate = processISODateString(isoString);
    if (valDate) {
      return formatDistance(valDate, baseDate, options);
    }
  }
  return '';
}

export { date, dateDiff };
