<template>
  <v-toolbar-items class="align-center">
    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <v-icon v-bind="props" size="medium" class="d-sm-flex mx-2"> fal fa-user-plus </v-icon>
      </template>
      <span>Onboarding</span>
    </v-tooltip>
    <div class="mx-2 text-center link" @click="navDetail('New')">
      <div class="text-subtitle-1">
        {{ onboardInfo.new }}
      </div>
      <div class="text-caption" style="color: smoke">New</div>
    </div>
    <div class="mx-2 text-center link" @click="navDetail('Pending')">
      <div class="text-subtitle-1">
        {{ onboardInfo.pending }}
      </div>
      <div class="text-caption" style="color: smoke">Pending</div>
    </div>
    <!-- <div class="mx-2 text-center link" @click="navDetail('P1')">
      <div class="text-subtitle-1">{{ onboardInfo.outgoingCount }}</div>
      <div class="text-caption" style="color:smoke">Outgoing</div>
    </div> -->
  </v-toolbar-items>
</template>

<script>
export default {
  name: 'EixPeopleOnboard',
  components: {},

  data: () => ({
    polling: null,
  }),
  computed: {
    onboardInfo() {
      return this.$store.getters['metric/eixOnboardingInfo'];
    },
  },
  mounted() {
    // get initial data
    this.$store.dispatch('metric/loadOnboardingInfo');
    // trigger 1 second data polling
    this.pollData();
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },

  methods: {
    navDetail(state) {
      const target = {
        name: 'onboarding',
        query: { now: Date.now(), actionFilter: state, cb: Date.now() },
      }; // query: Date.now() required to ensure nav request is unique - otherwise error trying to nav to the same page
      this.$router.push(target);
    },
    refreshStats() {
      this.$store.dispatch('metric/loadOnboardingInfo');
    },

    pollData() {
      this.polling = setInterval(this.refreshStats, import.meta.VITE_VUE_APP_POLLING_INTERVAL || 300000);
    },
  },
};
</script>
<style>
.link {
  cursor: pointer;
}
</style>
