import apiCore from '@/api/api.common';
// import { Cache } from 'axios-extensions';
// import util from "@/util";

// const FIVE_MINUTES = 1000 * 60 * 5;
// const CAPACITY = 100;
// const localCache = new Cache({ ttl: FIVE_MINUTES, max: CAPACITY });
const dftOptions = { useCache: true };

function applyOpts(options) {
  return Object.assign(options || dftOptions);
}

const repo = {
  apiCore: apiCore,
  activeCounts: activeCounts,
  activeCountsUniq: activeCountsUniq,
  alertSeriesByDate: alertSeriesByDate,
  alertCountsByDate: alertCountsByDate,
  alertsByHardwareId: alertsByHardwareId,
  securityAlertOpenClosedSeriesByDate: securityAlertOpenClosedSeriesByDate,
  deviceAlertOpenClosedSeriesByDate: deviceAlertOpenClosedSeriesByDate,
  devicePriorityCounts: devicePriorityCounts,
  securityPriorityCounts: securityPriorityCounts,
  deviceAlertsByHardware: deviceAlertsByHardware,
  securityAlertsByHardware: securityAlertsByHardware,
  graphs: {
    deviceOpenedClosedBarChart: deviceOpenedClosedBarChart,
    securityOpenedClosedBarChart: securityOpenedClosedBarChart,
  },
};

function alertsByHardwareId(id, options) {
  return apiCore.get(`/core/alerts/alertsByHardware/${id}`, applyOpts(options));
}
function alertCountsByDate(options) {
  return apiCore.get('/core/alerts/alertCountsByDate', applyOpts(options));
}

function deviceAlertsByHardware(options) {
  return apiCore.get('/core/alerts/deviceAlertsByHardware', applyOpts(options));
}
function securityAlertsByHardware(options) {
  return apiCore.get('/core/alerts/securityAlertsByHardware', applyOpts(options));
}
function securityPriorityCounts(options) {
  return apiCore.get('/core/alerts/securityPriorityCounts', applyOpts(options));
}
function devicePriorityCounts(options) {
  return apiCore.get('/core/alerts/devicePriorityCounts', applyOpts(options));
}
function deviceAlertOpenClosedSeriesByDate(options) {
  return apiCore.get('/core/alerts/deviceAlertOpenClosedSeriesByDate', applyOpts(options));
}

function securityAlertOpenClosedSeriesByDate(options) {
  return apiCore.get('/core/alerts/securityAlertOpenClosedSeriesByDate', applyOpts(options));
}

function activeCounts(options) {
  return apiCore.get('/core/alerts/activeCounts', applyOpts(options));
}
function activeCountsUniq(options) {
  return apiCore.get('/core/alerts/activeCountsUniq', applyOpts(options));
}
function alertSeriesByDate(options) {
  return apiCore.get('/core/alerts/alertSeriesByDate', applyOpts(options));
}

/************************
 * Graph/Chart helpers
 *************************/
async function deviceOpenedClosedBarChart(options) {
  const series = [
    {
      name: 'Opened',
      data: [],
    },
    {
      name: 'Closed',
      data: [],
    },
  ];

  const resp = await deviceAlertOpenClosedSeriesByDate(options); // make data query
  if (resp && resp.status === 200) {
    //shape the data for the line chart with two series of data
    const coll = resp.data.Results.series;
    const opened = series[0];
    const closed = series[1];
    for (let i = 0; i < coll.length; i++) {
      const row = coll[i];
      opened.data.push({ x: new Date(row.timestamp).getTime(), y: row.open });
      closed.data.push({ x: new Date(row.timestamp).getTime(), y: row.closed });
    }
  }
  return series;
}

async function securityOpenedClosedBarChart(options) {
  const series = [
    {
      name: 'Opened',
      data: [],
    },
    {
      name: 'Closed',
      data: [],
    },
  ];

  const resp = await securityAlertOpenClosedSeriesByDate(options); // make data query
  if (resp && resp.status === 200) {
    //shape the data for the line chart with two series of data
    const coll = resp.data.Results.series;
    const opened = series[0];
    const closed = series[1];
    for (let i = 0; i < coll.length; i++) {
      const row = coll[i];
      opened.data.push({ x: new Date(row.timestamp).getTime(), y: row.open });
      closed.data.push({ x: new Date(row.timestamp).getTime(), y: row.closed });
    }
  }
  return series;
}

export default repo;
