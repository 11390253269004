import { evalLevels } from '@/api/core/meta/userPermission.meta';
import {hasPermission } from '@/common/permission.mapper.js';

export function userMenu(isPeople) {
  return [
    {
      icon: 'fal fa-user',
      href: '#',
      title: 'Profile',
      route_name: isPeople ? 'eixUserProfile' : 'profile',
      sub: false,
      permissions: ['view_people'],
      items: [],
    },
    {
      icon: 'fal fa-wrench',
      name: 'administration',
      title: 'Administration',
      href: '/maintenance/admin/tenants',
      permissions: ['full_workforce'],
      open: false,
      sub: false,
      parent: true,
      items: [
        {
          icon: '',
          name: 'user',
          title: 'Via Users',
          route_name: 'users',
          href: '/maintenance/admin/users',
          permissions: ['full_workforce'],
          items: [],
          sub: true,
        },
        {
          icon: '',
          name: 'employees',
          title: 'Employees',
          route_name: 'employees',
          href: '/maintenance/admin/employees',
          permissions: ['full_workforce'],
          items: [],
          sub: true,
        },
      ],
    },
    {
      icon: 'fal fa-user-secret',
      href: '/maintenance/admin/impersonate',
      title: 'Impersonate',
      route_name: 'impersonate',
      permissions: ['impersonate_tenant'],
      sub: false,
    },
    {
      icon: 'fal fa-eraser',
      href: '#',
      title: 'Clear Custom Filters',
      fn_name: 'clear',
      permissions: [],
      items: [],
      sub: false,
    },
    {
      icon: 'fal fa-list',
      href: '/release-notes',
      title: 'Release Notes',
      route_name: 'release-notes',
      permissions: [],
      sub: false,
    },
    // {
    //   icon: 'fal fa-info-circle',
    //   href: '#',
    //   title: 'Help',
    //   fn_name: 'help',
    //   permissions: [],
    //   items: [],
    //   sub: false,
    // },
    {
      icon: 'fal fa-right-from-bracket',
      href: '#',
      title: 'Logout',
      fn_name: 'logout',
      permissions: [],
      items: [],
      sub: false,
    },
  ];
}

function load(user) {
  let clone = [];

  if (user.auth0Id) {
    clone = evalLevels(user, userMenu(hasPermission('view_people')));
  }
  return clone;
}
function defaultPath(user) {
  const menu = load(user);
  if (menu && menu.length > 0) {
    return menu[0].href;
  } else {
    return '/';
  }
}

const UserMenu = {
  load,
  defaultPath,
};

export default UserMenu;
