import apiCore from '@/api/api.common';
// import { Cache } from 'axios-extensions';
// import util from "@/util";

// const SIXTY_MINUTES = 1000 * 60 * 60;
// const CAPACITY = 100;
// const azureCache = new Cache({ ttl: SIXTY_MINUTES, max: CAPACITY });
const dftOptions = { useCache: true };

const repo = {
  apiCore: apiCore,
  advisorList: advisorList,
  disks: disks,
  virtualMachines: virtualMachines,
};

function applyOpts(options) {
  return Object.assign(options || dftOptions);
}
function advisorList(options) {
  return apiCore.get('/management/advisor', applyOpts(options));
}
function disks(options) {
  return apiCore.get('/management/disks', applyOpts(options));
}
function virtualMachines(options) {
  return apiCore.get('/management/virtualMachines', applyOpts(options));
}
export default repo;
