export default [
  { id: 28, name: 'Galax', value: 'Galax', isActive: false },
  { id: 29, name: 'ADLINK', value: 'ADLINK', isActive: false },
  {
    id: 27,
    name: 'Adlink Technologies',
    value: 'Adlink Technologies',
    isActive: false,
  },
  { id: 11, name: 'HP', value: 'HP', isActive: true },
  { id: 26, name: 'Pixellot', value: 'Pixellot', isActive: true },
];
