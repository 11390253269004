export default [
  {
    path: '/servicedesk',
    name: 'servicedesk',
    component: () => import(/* webpackChunkName: "sd_dashboard" */ '@/pages/servicedesk/dashboard.vue'),
    meta: {
      public: false,
      onlyWhenLoggedOut: false,
      title: 'Service Desk Dashboard',
    },
    children: [
      {
        path: 'admin',
        component: () => import(/* webpackChunkName: "sd_admin" */ '@/pages/servicedesk/admin.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          title: 'Service Desk Admin',
        },
        children: [
          {
            // Toshko Cheese page move at your own risk of causing a call with them
            name: 'tickets_serviceDesk',
            path: 'tickets_serviceDesk',
            component: () => import(/* webpackChunkName: "sd_tickets" */ '@/pages/servicedesk/servicedesk.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: '',
              permissions: [ 'view_ticket', 'full_service_desk'],
              title: 'Service Items',
            },
          },
          {
            name: 'tickets',
            path: 'tickets',
            component: () => import(/* webpackChunkName: "sd_tickets" */ '@/pages/servicedesk/servicedesk.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'tickets',
              permissions: ['view_ticket', 'full_service_desk'],
              title: 'Service Items',
            },
          },
          {
            name: 'ticketDetail',
            path: 'ticketDetail',
            component: () =>
              import(/* webpackChunkName: "sd_ticketDetails" */ '@/pages/servicedesk/tickets/ticketDetail.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'tickets',
              permissions: ['view_ticket', 'full_service_desk'],
              title: 'Service Item Detail',
            },
          },
          {
            name: 'ticket-admin-alerts',
            path: 'tickets/alerts',
            component: () => import(/* webpackChunkName: "eix_admin" */ '@/pages/servicedesk/tickets/alerts.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'tickets',
              permissions: [],
              title: 'Service Items Admin Config',
            },
          },
          {
            name: 'ticket-admin-item-selected',
            path: 'tickets/:configitem',
            component: () => import(/* webpackChunkName: "eix_admin" */ '@/pages/servicedesk/tickets/admin.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'tickets',
              permissions: [ 'admin_service_desk', 'full_service_desk'],
              title: 'Service Items Admin Config',
            },
          },
        ],
      },
    ],
  },
];
