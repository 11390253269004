import apiCore from '@/api/api.common';
// import { Cache } from 'axios-extensions';
import uniqBy from 'lodash/uniqBy';
import util from '@/util/index.js';
import store from '@/store/state.js';
import issueTypes from '@/api/core/lookups/issueTypes.js';
import priorityTypes from '@/api/core/lookups/prioritiesTypes.js';
import statusTypes from '@/api/core/lookups/statusTypes.js';
import subIssueTypes from '@/api/core/lookups/subissueTypes.js';
import sources from '@/api/core/lookups/sources.js';
import types from '@/api/core/lookups/types.js';
import rmaInitialDiagnosis from '@/api/core/lookups/rmaInitialDiagnosis.js';
import rmaFinalDiagnosis from '@/api/core/lookups/rmaFinalDiagnosis.js';
import hardwareStateTypes from '@/api/core/lookups/hardwareStateTypes.js';
import hardwareCategoryTypes from '@/api/core/lookups/hardwareCategoryTypes.js';
import hardwareTypes from '@/api/core/lookups/hardwareTypes.js';

// const FIVE_MINUTES = 1000 * 60 * 5;
// const CAPACITY = 100;
// const localCache = new Cache({ ttl: FIVE_MINUTES, max: CAPACITY });
const dftOptions = { useCache: true };

const repo = {
  apiCore: apiCore,
  createTicket: createTicket,
  updateTicketById: updateTicketById,
  addTicketNote: addTicketNote,
  getTickets: getTickets,
  getTicketById: getTicketById,
  getActiveCounts: getActiveCounts,
  avgResolutionMinutes: avgResolutionMinutes,
  priorityCounts: priorityCounts,
  openPriorityCounts: openPriorityCounts,
  findTicketIdByTicketNumber: findTicketIdByTicketNumber,
  openedClosedCountByDate: openedClosedCountByDate,
  countsByContacts: countsByContacts,
  countsByStale: countsByStale,
  agedActivityCounts: agedActivityCounts,
  getHardwareAssets: getHardwareAssets,
  getRMAs: getRMAs,
  getTicketRMAs: getTicketRMAs, // his returns full ticket.rma data such for where entire where clause use
  getTicketRMAsV2: getTicketRMAsV2,
  getRMAById: getRMAById,
  getPDFReturnLabel: getPDFReturnLabel,
  reissueReturnLabel: reissueReturnLabel,
  cancelShippingReturnLabel: cancelShippingReturnLabel,
  updateRMA: updateRMA,
  getEmployees: getEmployees,
  getStock: getStock,
  getStockV3: getStockV3,
  getRMAShipped: getRMAShipped,
  getAvgSla1: getAvgSla1,
  RMACountsByDate: RMACountsByDate,
  getRMAAssetCategoryCounts: getRMAAssetCategoryCounts,
  RMAStatusCountsByDate: RMAStatusCountsByDate,
  initialDiagnosisCountsByDate: initialDiagnosisCountsByDate,
  RMASInitialDiagnosisCounts: RMASInitialDiagnosisCounts,
  RMASVPUInitialDiagnosisCounts: RMASVPUInitialDiagnosisCounts,
  RMASCameraInitialDiagnosisCounts: RMASCameraInitialDiagnosisCounts,
  getOrders: getOrders,
  getTicketOrders: getTicketOrders, // his returns full ticket.rma data such for where entire where clause use
  getOrderById: getOrderById,
  getPDFOrderLabel: getPDFOrderLabel,
  updateOrder: updateOrder,
  updateOrderV2: updateOrderV2,
  updateOrderV3: updateOrderV3,
  undoOrderStage: undoOrderStage,
  undoOrderStageAll: undoOrderStageAll,
  deleteOrder: deleteOrder,
  UnitsInOutWarrantyByDefectCodeCounts: UnitsInOutWarrantyByDefectCodeCounts,
  UnitsInOutWarrantyByHardwareStatusCounts: UnitsInOutWarrantyByHardwareStatusCounts,
  DynaColorUnitsPending: DynaColorUnitsPending,
  DynaColorUnitsPendingByDays: DynaColorUnitsPendingByDays,
  UnitsPendingInRepairByDays: UnitsPendingInRepairByDays,
  UnitsPendingInRepair: UnitsPendingInRepair,
  downloadTicketAttachment: downloadTicketAttachment,
  assets: {
    getAssetCountByCategory: getAssetCountByCategory,
    getAssetCountByOSType: getAssetCountByOSType,
  },
  getOrderReasons: getOrderReasons,
  lookups: {
    types: types,
    sources: sources,
    statusTypes: statusTypes,
    priorityTypes: priorityTypes,
    issueTypes: issueTypes,
    subIssueTypes: subIssueTypes,
    rmaInitialDiagnosis: rmaInitialDiagnosis,
    rmaFinalDiagnosis: rmaFinalDiagnosis,
    hardwareStateTypes: hardwareStateTypes,
    hardwareCategoryTypes: hardwareCategoryTypes,
    hardwareTypes: hardwareTypes,
    wildcard: getByLookup,
  },
  graphs: {
    openedClosedCountChart: openedClosedCountChart,
    ticketCountsByContactChart: ticketCountsByContactChart,
    priorityCountChart: priorityCountChart,
    RMACountsByDateChart: RMACountsByDateChart,
    RMAStatusCountsByDateChart: RMAStatusCountsByDateChart,

    RMASLA1ByDateCounts: RMASLA1ByDateCounts,
    RMAPriorityByIssueTypeCounts: RMAPriorityByIssueTypeCounts,
    initialDiagnosisCountsByDateChart: initialDiagnosisCountsByDateChart,
    asset: {
      assetCountByCategoryChart: assetCountByCategoryChart,
    },
  },
  getQueues: getQueues,
  getPriorities: getPriorities,
  getIssueTypes: getIssueTypes,
  getSubIssueTypes: getSubIssueTypes,
  getSLAs: getSLAs,
  getTypes: getTypes,
  getProducts: getProducts,
};

function applyOpts(options) {
  return Object.assign(options || dftOptions);
}

function createTicket() {
  return Promise.reject('not implemented');
}

function updateTicketById() {
  return Promise.reject('not implemented');
}

function addTicketNote(id, noteSummary) {
  if (noteSummary && noteSummary.length > 1) {
    const user = store.getters['account/user']; //store.state.account.user;
    const payload = {
      noteType: 'General Notes',
      noteSummary: noteSummary,
      isInternal: false,
      noteDate: new Date(Date.now()).toISOString(),
      senderEmail: user.email,
    };
    return apiCore.post(`/kaseya/bms/servicedesk/tickets/${id}/note`, payload);
  } else return null;
}

function downloadTicketAttachment(documentId, options) {
  return apiCore.get(`kaseya/bms/system/attachments/${documentId}/download`, applyOpts(options));
}
function getTickets(options) {
  return apiCore.get('/core/bms/ticket', applyOpts(options));
}

function getProducts(options) {
  return apiCore.get('/core/bms/product', applyOpts(options));
}

function getTicketById(id, options) {
  const opt = Object.assign({ useCache: false }, options || {});
  return apiCore.get(`/core/bms/ticket/${id}`, opt).then((resp) => {
    if (resp && resp.status === 200) {
      const orig = resp.data.Results;
      if (orig && orig.notes) {
        orig.notes.forEach(remapTicketRefs);
      }
      orig.statusChanges = orig.statusChanges || [];
      orig.priorityChanges = orig.priorityChanges || [];
      orig.notes = orig.notes || [];
      orig.attachments = orig.attachments || [];
      orig.timeLogs = orig.timeLogs || [];
    }
    return resp;
  });
  // Ticket Notes can include <a> links ref other bms.kaseya.com tickets, update those links for Montra portal display
  function remapTicketRefs(note) {
    if (note.noteSummary) {
      note.noteSummary = note.noteSummary.replaceAll(
        'https://bms.kaseya.com/MSP/TicketEdit.aspx?ID=',
        '/servicedesk/admin/ticketDetail?id=',
        true,
      );
    }
  }
}

async function findTicketIdByTicketNumber(ticketNumber) {
  const opts = {
    params: {
      $filter: `ticketNumber eq '${ticketNumber}'`,
      $select: 'id,ticketNumber',
    },
  };
  const resp = await apiCore.get('/core/bms/ticket', applyOpts(opts));
  if (resp && resp.status === 200) {
    return util.get(resp, 'data.Results[0].id', 0);
  } else {
    return 0;
  }
}

function getActiveCounts(options) {
  return apiCore.get('/core/bms/ticket/activeCounts', applyOpts(options));
}

function avgResolutionMinutes(options) {
  return apiCore.get('/core/bms/ticket/avgResolutionMinutes', applyOpts(options));
}

function priorityCounts(options) {
  return apiCore.get('/core/bms/ticket/priorityCounts', applyOpts(options));
}
function openPriorityCounts(options) {
  return apiCore.get('/core/bms/ticket/openPriorityCounts', applyOpts(options));
}
/**
 *
 * @param {*} options query: $interval [second,minute,hour,day,week,month,quarter,year,decade], $dates [# of dates in interval]
 */
function openedClosedCountByDate(options) {
  return apiCore.get('/core/bms/ticket/openedClosedCountByDate', applyOpts(options));
}
function countsByContacts(options) {
  return apiCore.get('/core/bms/ticket/countsByContact', applyOpts(options));
}

function getHardwareAssets(options) {
  return apiCore.get('/core/bms/hardwareAsset', applyOpts(options));
}
function countsByStale(options) {
  return apiCore.get('/core/bms/ticket/countsByStale', applyOpts(options));
}
function agedActivityCounts(options) {
  return apiCore.get('/core/bms/ticket/agedActivityCounts', applyOpts(options));
}

function getRMAAssetCategoryCounts(options) {
  return apiCore.get('/core/bms/rma/assets/categoryCounts', applyOpts(options));
}

/********************************
 * Assets
 */
function getAssetCountByCategory(options) {
  return apiCore.get('/core/bms/hardwareAsset/countByCategory', applyOpts(options));
}
function getAssetCountByOSType(options) {
  return apiCore.get('/core/bms/hardwareAsset/countByOSType', applyOpts(options));
}
/************************
 * Graph/Chart helpers
 *************************/

async function assetCountByCategoryChart(options) {
  const chartData = {
    series: [],
    categories: [],
  };
  //const resp = this.$store.getters["vsa/vsaAssetCountByType"];
  const resp = await getAssetCountByCategory(options); // make data query
  if (resp && resp.status === 200) {
    const coll = resp.data.Results;
    chartData.categories = coll.map((i) => {
      return i.category;
    });
    //return [{data:totals}];
    const totals = coll.map((i) => {
      return i.total;
    }); // convert to string
    chartData.series = [{ data: totals }];
    chartData.totalRecords = resp.data.TotalRecords;
  }
  return chartData;
}

async function openedClosedCountChart(options) {
  const series = [
    {
      name: 'Opened',
      data: [],
    },
    {
      name: 'Closed',
      data: [],
    },
  ];

  const resp = await openedClosedCountByDate(options); // make data query
  if (resp && resp.status === 200) {
    //shape the data for the line chart with two series of data
    const coll = resp.data.Results.series;
    const opened = series[0];
    const closed = series[1];
    for (let i = 0; i < coll.length; i++) {
      const row = coll[i];
      opened.data.push({ x: new Date(row.date).getTime(), y: row.opened });
      closed.data.push({ x: new Date(row.date).getTime(), y: row.closed });
    }
  }
  return series;
}

async function ticketCountsByContactChart(options) {
  const series = {
    name: 'Service Items',
    data: [],
  };
  const results = { contacts: [], contactsId: [], accounts: [], data: [] };
  const resp = await repo.countsByContacts(options);
  if (resp && resp.status === 200) {
    const rows = resp.data.Results;
    results.contacts = rows.map((i) => {
      return i.contactId ? i.fullName : i.accountName;
    });
    results.contactsId = rows.map((i) => {
      return i.contactId;
    });
    results.accounts = rows.map((i) => {
      return i.accountName;
    });
    series.data = rows.map((i) => i.count);
    //return {data: {series: [ {data:counts}]}};
    results.data.push(series);
  }
  //console.log('ticketCountsByContactChart',results)
  return results;
}
/**
 *
 * call Montra { params: { $interval: this.interval, $dates: this.dates }}
 */
async function priorityCountChart(options) {
  const series = [];
  await priorityCounts(options) //
    .then((resp) => {
      if (resp && resp.status === 200) {
        const coll = resp.data.Results.series;
        series.push({
          name: 'P1',
          data: coll.map((i) => [new Date(i.openDate).getTime(), i.p1]),
        });
        series.push({
          name: 'P2',
          data: coll.map((i) => [new Date(i.openDate).getTime(), i.p2]),
        });
        series.push({
          name: 'P3',
          data: coll.map((i) => [new Date(i.openDate).getTime(), i.p3]),
        });
        series.push({
          name: 'P4',
          data: coll.map((i) => [new Date(i.openDate).getTime(), i.p4]),
        });
        series.push({
          name: 'P5',
          data: coll.map((i) => [new Date(i.openDate).getTime(), i.p5]),
        });
      }
    });
  return series;
}

/*
RMA API functions
*/

function getRMAs(options) {
  return apiCore.get('/core/bms/rma/tickets', applyOpts(options));
}
/**
 * This returns full ticket.rma data such for where entire where clause use
 * @param {*} options
 */
function getTicketRMAs(options) {
  return apiCore.get('/core/bms/rma/tickets/rmas', applyOpts(options));
}

function getTicketRMAsV2(options) {
  return apiCore.get('/core/bms/rma/tickets/rmas/V2', applyOpts(options));
}

function getRMAById(id, options) {
  const opt = Object.assign({ useCache: false }, options || {});
  return apiCore.get(`/core/bms/rma/tickets/${id}`, opt).then((resp) => {
    if (resp && resp.status === 200) {
      const orig = resp.data.Results;
      if (orig) {
        orig.statusChanges = orig?.statusChanges || [];
        orig.priorityChanges = orig?.priorityChanges || [];
        orig.attachments = orig?.attachments || [];
        orig.timeLogs = orig?.timeLogs || [];
        if (orig?.notes) {
          orig.notes.forEach(remapTicketRefs);
        }
      }
    }
    return resp;
  });
  // Ticket Notes can include <a> links ref other bms.kaseya.com tickets, update those links for Montra portal display
  function remapTicketRefs(note) {
    if (note.noteSummary) {
      note.noteSummary = note.noteSummary.replaceAll(
        'https://bms.kaseya.com/MSP/TicketEdit.aspx?ID=',
        '/servicedesk/admin/ticketDetail?id=',
        true,
      );
    }
  }
}

function updateRMA(payload) {
  return apiCore.post(`/core/bms/rma/stage`, payload);
}

/*
Ticket Order (Fulfillment orders) API functions
*/

function getOrders(options) {
  return apiCore.get('/core/bms/order/tickets', applyOpts(options));
}
/**
 * This returns full ticket.rma data such for where entire where clause use
 * @param {*} options
 */
function getTicketOrders(options) {
  return apiCore.get('/core/bms/order/tickets/orders', applyOpts(options));
}

function getOrderById(id, options) {
  const opt = Object.assign({ useCache: false }, options || {});
  return apiCore.get(`/core/bms/order/tickets/${id}`, opt).then((resp) => {
    if (resp && resp.status === 200) {
      const orig = resp.data.Results;
      if (orig && orig.notes) {
        orig.notes.forEach(remapTicketRefs);
      }
      orig.statusChanges = orig?.statusChanges || [];
      orig.priorityChanges = orig?.priorityChanges || [];
      orig.notes = orig?.notes || [];
      orig.attachments = orig?.attachments || [];
      orig.timeLogs = orig?.timeLogs || [];
    }
    return resp;
  });
  // Ticket Notes can include <a> links ref other bms.kaseya.com tickets, update those links for Montra portal display
  function remapTicketRefs(note) {
    if (note.noteSummary) {
      note.noteSummary = note.noteSummary.replaceAll(
        'https://bms.kaseya.com/MSP/TicketEdit.aspx?ID=',
        '/servicedesk/admin/ticketDetail?id=',
        true,
      );
    }
  }
}

function updateOrder(payload) {
  return apiCore.post(`/core/bms/order/stage`, payload);
}
function updateOrderV2(payload) {
  return apiCore.post(`/core/bms/order/stageV2`, payload);
}
function updateOrderV3(payload) {
  return apiCore.post(`/core/bms/order/stageV3`, payload);
}
function undoOrderStage(orderId, undoStage, payload) {
  return apiCore.post(`/core/bms/order/undoStage/${orderId}/${undoStage}`, payload);
}
function undoOrderStageAll(orderId, payload) {
  return apiCore.post(`/core/bms/order/undoAllStages/${orderId}`, payload);
}
function deleteOrder(id) {
  return apiCore.delete(`/core/bms/order/tickets/order/${id}`);
}
function getEmployees(options) {
  return apiCore.get('/core/users/employees', applyOpts(options));
}

function getStock(options) {
  return apiCore.get('/core/bms/rma/assets', applyOpts(options));
}

function getStockV3(options) {
  return apiCore.get('/core/bms/rma/assetsV3', applyOpts(options));
}
/************************
 * Graph/Chart helpers
 *************************/
function getRMAShipped(options) {
  return apiCore.get('/core/bms/rma/assets/shippedCounts', applyOpts(options));
}

function getAvgSla1(options) {
  return apiCore.get('/core/bms/rma/tickets/avgSla1', applyOpts(options));
}
function RMACountsByDate(options) {
  return apiCore.get('/core/bms/rma/tickets/rma/countsByDate', applyOpts(options));
}

async function RMACountsByDateChart(options) {
  const chartData = {
    series: [],
    categories: [],
  };
  const resp = await RMACountsByDate(options);
  if (resp && resp.status === 200) {
    const coll = resp.data.Results.series;
    chartData.categories = coll.map((i) => {
      return i.openDate;
    });
    // const totals = coll.map(i => { return i.total; }); // convert to string
    const vpu = coll.map((i) => {
      return i.vpu;
    }); // convert to string
    const cameras = coll.map((i) => {
      return i.camera;
    }); // convert to string
    //chartData.series = [{ name: 'total', data: totals }]; // if we just wanted totals
    chartData.series = [
      { name: 'VPU', data: vpu },
      { name: 'Camera', data: cameras },
    ];
  }
  return chartData;
}

function RMAStatusCountsByDate(options) {
  return apiCore.get('/core/bms/rma/tickets/rma/statusCountsByDate', applyOpts(options));
}

async function RMAStatusCountsByDateChart(options) {
  const chartData = {
    series: [],
    categories: [],
  };
  const resp = await RMAStatusCountsByDate(options);
  if (resp && resp.status === 200) {
    const coll = resp.data.Results.series;
    chartData.categories = coll.map((i) => {
      return new Date(i.openDate).toISOString().split('T')[0];
    });
    //chartData.series = [{ name: 'total', data: totals }]; // if we just wanted totals
    chartData.series = [
      {
        name: 'Assigned',
        data: coll.map((i) => {
          return i['Assigned'];
        }),
      },
      {
        name: 'Completed',
        data: coll.map((i) => {
          return i['Completed'];
        }),
      },
      {
        name: 'Customer Responded',
        data: coll.map((i) => {
          return i['Customer Responded'];
        }),
      },
      {
        name: 'In Progress',
        data: coll.map((i) => {
          return i['In Progress'];
        }),
      },
      {
        name: 'Pending Customer',
        data: coll.map((i) => {
          return i['Pending Customer'];
        }),
      },
      {
        name: 'Pending Vendor',
        data: coll.map((i) => {
          return i['Pending Vendor'];
        }),
      },
      {
        name: 'Scheduled',
        data: coll.map((i) => {
          return i['Scheduled'];
        }),
      },
    ];
  }
  return chartData;
}

// expects $interval='month', $dates=3 for dates
function initialDiagnosisCountsByDate(options) {
  return apiCore.get('/core/bms/rma/tickets/rma/initialDiagnosticsCountsByDate', applyOpts(options));
}
async function initialDiagnosisCountsByDateChart(options) {
  const chartData = {
    series: [],
    categories: [],
  };
  const resp = await initialDiagnosisCountsByDate(options);
  if (resp && resp.status === 200) {
    const coll = resp.data.Results.series;
    chartData.categories = coll.map((i) => {
      return new Date(i.openDate).toISOString().split('T')[0];
    });
    //chartData.series = [{ name: 'total', data: totals }]; // if we just wanted totals
    chartData.series = [
      {
        name: 'Box Damage',
        data: coll.map((i) => {
          return i['Box Damage'];
        }),
      },
      {
        name: 'CPU',
        data: coll.map((i) => {
          return i['CPU'];
        }),
      },
      {
        name: 'Camera Focus/Quality',
        data: coll.map((i) => {
          return i['Camera Focus/Quality'];
        }),
      },
      {
        name: 'Condensation',
        data: coll.map((i) => {
          return i['Condensation'];
        }),
      },
      {
        name: 'Disk',
        data: coll.map((i) => {
          return i['Disk'];
        }),
      },
      {
        name: 'Environmental',
        data: coll.map((i) => {
          return i['Environmental'];
        }),
      },
      {
        name: 'Ethernet Connection',
        data: coll.map((i) => {
          return i['Ethernet Connection'];
        }),
      },
      {
        name: 'Graphics Card',
        data: coll.map((i) => {
          return i['Graphics Card'];
        }),
      },
      {
        name: 'Motherboard',
        data: coll.map((i) => {
          return i['Motherboard'];
        }),
      },
      {
        name: 'NIC',
        data: coll.map((i) => {
          return i['NIC'];
        }),
      },
      {
        name: 'Not supplied',
        data: coll.map((i) => {
          return i['"Not supplied'];
        }),
      },
      {
        name: 'Other',
        data: coll.map((i) => {
          return i['Other'];
        }),
      },
      {
        name: 'Physical Damage',
        data: coll.map((i) => {
          return i['Physical Damage'];
        }),
      },
      {
        name: 'Pixellot Dongle',
        data: coll.map((i) => {
          return i['Pixellot Dongle'];
        }),
      },
      {
        name: 'Power Supply',
        data: coll.map((i) => {
          return i['Power Supply'];
        }),
      },
      {
        name: 'RAM',
        data: coll.map((i) => {
          return i['RAM'];
        }),
      },
      {
        name: 'Software',
        data: coll.map((i) => {
          return i['Software'];
        }),
      },
      {
        name: 'Sound',
        data: coll.map((i) => {
          return i['Sound'];
        }),
      },
      {
        name: 'Water Damage',
        data: coll.map((i) => {
          return i['Water Damage'];
        }),
      },
    ];
  }
  return chartData;
}

function RMASInitialDiagnosisCounts(options) {
  return apiCore.get('/core/bms/rma/tickets/rma/initialDiagnosisCounts', applyOpts(options));
}
function RMASVPUInitialDiagnosisCounts(options) {
  return apiCore.get('/core/bms/rma/tickets/rma/vpuInitialDiagnosisCounts', applyOpts(options));
}
function RMASCameraInitialDiagnosisCounts(options) {
  return apiCore.get('/core/bms/rma/tickets/rma/cameraInitialDiagnosisCounts', applyOpts(options));
}

async function RMASLA1ByDateCounts(options) {
  const chartData = {
    series: [],
    categories: [],
  };
  const resp = await apiCore.get('/core/bms/rma/tickets/avgSla1Months', applyOpts(options));
  if (resp && resp.status === 200) {
    const coll = resp.data.Results;
    chartData.categories = coll.map((i) => {
      return new Date(i.outShipDate).toISOString().split('T')[0];
    });
    //chartData.series = [{ name: 'total', data: totals }]; // if we just wanted totals
    chartData.series = [
      {
        name: 'SLA',
        data: coll.map((i) => {
          return Number.parseFloat(i.avgSla1).toFixed(0);
        }),
      },
    ];
  }
  return chartData;
}

async function RMAPriorityByIssueTypeCounts(options) {
  const chartData = {
    series: [],
    categories: [],
  };
  const resp = await apiCore.get('/core/bms/rma/tickets/priorityCountsByIssueType', applyOpts(options));
  if (resp && resp.status === 200) {
    const coll = resp.data.Results;
    chartData.categories = coll.map((i) => {
      return i.priority;
    });
    chartData.series = [
      {
        name: 'VPU',
        data: coll.map((i) => {
          return i['User: Workstation'];
        }),
      },
      {
        name: 'Camera',
        data: coll.map((i) => {
          return i['Camera'];
        }),
      },
    ];
  }

  return chartData;
}

async function UnitsInOutWarrantyByDefectCodeCounts(options) {
  return await apiCore.get('/core/bms/rma/tickets/rma/unitsInOutWarrantyByDefectCodeCounts', applyOpts(options));
}
async function UnitsInOutWarrantyByHardwareStatusCounts(options) {
  return await apiCore.get('/core/bms/rma/tickets/rma/unitsInOutWarrantyByHardwareStatusCounts', applyOpts(options));
}

async function DynaColorUnitsPending() {
  return await apiCore.get('/core/bms/rma/tickets/rma/dynaColorUnitsPending');
}

async function DynaColorUnitsPendingByDays() {
  return await apiCore.get('/core/bms/rma/tickets/rma/dynaColorUnitsPendingByDays');
}
async function UnitsPendingInRepair(options) {
  return await apiCore.get('/core/bms/rma/tickets/rma/pendingInRepair', applyOpts(options));
}
async function UnitsPendingInRepairByDays(options) {
  return await apiCore.get('/core/bms/rma/tickets/rma/pendingInRepairByDays', applyOpts(options));
}

//  EIX Onboarding/Offboarding BMS Functions
function getQueues(options) {
  return apiCore.get('/core/bms/ticket/queues', applyOpts(options));
}

function getPriorities(options) {
  return apiCore.get('/kaseya/bmsv2/tickets/lookup/priority', applyOpts(options));
}

function getIssueTypes(options) {
  return apiCore.get('/kaseya/bmsv2/tickets/lookup/issueTypes', applyOpts(options));
}

function getSubIssueTypes(id, options) {
  return apiCore.get(`/kaseya/bmsv2/tickets/lookup/issueTypes/${id}/subissueTypes`, applyOpts(options));
}

function getSLAs(options) {
  return apiCore.get('/kaseya/bmsv2/tickets/lookup/slas', applyOpts(options));
}

function getTypes(options) {
  return apiCore.get('/kaseya/bmsv2/tickets/lookup/ticketType', applyOpts(options));
}

function getByLookup(lookup, options) {
  return apiCore.get(lookup, applyOpts(options));
}

function reissueReturnLabel(id) {
  return apiCore.post(`/core/bms/rma/reissueReturnLabel/${id}`);
}

function cancelShippingReturnLabel(id) {
  return apiCore.delete(`/core/bms/rma/cancelShippingReturnLabel/${id}`);
}

async function getPDFReturnLabel(id, options) {
  return apiCore.get(`/core/bms/rma/shippingLabelAsPDF/${id}`, applyOpts(options));
}

async function getPDFOrderLabel(id, trackingNumber, options) {
  return await apiCore.get(`/core/bms/order/shippingLabelAsPDF/${id}/${trackingNumber}`, applyOpts(options));
}

function getOrderReasons() {
  const req = {
    params: { $select: 'orderReason', $orderBy: 'orderReason asc', $top: 8000000, $skip: 0 },
  };
  return repo.getTicketOrders(req).then((resp) => {
    return uniqBy(resp.data.Results, 'orderReason').map((m) => m.orderReason);
  });
}

export default repo;
