// import * as Vue from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes/index';
import NProgress from 'nprogress';
//import 'nprogress/nprogress.css'; see index.html and public/static/css/nprogress.css
import store from '@/store/state';
import { evalLevel } from '@/api/core/meta/userPermission.meta';

const authService = store.getters['account/authService'];
// grab from store vs instantiating new object
const isImpersonated = store.getters['account/isImpersonated'];

// Create a router instance using Vue Router with web history mode and defined routes
const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_SITE_PATH),
  routes,
});

// Handle any errors that occur during routing
router.onError((error, to) => {
  // If there's an issue loading a dynamically imported module, reload the page
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location = to.fullPath;
  }
  console.log('Router error:', error);
});

// Define router guards for route access control
router.beforeEach(async (to, from, next) => {
  console.log('Router beforeEach:', to.fullPath);

  NProgress.start(); // Start the progress bar at the beginning of each route change

  // Ensure we have the latest user information from the store
  const user = store.getters['account/user'];
  const isPublic = to.matched.some((record) => record.meta.public); // Check if the route is public
  const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut); // Check if the route is only for logged-out users

  try {
    console.log('Initializing Router Try:', to.fullPath);
    // Check if the user is authenticated
    let isAuthenticated = await authService.isAuthenticationValid(to.fullPath);
    console.log('API isAuthenticated:', isAuthenticated, to.fullPath);
    
    if(!isAuthenticated) {
      console.log('API isAuthenticated failed');
      const idpUser = store.getters['account/ssoToken'];
      const auth = store.getters['account/auth'];
      const state = store.getters['account/state'];
      console.log('idpUser', idpUser, 'auth', auth, 'state', JSON.stringify(state));
      // on router change, if they are not authenticated, try to refresh the token before logging them in...
      if(idpUser || auth) {
        await authService
        .refreshToken()
        .then(async (resp) => {
          console.log('Router Refresh: ', resp);
          isAuthenticated = await authService.isAuthenticationValid(to.fullPath);
          console.log('API RE-Authenticated:', isAuthenticated, to.fullPath);
        })
      }      
    }

    // If impersonated user session is invalid, clear impersonation
    if (isImpersonated && !isAuthenticated) {
      console.log('Impersonated user session is invalid, clearing impersonation');
      store.dispatch('account/clearImpersonation', '');
    }

    // If the route is not public and the user is not authenticated, redirect to login
    if (!isPublic && !isAuthenticated) {
      try {
        await authService.loginUser().then((resp) => {
          console.log('Login response:', resp);
          // Attempt to login the user
          if(resp) {
            const targetPath = to.path;
            store.dispatch('account/saveRedirectPath', targetPath); // Save the target path to redirect after login
            console.log('Login successful');
            // Redirect to the originally requested page after successful login
            console.log('Redirecting to:', targetPath);
            return next(targetPath);
          } else {
            console.log('Login failed? No Response from loginUser', to.fullPath);
          }
        });
      } catch (loginError) {
        console.error('Login failed:', loginError.message);
        // Redirect to a generic login or error page on login failure
        return next(false);
      }
    } else {
      if(to.fullPath === '/[object%20Object]') {
        console.log('from:', from.fullPath, 'to:', to.fullPath);
        return next(false);
      }
    
      if(to.fullPath === from.fullPath) {
        console.log('from:', from.fullPath, 'to:', to.fullPath);
        return next(to.fullPath + '?d=' + Date.now());
        // this is a hack to force a reload of the page
      }
    }

    // If the user is already authenticated and trying to access a logged-out only page, redirect to dashboard
    if (isAuthenticated && onlyWhenLoggedOut) {
      console.log('User is already authenticated, redirecting to dashboard');
      return next('/dashboard/main');
    }

  } catch (err) {
    console.error(err.message); // Log any errors that occur during authentication
  }

  // Evaluate the user's permission level for accessing the route
  if (!evalLevel(user, to)) {
    return next('/access-denied'); // Redirect to an access denied page if the user lacks permission
  }

  next(); // Allow the route navigation to proceed
  return;
  
});

// After each route change, update the page title and complete the progress bar
router.afterEach((to) => {
  try {
    // Set the document title based on the route's meta information
    document.title = to.meta.title ? `${import.meta.env.VITE_VUE_APP_TITLE} - ${to.meta.title}` : import.meta.env.VITE_VUE_APP_TITLE;
  } catch (error) {
    console.error('Error setting document title:', error);
  }
  //  TODO: add some analytics codes here if we want
  NProgress.done(); // Complete the progress bar when the route change is done
});

export default router;
