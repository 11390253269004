import config from '@/common/config';
import store from '@/store/state';
import { PublicClientApplication } from '@azure/msal-browser';
import { differenceInSeconds } from 'date-fns';
import ApiService from '@/api/index';
import { msalConfig } from '../authConfig';

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();


const loginRequest = {
  prompt: 'select_account',
  scopes: config.sso.SCOPES,
};

class AuthO365Service {
  static signInType = {
    popup: 'loginPopup',
    redirect: 'loginRedirect',
  };
  signInMethod = config.sso.loginMethod;
  static watchDogTimerId = null;

  constructor(signInMethod) {
    this.signInMethod = signInMethod || AuthO365Service.signInType.redirect;
  }

  async handleRedirectPromise() {
    await msalInstance
    .handleRedirectPromise()
    .then(async (tokenResponse) => {
      if(!tokenResponse) {
        // If the tokenResponse === null, you are not coming back from an auth redirect.
        console.log('No token response');
        await msalInstance.loginRedirect();
      } else {
        // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
        console.log('token response', tokenResponse);
        
        const accounts = await msalInstance.getAllAccounts()
        console.log('# of accounts', accounts.length)
        console.log(JSON.stringify(accounts));

        if (accounts.length === 0) {
          // No user signed in
          console.log('No user signed in');
          await msalInstance.loginRedirect();
        } else {
          // User signed in
          console.log('User signed in');
          await this.refreshToken(tokenResponse);
        }
      }
    })
    .catch((error) => {
      console.log(error)
        // handle error, either in the library or coming back from the server
    });
  }

  cancelWatchdog() {
    if (AuthO365Service.watchDogTimerId) {
      clearTimeout(AuthO365Service.watchDogTimerId);
    }
  }

  async setWatchdog() {
    // this.cancelWatchdog();
    AuthO365Service.watchDogTimerId = setTimeout(
      async function () {
        console.log("we've timed out, effectively no longer waiting to login or have logged in.")
        store.dispatch('account/pendingLogin', false); // we've timed out, effectively no longer waiting to login
        // store.dispatch('alerts/addAlert', {
        //   alertText: 'SSO Login TimedOut',
        //   alertType: 'error',
        //   delay: 3500,
        // });
        // store.dispatch('alerts/addAlert', {
        //   alertText: 'Redirecting - Standby',
        //   alertType: 'warning',
        //   delay: 2000,
        // });
        // store.dispatch;
        await new Promise((resolve) => setTimeout(resolve, 1000)); // 1 sec delay before proceeding
        location.pathname = '/'; // redirect back to the root & let our processes take over
      }.bind(this),
      6000,
    );
  }

  async acquireTokenByCode(request) {
    console.log('autho365:acquireTokenByCode Request', request);
    return await msalInstance.acquireTokenByCode(request)
      .then((response) => {
        console.log('autho365:acquireTokenByCode response', response);
        return response;
      })
      .catch((error) => {
        console.log('autho365:acquireTokenByCode error', error);
        return error;
      });
  }

  async redirectToken() {
    console.log('autho365:redirectToken: no newSSOToken - requesting login  to get it');
    newSSOToken = await msalInstance.loginRedirect(loginRequest);
    console.log('newSSOToken', newSSOToken)
    return newSSOToken;
  }


  /**
    * get silentRequest
  */
  async getSilentRequest(account) {
    const limitedScopes = config.sso.SCOPES.filter((scope) => ['profile', 'openid'].indexOf(scope) === -1);

    const silentRequest = {
      scopes: limitedScopes,
      loginHint: account.username,
    };

    try {
      const loginResponse = await msalInstance.ssoSilent(silentRequest);
      console.log('loginResponse', loginResponse);  
    } catch (err) {
      // if (err instanceof InteractionRequiredAuthError) {
      //     const loginResponse = await msalInstance
      //       .loginRedirect(silentRequest)
      //       .catch((error) => {
      //           console.log(error);
      //       });
      // } else {
      //     console.log(err)
      // }
      console.log(err)
    }
  }


  /**
   * Called from either signInRedirect or signInPopUp - when the user has an existing token
   * TODO NEW: or can be called from refreshToken - when we need to get a new token
   * @param {*} ssoToken
   * @returns
   */
  async refreshToken(ssoToken) {
    console.log('Old Token', ssoToken, ssoToken?.account);
    let newSSOToken = null;
    if (ssoToken && ssoToken?.account) {
      /***
       * Will either silently acquire access token: msal.acquireTokenSilent
       * OR
       * Redirect  user's browser window: msal.acquireTokenRedirect
       *
       * limitedScopes is an attempt to prevent msal from getting a brand new
       * token on acquireTokenSilent due to the presence of openid and profile
       * scopes  So far I have only found this mentioned in github issue tickets.
       ***/
      console.log('autho365:refreshToken ssoToken is set: persist & capture/map Montra user');
      const limitedScopes = config.sso.SCOPES.filter((scope) => ['profile', 'openid'].indexOf(scope) === -1);
      console.log('limitedScopes', limitedScopes);
      let obj = {
        account: ssoToken.account,
        scopes: limitedScopes,
        redirectUri: window.location.origin + config.sso.REDIRECT_URI,
        forceRefresh: true,
        extraQueryParameters: {
          login_hint: ssoToken.account.idTokenClaims.preferred_username,
          domain_hint: 'organizations',
        },
      };
      console.log(obj);
      newSSOToken = await msalInstance
        .acquireTokenSilent(obj)
        .then(async (resp) => {
          console.log('autho365:refreshToken', resp);
          // getSilentToken does not return original account details.
          resp.account = ssoToken.account;
          await this.processUpdateToken(resp);
        })
        .catch(async (err) => {
          console.log('autho365:refreshToken msalInstance.getSilentToken error - this is not expected', err);
        });
    } else {
      console.log('autho365:refreshToken no ssoToken or account  - ??');
    }
    return newSSOToken;
  }

  async loadUser() {
    console.log('autho365:loadUser');
    const impersonated = store.getters['account/isImpersonated'] || false;
    if (!impersonated) {
      const user = await ApiService.apiCore.get('auth/user', { params: { source: 'o365' } }).then((authResp) => {
        // console.log(authResp, authResp.status)
        if (authResp && authResp.status === 200) {
          return authResp.data; // echo what api received (JWT) or decrypted idToken
        } else {
          // console.log('Auth Error')
          throw new Error(`ValidateLogin ${authResp}`);
        }
      });

      console.log('autho365:loadUser user', user);

      await ApiService.apiCore.get(`/core/permission/user/${user.id}?$expand=[userRoles,permissions]`).then((resp) => {
        if (resp && resp.status === 200) {
          if (resp.data) {
            const { permissions, userRoles } = resp.data.Results;
            user.permissionsString = permissions?.map((p) => p.permissionCode).toString();
            if (user.permissions != permissions) {
              user.permissions = permissions;
            }
            if (user.userRoles != userRoles) {
              user.userRoles = userRoles;
            }
          }
        }
      });

      user.picture = user.picture || 'https://placeimg.com/250/250/people';
      // if (state.user.length > 1) {
      //     return state.user[0];
      // } else {
      //     store.commit("account/SET_USER", user);
      //     return user;
      // }
      store.commit('account/SET_USER', user);
      return user;
    }
  }

  /************************************************************************************************
   *   New Methods
   */

  /**
   *
   * @param {*} newSSOToken
   * accessToken → String Access token received as part of the response.
   * account → AccountInfo? An account object representation of the currently signed-in user
   * authority → String The authority that the token was retrieved from.
   * cloudGraphHostName → String The AAD graph host.
   * expiresOn - Date representing relative expiration of access token.
   * extExpiresOn → DateTime? Date representing extended relative expiration of access token in case of server outage.
   * familyId → String? Family ID identifier, usually only used for refresh tokens.
   * fromCache → bool Boolean denoting whether token came from cache.
   * hashCode → int The hash code for this object.
   * idToken → String ID token received as part of the response.
   * idTokenClaims → Map<String, dynamic> MSAL-relevant ID token claims.
   * msGraphHost → String? The Microsoft Graph host.
   * runtimeType → Type A representation of the runtime type of the object.
   * scopes → List<String> Scopes that are validated for the respective token.
   * state → String? Value passed in by user in request.
   * tenantId → String tid claim from ID token.
   * tokenType → String read-only
   * uniqueId → String oid or sub claim from ID token.
   * @returns
   */
  async processToken(newSSOToken) {
    console.log('processToken', newSSOToken);
    // returns Montra user
    let user = null; // this is what we'll return
    // console.log('autho365:processToken newSSOToken is set: persist & capture/map Montra user');
    try {
      this.cancelWatchdog();
      // We add the access token as an authorization header for our Axios requests to our API
      store.commit('account/SET_SSO_TOKEN', newSSOToken);
      // store.commit('account/SET_AUTHENTICATED', true);

      const userAuth = {
        accessToken: newSSOToken.idToken, // idToken is the JWT we need, not accessToken
        idToken: newSSOToken.idToken,
        refreshToken: null,
        state: newSSOToken.state,
        expiresIn: differenceInSeconds(new Date(Date.parse(newSSOToken.expiresOn)), new Date()), // laterDate, earlierDate
        tokenType: newSSOToken.tokenType,
        scope: newSSOToken.scopes.join(' '),
        expiresAt: newSSOToken.expiresOn,
        account: newSSOToken.account,
        tenantId: newSSOToken.tenantId,
      };
      store.commit('account/SET_USER_AUTH', userAuth);
      console.log('user auth step', userAuth);
      msalInstance.setActiveAccount(newSSOToken.account);
      user = await this.loadUser();
      console.log('user', user);
      
    } catch (err) {
      console.log('processToken failed', err);
      await store.dispatch('account/logoutUser');
      location.pathname = '/unexpected';
    }
    return user;
  }

  async processUpdateToken(newSSOToken) {
    let userAuth = null; // this is what we'll return
    console.log('autho365:processUpdateToken newSSOToken refreshed');
    try {
      this.cancelWatchdog();
      // We add the access token as an authorization header for our Axios requests to our API
      console.log('processUpdateToken newSSOToken is set: persist & capture/map Montra user');
      store.commit('account/SET_SSO_TOKEN', newSSOToken);
      // store.commit('account/SET_AUTHENTICATED', true);

      userAuth = {
        accessToken: newSSOToken.idToken, // idToken is the JWT we need, not accessToken
        idToken: newSSOToken.idToken,
        refreshToken: null,
        state: newSSOToken.state,
        expiresIn: differenceInSeconds(new Date(Date.parse(newSSOToken.expiresOn)), new Date()), // laterDate, earlierDate
        tokenType: newSSOToken.tokenType,
        scope: newSSOToken.scopes.join(' '),
        expiresAt: newSSOToken.expiresOn,
        account: newSSOToken.account,
        tenantId: newSSOToken.tenantId,
      };
      store.commit('account/SET_USER_AUTH', userAuth);
      msalInstance.setActiveAccount(newSSOToken.account);
      userAuth = await this.loadUser();
    } catch (err) {
      // maybe dont log someone out if their auth didn't update correctly?
      console.log('auth0365 processUpdateToken Error', err);
      // store.dispatch("account/clearUser");
      // location.pathname = "/unexpected";
    }
    return userAuth;
  }

  async signIn() {
    let user = null; // this is what we'll return
    const ssoToken = store.getters['account/ssoToken'];
    user = store.getters['account/user'];
    // console.log('signInMethod', this.signInMethod)
    try {
      if(ssoToken !== null) {
        const newSSOToken = await this.refreshToken(ssoToken);
        console.log('newSSOToken', newSSOToken)
        if (newSSOToken) {
          console.log('autho365:signIn newSSOToken is set: persist & capture/map Montra user');
          user = await this.processUpdateToken(newSSOToken);
        } else {
          console.log('autho365:signIn no newSSOToken - requesting login redirect to get it');
          store.dispatch('account/pendingLogin', true);
          user = await this.signInRedirect(ssoToken);
        }
      } else {      
        store.dispatch('account/pendingLogin', true);
        user = await this.signInRedirect(ssoToken);
      }
    } catch (err) {
      console.log(err);
      console.log('autho365:signIn failed - requesting login redirect to get it');
      store.dispatch('account/pendingLogin', true);
      user = await this.signInRedirect(ssoToken);
    }
    // User will never be set if we had to redirect use to Azure AD auth pages
    // If we get this far - capture all the deets and redirect user to A) where they were or B) toplevel menu item they have access to.
    if (user) {
      // console.log('autho365:signIn user has been returned from signIn(), account/pendingLogin set to false');
      store.dispatch('account/pendingLogin', false);
      let reqPath = store.getters['account/requestedPath'];
      // console.log('reqPath', reqPath)
      if (!reqPath || (reqPath && reqPath.length == 1)) {
        reqPath = store.getters['account/defaultHref'];
      }
      console.log('autho365:signIn redirecting to', reqPath);

      store.dispatch('account/resetRequestedPath', null); // reset path

      if(reqPath === '/') {
        reqPath = '/people/eix';
      }
      location.pathname = reqPath;
    }
    return user;
  }

  async getAllAccounts() {
    console.log('autho365:getAllAccounts');
    return await msalInstance.getAllAccounts();
  }

  /**
   * (Prior method to 5/20/2022)
   * Method called from store.logoutUser => authentication.service == AAD method
   * This method will sign user out of AAD (may not be totally expected)
   */
  async signOutFromAAD() {
    const currentAccounts = msalInstance.getAllAccounts();
    const accountId = currentAccounts[0].homeAccountId;
    const logoutRequest = {
      account: msalInstance.getAccountByHomeId(accountId),
    };
    msalInstance.logoutRedirect(logoutRequest);
  }

  /**
   * (Active since 5/20/2022 - do not log user out from AAD, only from VIA)
   * Method called from store.logoutUser => authentication.service == AAD method
   * This method assumes the store authentication properties have been cleared, and will
   * simply redirect user to home page (auth page) w/o touching AAD
   */
  async signOut() {
    // console.log('autho365:signOut');
    await store.dispatch('account/clearUser', '');
    location.pathname = '/';
  }


  /**
   * If ssoToken is present, attempt to refresh the token and return Montra User
   * If unsuccessful, a popup modal will be provided for user to enter credentials
   * @param {*} ssoToken
   * @returns Montra User if token is successfully obtained
   */
  async signInPopUp(ssoToken) {
    let user = null;
    let newSSOToken = null;
    if (ssoToken && ssoToken.account) {
      newSSOToken = await this.refreshToken(ssoToken);
    }
    if (!newSSOToken) {
      newSSOToken = await this.getTokenPopUp();
    }
    if (newSSOToken) {
      user = await this.processToken(newSSOToken);
    }
    return user;
  }

  /**
   * If ssoToken is present, attempt to refresh the token and return Montra User
   * If unsuccessful, a redirect page will be provided for user to enter credentials
   * @param {*} ssoToken
   * @returns Montra User if token is successfully obtained
   */
  async signInRedirect(ssoToken) {
    let user = null;
    let newSSOToken = null;
    // console.log(ssoToken, ssoToken?.account);
    if (ssoToken && ssoToken?.account) {
      newSSOToken = await this.refreshToken(ssoToken);
      if (newSSOToken) {
        user = await this.processToken(newSSOToken);
      }
    } else {
      await this.getTokenRedirect(); // will never return newSSOToken - page will be redirected for user login
    }
    return user;
  }

  /**
   * Specifically will use a PopUp login page for User credential input
   * @returns Token if successful
   */
  async getTokenPopUp() {
    let newSSOToken = null;
    // console.log('autho365:getTokenPopUp no newSSOToken - requesting login pop-up to get it');
    newSSOToken = await msalInstance.loginPopup(loginRequest);
    return newSSOToken;
  }

  /**
   * Specifically will use a processUpdateToken login page for User credential input
   * @returns Token if successful
   */
  async getTokenRedirect() {
    // await this.init();

    const newSSOToken = null;
    console.log('autho365:getTokenRedirect no newSSOToken - requesting login redirect to get it');
    //await msalInstance.acquireTokenRedirect({...loginRequest, redirectStartPage: window.location.href});
    await msalInstance.loginRedirect({ ...loginRequest }).catch((err) => {
      console.log(err);
    });
    return newSSOToken;
  }

  /* This will be called from a Page onload event (e.g. created)
    Handles the redirect response from msal-browser redirect - ultimately return a target route (if successful) so we nav the user to the correct page.
    It honestly doesn't matter what route path the user was on - they were kicked out & had to auth through Azure AD.
    Look to see what the top-level menu item is, and navigate there
    */
  async handleResponse(resp) {
    this.cancelWatchdog();
    if (resp !== null) {
      store.dispatch('account/pendingLogin', false); // only reset pendingLogin after we've eval'd user
      await this.processToken(resp);
      /**  new */
      const hrefTarget = store.getters['account/defaultHref'];
      return hrefTarget;
    }
    return null;
  }
}

export { AuthO365Service };
