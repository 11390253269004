import apiCore from '@/api/api.common';
// import { Cache } from 'axios-extensions';

// const FIVE_MINUTES = 1000 * 60 * 5;
// const CAPACITY = 100;
// const metricCache = new Cache({ ttl: FIVE_MINUTES, max: CAPACITY });
const dftOptions = { useCache: true };

const repo = {
  apiCore: apiCore,
  dimensions: getDimensions,
  //getTenantById: getTenantById,
  //createUser: createUser,
  getRMMDeviceCounts: getRMMDeviceCounts,
  getHardwareAssetCounts: getHardwareAssetCounts,
};

function applyOpts(options) {
  return Object.assign(options || dftOptions);
}
function getDimensions(options) {
  return apiCore.get('/core/metric/dimensions', applyOpts(options));
}
function getRMMDeviceCounts(options) {
  // const DAILY = 1000 * 60 * 300; // 5 hours
  // const devCache = new Cache({ ttl: DAILY, max: CAPACITY });
  const opt = Object.assign({ useCache: true }, options || { $expand: '[series]' });
  return apiCore.get('/core/metric/dimensions/rmmDeviceCounts', applyOpts(opt));
}

function getHardwareAssetCounts(options) {
  // const DAILY = 1000 * 60 * 300; // 5 hours
  // const devCache = new Cache({ ttl: DAILY, max: CAPACITY });
  const opt = Object.assign({ useCache: true }, options || { $expand: '[series]' });
  return apiCore.get('/core/metric/dimensions/hardwareAssetCounts', applyOpts(opt));
}

export default repo;
