<template>
  <v-app-bar>
    <v-toolbar-title class="ml-0 keepVisible">
      <div class="d-flex align-center">
        <v-app-bar-nav-icon @click="toggleDrawer">
          <v-icon size="small"> fal fa-bars </v-icon>
        </v-app-bar-nav-icon>
        <div v-if="title">{{ title }}</div>
      </div>
    </v-toolbar-title>

    <v-spacer />

    <v-bottom-sheet>
      <template #activator="{ props }">
        <v-btn variant="text" size="small" v-bind="props" class="d-md-none clickable px-0">
          <v-icon>fal fa-bell</v-icon>
        </v-btn>
      </template>
      <v-sheet class="pa-3">
        <div v-for="(widget, index) in infoBarContent" :key="index">
          <component :is="widget" class="my-2" />
          <v-divider />
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <!-- Update % and trend -->
    <!-- <tenant-uptime class="d-none d-sm-inline-flex"></tenant-uptime>
    <v-divider vertical class="ml-2 mr-3 d-none d-sm-inline-flex"></v-divider> -->

    <!-- Add the info bar components defined in infoBarContent -->
    <div class="slideBeneath d-flex align-center">
      <div v-for="widget in infoBarContent" :key="widget.id">
        <component :is="widget" class="d-none d-md-inline-flex" />
        <v-divider id="widget + '-divider'" vertical class="ma-2" />
      </div>
    </div>

    <!-- Fulfillment / Cart area -->
    <Fulfillment v-if="fulfillmentNew" />

    <!-- Current login user / profile options -->
    <app-user :refresh="refresh" />
  </v-app-bar>
</template>

<script>
import Util from '@/util/index.js';

// import TenantTicketActive from '@/components/navtoolbar/widgets/TenantTicketActive.vue';
import TenantAlerts from '@/components/navtoolbar/widgets/TenantAlerts.vue';
import AppUser from '@/components/navtoolbar/widgets/AppUser.vue';
// import { mapMutations } from "vuex";
// import EixPeopleInfo from '@/components/navtoolbar/widgets/EixPeopleInfo.vue';
import EixPeopleOnboard from '@/components/navtoolbar/widgets/EixPeopleOnboard.vue';
import EixPeopleOffboard from '@/components/navtoolbar/widgets/EixPeopleOffboard.vue';
import Fulfillment from '@/components/navtoolbar/widgets/Fulfillment.vue';

import {hasPermission} from '@/common/permission.mapper.js';

//import { mapMutations } from "vuex";
import store from '@/store/state.js';

// A mapping of user feature data to their associated components
const featureComponents = [
  { featureKey: 'Device', component: 'tenant-alerts', permission: 'view_asset' },
  // { featureKey: 'Ticket', component: 'tenant-ticket-active' },
  // { featureKey: 'People', component: 'eix-people-info' },
  { featureKey: 'OnBoard', component: 'eix-people-onboard', permission: 'onboard' },
  { featureKey: 'OffBoard', component: 'eix-people-offboard', permission: 'offboard' },
];

export default {
  name: 'AppToolbar',
  components: {
    // TenantTicketActive,
    TenantAlerts,
    // EixPeopleInfo,
    EixPeopleOnboard,
    EixPeopleOffboard,
    Fulfillment,
    AppUser,
  },
  props: {
    refresh: { type: Function, required: false, default: () => {} },
  },
  data: () => ({}),
  computed: {
    title() {
      return store.state.title;
    },
    drawer() {
      return store.state.drawer;
    },
    user() {
      return this.$store.getters['account/user'];
    },
    fulfillmentNew() {
      return hasPermission('create_order');
    },
    toolbarColor() {
      return this.$vuetify.options.extra.mainNav;
    },
    infoBarContent() {
      const visibleFeatures = [];

      if (!this.user) return visibleFeatures;

      // if beta is enabled, show all features
      featureComponents.forEach((component) => {
        if(hasPermission(component.permission)) {
          visibleFeatures.push(component.component);
        }
      });
      

      // TODO: if we dont have features anymore we need to figure out what to do with this function?

      return visibleFeatures;
    },
  },
  methods: {
    toggleDrawer() {
      store.commit('toggleDrawer');
    },
    handle_function_call(function_name) {
      if (typeof this[function_name] === 'function') this[function_name]();
    },
    handleFullScreen() {
      Util.toggleFullScreen();
    },
  },
};
</script>
