export default [
  {
    path: '/rma_requests',
    name: 'rma_requests',
    component: () => import(/* webpackChunkName: "rma_requests" */ '@/pages/rma_requests/dashboard.vue'),
    meta: {
      public: false,
      onlyWhenLoggedOut: false,
      parent: 'devices',
      permissions: ['view_rma', 'full_logistics'],
      title: 'RMA Requests',
    },
    children: [
      {
        name: 'requests',
        path: 'requests',
        component: () => import(/* webpackChunkName: "devices-warehouse" */ '@/pages/rma_requests/requests.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'devices',
          permissions: ['view_rma', 'full_logistics'],
          title: 'RMA Requests',
        },
      },
      {
        path: 'admin',
        component: () => import(/* webpackChunkName: "rma_requests_admin" */ '@/pages/rma_requests/admin.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'devices',
          permissions: ['view_rma', 'full_logistics'],
          title: 'RMA Admin',
        },
        children: [
          {
            name: 'rmaDetailAdvanced',
            path: 'rmaDetailAdvanced',
            component: () =>
              import(/* webpackChunkName: "rmaDetailAdvanced" */ '@/pages/rma_requests/rmas/rmaDetailAdvanced.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['view_rma', 'full_logistics'],
              title: 'RMA Detail Advanced',
            },
          },
          {
            name: 'rmaDetailReg',
            path: 'rmaDetailReg',
            component: () =>
              import(/* webpackChunkName: "rma_rmaDetailReg" */ '@/pages/rma_requests/rmas/rmaDetailReg.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['view_rma', 'full_logistics'],
              title: 'RMA Detail',
            },
          },
          {
            name: 'rmaTicketDetail',
            path: 'rmaTicketDetail',
            component: () =>
              import(/* webpackChunkName: "sd_rmaDetails" */ '@/pages/rma_requests/rmas/rmaTicketDetail.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['view_rma', 'full_logistics'],
              title: 'RMA Service Item Detail',
            },
          },
          {
            name: 'rmaReturnToStock',
            path: 'rmaReturnToStock',
            component: () =>
              import(/* webpackChunkName: "rma_rmaReturnToStock" */ '@/pages/rma_requests/rmas/rmaReturnToStock.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['view_rma', 'full_logistics'],
              title: 'Return To Stock',
            },
          },
        ],
      },
    ],
  },
];
