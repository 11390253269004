import store from '@/store/state.js';

/**
 * check if user/account contains active feature and new permissions
 * @param {*} feature
 * @example hasPermission('full_logistics')
 */
function hasPermission(permission) {
  const user = store.getters['account/user'];  
  let hasPermission = false;

  if(permission) {
    hasPermission = user?.permissionsString?.includes(permission);
  }

  const isSuperAdmin = user?.permissionsString?.includes('super_admin') || false;
  // let hasPermissionMapped = false;
  // const mapping = permissionMapper.find(i => (i.featureRole.toLowerCase() === permission.toLowerCase() || i.permission.toLowerCase() === permission.toLowerCase()));
  // if(mapping){
  //   hasPermissionMapped = user?.permissionsString?.includes(mapping.permission) || user?.permissionsString?.includes(mapping?.override) || false;
  // }
  return hasPermission || isSuperAdmin // || hasPermissionMapped;
}


function hasRole(role) {
  const user = store.getters['account/user'];
  let ret = 0;

  user?.userRoles?.forEach((userRole) => {
    if(userRole.roleCode === role && userRole.isAssigned){
      ret++;
    }
  });

  return ret > 0;
}

// const permissionMapper = [
//   {
//     featureRole: 'SuperAdmin',
//     permission: 'super_admin'
//   },
//   {
//     featureRole: 'Administrator',
//     permission: 'view_ticket',
//   },
//   {
//     featureRole: 'device',
//     override: 'full_logistics',
//     permission: 'view_asset'
//   },
//   {
//     featureRole: 'ticket',
//     override: 'full_service_desk',
//     permission: 'view_ticket',
//   },
//   {
//     featureRole: 'asset:create',
//     override: 'full_logistics',
//     permission: 'create_asset'
//   },
//   {
//     featureRole: 'asset:delete',
//     override: 'full_logistics',
//     permission: 'delete_asset'
//   },
//   {
//     featureRole: 'asset:update',
//     override: 'full_logistics',
//     permission: 'edit_asset'
//   },
//   {
//     featureRole: 'asset:assignment',
//     override: 'full_logistics',
//     permission: 'edit_asset'
//   },
//   {
//     featureRole: '',
//     override: 'full_logistics',
//     permission: 'view_inventory'
//   },
//   {
//     featureRole: '',
//     override: 'full_logistics',
//     permission: 'edit_inventory'
//   },
//   {
//     featureRole: 'fulfillment:new',
//     override: 'full_logistics',
//     permission: 'create_order'
//   }, 
//   {
//     featureRole: 'fulfillment:delete',
//     override: 'full_logistics',
//     permission: 'delete_order'
//   }, 
  
// ];

export { hasPermission, hasRole };