export default [
  {
    id: 68930,
    name: 'CPU',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 68931,
    name: 'RAM',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 68932,
    name: 'Disk',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 68933,
    name: 'Physical Phone',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 68934,
    name: 'Display / Monitor',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 68935,
    name: 'Mouse',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 68936,
    name: 'Keyboard',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 68937,
    name: 'Charger',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 68938,
    name: 'Battery',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 68939,
    name: 'Local Printer',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 68948,
    name: 'Other',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 68949,
    name: 'Windows',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 69044,
    name: 'macOS',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 69073,
    name: 'Docking Station',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 69075,
    name: 'Bitlocker',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 69076,
    name: 'Sophos Encryption',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 69077,
    name: 'FileVault',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 69082,
    name: 'Trackpad',
    description: '',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 55921,
    createdOn: '2019-05-20T14:28:39.347',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 69867,
    name: 'Adobe Acrobat',
    description: 'Adobe Acrobat',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 56261,
    createdOn: '2019-06-08T03:09:01.797',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 69868,
    name: 'Backblaze',
    description: 'Backblaze',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 56261,
    createdOn: '2019-06-08T03:09:01.797',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 69869,
    name: 'Battery / Power Supply',
    description: 'Battery / Power Supply',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 56261,
    createdOn: '2019-06-08T03:09:01.797',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 69870,
    name: 'Computer Port Issue (USB, Display, etc)',
    description: 'Computer Port Issue (USB, Display, etc)',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 56261,
    createdOn: '2019-06-08T03:09:01.797',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 69871,
    name: 'Office',
    description: 'Office',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 56261,
    createdOn: '2019-06-08T03:09:01.8',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 69872,
    name: 'Printer',
    description: 'Printer',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 56261,
    createdOn: '2019-06-08T03:09:01.8',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 69873,
    name: 'VPN Configuration',
    description: 'VPN Configuration',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 56261,
    createdOn: '2019-06-08T03:09:01.8',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 69874,
    name: 'Webroot',
    description: 'Webroot',
    issueTypeName: 'User: Workstation',
    issueTypeId: 16250,
    isActive: true,
    createdBy: 56261,
    createdOn: '2019-06-08T03:09:01.8',
    modifiedBy: null,
    modifiedOn: null,
  },
];
