import util from '@/util';
import adminRepo from '@/api/core/adminRepository';
import bmsRepo from '@/api/core/bmsRepository';
import rmaRepo from '@/api/core/rmaRepository.js';
import azureRepo from '@/api/core/azureRepository';
import o365Repo from '@/api/core/o365Repository';
import vsaRepo from '@/api/core/vsaRepository';
import metricRepo from '@/api/core/metricRepository';
import pixellotRepo from '@/api/core/pixellotRepository';
import webrootRepo from '@/api/core/webrootRepository';
import alertRepo from '@/api/core/alertRepository';
import usersRepo from '@/api/core/usersRepository';
import personalizeRepo from '@/api/core/personalizationRepository';
import fulfillmentRepo from '@/api/core/fulfillmentRepository.js';
import eixRepo from '@/api/core/eixRepository.js';
import dxRepo from '@/api/core/dxRepository.js';

const repositories = {
  azure: azureRepo,
  o365: o365Repo,
  bms: bmsRepo,
  rma: rmaRepo,
  vsa: vsaRepo,
  alert: alertRepo,
  /* bms: {
      ticket: bmsTicketRepository,
    }, */
  admin: adminRepo,
  metric: metricRepo,
  pixellot: pixellotRepo,
  webroot: webrootRepo,
  users: usersRepo,
  personalize: personalizeRepo,
  fulfillment: fulfillmentRepo,
  eix: eixRepo,
  dx: dxRepo,
  // other repositories ...
};

export class RepositoryFactory {
  static get(name) {
    return util.get(repositories, name, null);
  }
}
