import administration from './administration';
import core from './core';
import dash from './dashboard';
import devices from './devices';
import fulfillment from './fulfillment';
import logistics from './logistics';
import maint from './maintenance';
import people from './people';
import rma_requests from './rma_requests';
import serviceDesk from './servicedesk';

//concat to combine the exported router paths targets
const allRoutes = [].concat(
  administration,
  core,
  dash,
  devices,
  fulfillment,
  logistics,
  maint,
  people,
  rma_requests,
  serviceDesk,
);

export default allRoutes;
