// export function camel (str) {
//   const camel = (str || '').replace(/-([^-])/g, g => g[1].toUpperCase());

//   return capitalize(camel);
// }

// export function camelActual (str) {
//   return (str || '').replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ''));
// }

// export function kebab (str) {
//   return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
// }

// export function capitalize (str) {
//   str = str || '';

//   return `${str.substr(0, 1).toUpperCase()}${str.slice(1)}`;
// }

// export function findProduct (store, id) {
//   return store.state.store.products.find(p => p.id === id);
// }

// export function isOnSale (variants) {
//   return variants.some(variant => {
//     return parseFloat(variant.price) < parseFloat(variant.compareAtPrice);
//   });
// }

// export function randomNumber (min, max) {
//   return Math.floor(Math.random() * max) + min;
// }

// export function randomString (length = 5) {
//   let text = '';
//   const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

//   for (let i = 0; i < length; i++) {
//     text += possible.charAt(Math.floor(Math.random() * possible.length));
//   }

//   return text;
// }
import {
  startOfDay,
  endOfDay,
  endOfISOWeek,
  addDays,
  addMonths,
  addWeeks,
  startOfISOWeek,
  startOfMonth,
  endOfMonth,
  isFuture,
  addSeconds,
  differenceInSeconds,
} from 'date-fns';
import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';

String.prototype.replaceAll = function (str1, str2, ignore) {
  return this.replace(
    new RegExp(str1.replace(/([/,!\\^${}[\]().*+?|<>\-&])/g, '\\$&'), ignore ? 'gi' : 'g'),
    typeof str2 == 'string' ? str2.replace(/\$/g, '$$$$') : str2,
  ); // eslint-disable-line
};
/**
 * check if a value is of a particular type
 * @param {*} type 
 * @param {*} val 
 * samples
  is(String, ''); // true
  is(String, new String('')); // true
  is(Number, 1); // true
  is(Number, new Number(1)); // true
  is(Boolean, true); // true
  is(Array, [1]); // true
 */
// const is = (type, val) => ![null].includes(val) && val.constructor === type;
const randomElement = (arr = []) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const kebab = (str) => {
  return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

const toggleFullScreen = () => {
  const doc = window.document;
  const docEl = doc.documentElement;

  const requestFullScreen =
    docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
  const cancelFullScreen =
    doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

  if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
    requestFullScreen.call(docEl);
  } else {
    cancelFullScreen.call(doc);
  }
};

const decodeToken = (token) => {
  if (token) {
    return JSON.parse(atob(token.split('.')[1]));
  } else return null;
};

const isTokenExpired = (token) => {
  const decode = decodeToken(token);
  if (!decode || decode.exp < Date.now() / 1000) {
    return true;
  } else {
    return false;
  }
};

/**
 * If the path is a string, convert it to an array
 * @param  {String|Array} path The path
 * @return {Array}             The path array
 * @example get(object, 'a[0].b.c', 1);
 * Note: If provided path does not exists inside the object js will generate error.
 */
const get = (obj, path, defaultValue) => {
  const result = String.prototype.split
    .call(path, /[,[\].]+?/)
    .filter(Boolean)
    .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  return result === undefined || result === obj ? defaultValue : result;
};

const debounce = (fn, delay) => {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    /* eslint-disable-next-line prefer-rest-params */
    const args = arguments;
    const that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

const camelCase = (text) => {
  return text.replace(/^([A-Z])|[\s-_]+(\w)/g, function (match, p1, p2) {
    if (p2) return p2.toUpperCase();
    return p1.toLowerCase();
  });
};
const firstWord = (text) => {
  if (text) {
    return text.replace(/ .*/, '');
  } else {
    return '';
  }
};

const calcStartDate = (interval = 'day', dates = 60) => {
  let prior;
  let calc;
  switch (interval) {
    case 'day':
    case 'days':
      calc = addDays(new Date(), -dates);
      prior = startOfDay(calc).toISOString();
      break;
    case 'week':
    case 'weeks':
      calc = addWeeks(new Date(), -dates);
      prior = startOfISOWeek(calc).toISOString();
      break;
    case 'month':
    case 'months':
      calc = addMonths(new Date(), -dates);
      prior = startOfMonth(calc).toISOString();
      break;
    default:
      calc = addDays(new Date(), -dates);
      prior = startOfDay(calc).toISOString();
      break;
  }
  return prior;
};

const calcEndDate = (interval = 'day', dates = 60) => {
  let prior;
  let calc;
  switch (interval) {
    case 'day':
    case 'days':
      calc = addDays(new Date(), -dates);
      prior = endOfDay(calc).toISOString();
      break;
    case 'week':
    case 'weeks':
      calc = addWeeks(new Date(), -dates);
      prior = endOfISOWeek(calc).toISOString();
      break;
    case 'month':
    case 'months':
      calc = addMonths(new Date(), -dates);
      prior = endOfMonth(calc).toISOString();
      break;
    default:
      calc = addDays(new Date(), -dates);
      prior = endOfDay(calc).toISOString();
      break;
  }
  return prior;
};

/**
 * Add the specified number of seconds to the given date.
 * sample: util.expiresDate(60);  // returns current Date + 60 seconds
 */
const expiresDate = (seconds) => {
  return addSeconds(new Date(), seconds);
};
/**
 * Is the given date in the future?
 * You can pass a date object, or number (e.g. Date.getTime())
 */
const isFutureDate = (date) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  return isFuture(date);
};

const getDifferenceInSeconds = (authTimeout) => {
  const now = new Date();
  return differenceInSeconds(authTimeout, now);
};

/**
 * Similar to lodash startCase('helloThere') => 'Hello There', converts camelCase string to Words
 * @param {string} camelCase
 */
const startCase = (stringVal) => {
  return stringVal.replace(/([A-Z])/g, (match) => ` ${match}`).replace(/^./, (match) => match.toUpperCase());
};

/**
 * Sums a field from a collection, based on object path
 * @param {*} coll
 * @param {*} path
 */
const accumulator = (coll, path) => {
  return coll.reduce(function (acc, item) {
    const fld = get(item, path, '0') || '0'; // fld value can be '', so force to '0' to add correctly
    const val = parseInt(fld, 10);
    return acc + val;
  }, 0);
};

const accumulatorDecimal = (coll, path) => {
  return coll.reduce(function (acc, item) {
    const fld = get(item, path, '0') || '0'; // fld value can be '', so force to '0' to add correctly
    const val = Number(fld);
    return acc + val;
  }, 0);
};

/**
 * Creates an array of unique values that are included in all given arrays.
 * @param {*} arr
 * @param  {...any} args
 * @returns intersection([2, 1], [2, 3]) // => [2]
 */
const intersection = (arr, ...args) => {
  return arr.filter((item) => args.every((arr) => arr.includes(item)));
};

/**
 * Creates a duplicate-free version of an array, in which only the first occurrence of each element is kept.
 * @param {*} arr
 * @returns unique(['a', 1, 'a', 2, 1]) // => ['a', 1, 2]
 */
const unique = (arr) => {
  return [...new Set(arr)];
};
/* old method 
const pick = (...props) => o => props.reduce((a, e) => ({ ...a, [e]: o[e] }), {});
*/
/**
 * Creates an object composed of the object properties predicate returns truthy for.
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_pick
 * @param object
 * @param keys
 * @returns object with object properties based on keys passed
 */
const pick = (object, keys) => {
  return keys.reduce((obj, key) => {
    if (object && Object.getOwnPropertyDescriptor(object, key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
};

const objectDifference = (object, base) => {
  function changes(object, base) {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
};
const objectDeltas = (obj1, obj2, fieldsToCompare) => {
  const o1 = pick(obj1 || {}, fieldsToCompare);
  const o2 = pick(obj2 || {}, fieldsToCompare);
  const tDelta = objectDifference(o1, o2);
  return tDelta || {};
};

export default {
  randomElement,
  toggleFullScreen,
  kebab,
  decodeToken,
  isTokenExpired,
  get,
  debounce,
  pick,
  camelCase,
  startCase,
  firstWord,
  calcStartDate,
  calcEndDate,
  expiresDate,
  isFutureDate,
  getDifferenceInSeconds,
  accumulator,
  accumulatorDecimal,
  intersection,
  unique,
  objectDifference,
  objectDeltas,
};
