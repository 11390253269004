import apiCore from '@/api/api.common';
// import { Cache } from 'axios-extensions';

// const FIVE_MINUTES = 1000 * 60 * 5;
// const CAPACITY = 100;
// const metricCache = new Cache({ ttl: FIVE_MINUTES, max: CAPACITY });
const dftOptions = { useCache: true };

const repo = {
  apiCore: apiCore,
  getRoot,
  getSites,
  getGroups,
  getEndpoints,
  getThreatHistory,
  getThreatHistoryByMalwareGroup,
};

function applyOpts(options) {
  return Object.assign(options || dftOptions);
}
function getRoot(options) {
  return apiCore.get('/core/webroot', applyOpts(options));
}
function getSites(options) {
  return apiCore.get('/core/webroot/sites', applyOpts(options));
}
function getGroups(options) {
  return apiCore.get('/core/webroot/groups', applyOpts(options));
}
function getEndpoints(options) {
  return apiCore.get('/core/webroot/endpoints', applyOpts(options));
}
function getThreatHistory(options) {
  return apiCore.get('/core/webroot/threats', applyOpts(options));
}
function getThreatHistoryByMalwareGroup(options) {
  return apiCore.get('/core/webroot/threats/groupByMalware', applyOpts(options));
}
export default repo;
