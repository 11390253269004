import apiCore from '@/api/api.common';
// import { Cache } from 'axios-extensions';

// const SIXTY_MINUTES = 1000 * 60 * 60;
// const CAPACITY = 100;
// const localCache = new Cache({ ttl: SIXTY_MINUTES, max: CAPACITY });
const dftOptions = { useCache: true };

const repo = {
  apiCore: apiCore,
  getUser: getUser,
  getTenant: getTenant,
  getSharePointSites: getSharePointSites,
  securityScore: securityScore,
  getNewUsersByDateRange: getNewUsersByDateRange,
  getUserEmails: getUserEmails,
  getUserTeams: getUserTeams,
  getUserOnedrive: getUserOnedrive,
  getUserSharePoint: getUserSharePoint,

  graphs: {
    barNewUsersByDataRange: barNewUsersByDataRange,
    licenseHistoryChartByDateRange: licenseHistoryChartByDateRange,

    sparks: {
      users: activeUsers,
      mailUsage: mailUsage,
      getUserWithMFAEnabled: getUserWithMFAEnabled,
      getUserPasswordExpiring: getUserPasswordExpiring,
      getSubscribedSkuCount: getSubscribedSkuCount,
      getEnabledAndDisabledUsers: getEnabledAndDisabledUsers,
    },
  },
  reports: {
    getOneDriveUsageAccountDetail: getOneDriveUsageAccountDetail,
    getTotalStorageConsumption: getTotalStorageConsumption,
    getLicenseHistoryByDateRange: getLicenseHistoryByDateRange,
    getOneDriveActivityCard: getOneDriveActivityCard,
    getTeamsActivityCard: getTeamsActivityCard,
    getOutlookActivityCard: getOutlookActivityCard,
    getSharepointActivityCard: getSharepointActivityCard,
  },
};

function applyOpts(options) {
  return Object.assign(options || dftOptions);
}

/* **********************************
    top level
* ***********************************/
function getUser(options) {
  return apiCore.get('/core/o365/user', applyOpts(options));
}
function getUserEmails(options) {
  return apiCore.get('/core/o365/users/email', applyOpts(options));
}
function getUserTeams(options) {
  return apiCore.get('/core/o365/users/teams', applyOpts(options));
}
function getUserOnedrive(options) {
  return apiCore.get('/core/o365/users/onedrive', applyOpts(options));
}
function getUserSharePoint(options) {
  return apiCore.get('/core/o365/users/sharepoint', applyOpts(options));
}
function getTenant(options) {
  return apiCore.get('/core/o365/tenant', applyOpts(options));
}
/**
 * Not to be confused with o365/users/sharepoint!
 * @param {} options
 */
function getSharePointSites(options) {
  return apiCore.get('/core/o365/sharepoint', applyOpts(options));
}
function securityScore(options) {
  return apiCore.get('/azure/o365/security/securityscore', applyOpts(options));
}

/**
 * Get the number new users added by interval (period)
 * @param {*} options $interval: 'days'  | 'week' | 'month', $dates: <number of interval>
 */
async function getNewUsersByDateRange(options) {
  return apiCore.get('/core/o365/dash/getNewUsersByDateRange', applyOpts(options));
}

/************************************
 * graphs
 */
async function barNewUsersByDataRange(options) {
  const chartData = {
    series: [],
    categories: [],
  };
  const resp = await getNewUsersByDateRange(options);
  if (resp && resp.status === 200) {
    const coll = resp.data.Results.series;
    chartData.categories = coll.map((i) => {
      return i.createdDateTime;
    });
    const newUser = coll.map((i) => {
      return i.total;
    }); // convert to string
    chartData.series = [{ name: 'User', data: newUser }];
  }
  return chartData;
}

async function licenseHistoryChartByDateRange(options) {
  const series = [];
  await getLicenseHistoryByDateRange(options) //
    .then((resp) => {
      if (resp && resp.status === 200) {
        const coll = resp.data.Results;
        series.push({
          name: 'Total',
          data: coll.map((i) => [new Date(i.timestamp).getTime(), i.enabled]),
        });
        series.push({
          name: 'Used',
          data: coll.map((i) => [new Date(i.timestamp).getTime(), i.consumedUnits]),
        });
      }
    });
  return series;
}

/************************************
 * graphs / sparks
 */
function activeUsers() {
  return {
    Results: { activeTotal: 33, removedTotal: 0 },
  };
}

function mailUsage() {
  return {
    Results: { usage: 55, totalStorage: 80 },
  };
}

/**
 *
 * @param {*} options
 * @returns  {total,disabled,enabled,pctEnabled};
 */
async function getUserWithMFAEnabled(options) {
  return await apiCore.get('/core/o365/dash/getUserWithMFAEnabled', applyOpts(options)).then((resp) => {
    if (resp && resp.status === 200) {
      const coll = resp.data.Results;
      const total = coll
        .map((o) => o.total)
        .reduce((a, c) => {
          return a + c;
        });
      const disabled = coll
        .map((o) => o.disabled)
        .reduce((a, c) => {
          return a + c;
        });
      const enabled = coll
        .map((o) => o.enabled)
        .reduce((a, c) => {
          return a + c;
        });
      const neverExpire = coll
        .map((o) => o.passwordNeverExpires)
        .reduce((a, c) => {
          return a + c;
        });
      let pctEnabled = 0;
      if (total > 0) {
        pctEnabled = (enabled / total) * 100;
      }
      let pctNeverExpire = 0;
      if (total > 0) {
        pctNeverExpire = (neverExpire / total) * 100;
      }
      return { total, disabled, enabled, pctEnabled, pctNeverExpire };
    }
    return resp;
  });
}

/**
 * Get list of users that have (or near) expiring.
 * Use query param of 'offsetDaysFromExpire' to change default of (validity_period - offsetDaysFromExpire)
 * @param {*} options
 */
function getUserPasswordExpiring(options) {
  return apiCore.get('/core/o365/dash/getUserPasswordExpiring', applyOpts(options));
}

/**
 * 
 * @param {*} options 
 * @ returns {
 *      "consumedUnits": 587,
        "enabled": 581,
        "suspended": 0,
        "warning": 25
    }
 */
async function getSubscribedSkuCount(options) {
  return await apiCore.get('/core/o365/dash/getSubscribedSkuCount', applyOpts(options));
}

function getEnabledAndDisabledUsers(options) {
  return apiCore.get('/core/o365/dash/getEnabledAndDisabledUsers', applyOpts(options));
}

/*******************************
 * Reports
 */
async function getOneDriveUsageAccountDetail(options) {
  return apiCore.get('/core/o365/report/latestByName/getOneDriveUsageAccountDetail', applyOpts(options));
}

async function getTotalStorageConsumption(options) {
  return apiCore.get('/core/o365/dash/getTotalStorageConsumption', applyOpts(options));
}

/**
 *  Gets the o365 licensed history for consumedUnits, enabled, suspended, and warning by date range.
 *  User permissions (companyId) will be applied at the backend.
 *  @param {*} options $interval: 'month', $dates: <number of interval>
 * using interval other than month will return unexpected results (ie. 0 values)
 */
async function getLicenseHistoryByDateRange(options) {
  return apiCore.get('/core/o365/dash/getLicenseHistoryByDateRange', applyOpts(options));
}

async function getOneDriveActivityCard(options) {
  return apiCore.get('/core/o365/dash/getOneDriveActivityCard', applyOpts(options));
}

async function getTeamsActivityCard(options) {
  return apiCore.get('/core/o365/dash/getTeamsActivityCard', applyOpts(options));
}

async function getOutlookActivityCard(options) {
  return apiCore.get('/core/o365/dash/getOutlookActivityCard', applyOpts(options));
}

async function getSharepointActivityCard(options) {
  return apiCore.get('/core/o365/dash/getSharepointActivityCard', applyOpts(options));
}

export default repo;
