/**
 * Map configurations from ENV values to VUE configurations
 */
import { Version } from '@/common/version';

const config = {
  app: {
    title: import.meta.env.VITE_VUE_APP_TITLE || 'Via',
    version: Version.version || '0.0.0',
    sitePath: import.meta.env.VITE_SITE_PATH || 'localhost:8080',
  },
  api: {
    version: Version.version || '0.0.0',
    uri: import.meta.env.VITE_VUE_APP_API_URI,
  },
  auth: {
    AUTH0_DOMAIN: import.meta.env.VITE_VUE_APP_AUTH0_DOMAIN || 'app.montra.io',
    AUTH0_CLIENT_ID: import.meta.env.VITE_VUE_APP_AUTH0_CLIENT_ID || 'you_shall_not_pass',
    CALLBACK_URI: import.meta.env.VITE_VUE_APP_AUTH0_CALLBACK_URI || '/callback',
    AUTH0_API_AUDIENCE: import.meta.env.VITE_VUE_APP_AUTH0_API_AUDIENCE || 'http://localhost:3000',
  },
  sso: {
    CLIENT_ID: import.meta.env.VITE_VUE_APP_SSO_CLIENT_ID || '000000',
    AUTHORITY: import.meta.env.VITE_VUE_APP_SSO_AUTHORITY || 'https://login.microsoftonline.com/common',
    REDIRECT_URI: import.meta.env.VITE_VUE_APP_SSO_REDIRECT_URI || '/sso',
    SCOPES: ['profile', 'offline_access', 'User.Read', 'openid'],
    loginMethod: import.meta.env.VITE_VUE_APP_SSO_LOGIN_METHOD || 'loginPopup',
  },
  storage: {
    EIX_PROFILE_STORAGE_URI:
      import.meta.env.VITE_VUE_APP_VIA_EIX_STORAGE_URI || 'https://eixprofile.blob.core.windows.net/',
    FULFILLMENT_STORAGE_URI:
      import.meta.env.VITE_VUE_APP_VIA_FULFILLMENT_STORAGE_URI || 'https://viaproduct.blob.core.windows.net/',
  },
};

export default config;
