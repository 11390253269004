export default [
  {
    path: '/fulfillment',
    name: 'fulfillment',
    component: () => import(/* webpackChunkName: "fulfillment_requests" */ '@/pages/fulfillment/dashboard.vue'),
    meta: {
      public: false,
      onlyWhenLoggedOut: false,
      parent: 'devices',
      permissions: ['view_item', 'view_asset', 'view_inventory', 'full_logistics'],
      title: 'Order Fulfillment',
    },
    children: [
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "fulfillment_requests_admin" */ '@/pages/fulfillment/list.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'devices',
          permissions: ['view_item', 'view_asset', 'view_inventory', 'full_logistics'],
          title: 'Orders',
        },
        children: [
          {
            name: 'warehouse',
            path: 'warehouse',
            component: () =>
              import(/* webpackChunkName: "fulfillment-warehouse" */ '@/pages/fulfillment/warehouse.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['view_inventory', 'full_logistics'],
              title: 'Warehouse',
            },
          },
          {
            name: 'inventory',
            path: 'inventory',
            component: () =>
              import(/* webpackChunkName: "fulfillment-inventory" */ '@/pages/fulfillment/inventory.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['view_item', 'view_inventory', 'full_logistics'],
              title: 'Inventory',
            },
          },
          {
            name: 'orders',
            path: 'orders',
            component: () => import(/* webpackChunkName: "fulfillment-orders" */ '@/pages/fulfillment/orders.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['view_order', 'full_logistics'],
              title: 'Orders',
            },
          },
          {
            name: 'parts',
            path: 'parts',
            component: () => import(/* webpackChunkName: "fulfillment-outlook" */ '@/pages/fulfillment/parts.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['view_item', 'view_inventory', 'full_logistics'],
              title: 'Parts',
            },
          },
          {
            name: 'createOrder',
            path: 'createOrder',
            component: () => import(/* webpackChunkName: "createOrder" */ '@/pages/fulfillment/createOrder.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['create_order', 'full_logistics'],
              title: 'Create Order',
            },
          },
          {
            name: 'completeOrder',
            path: 'completeOrder',
            component: () => import(/* webpackChunkName: "completeOrder" */ '@/pages/fulfillment/completeOrder.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['create_order', 'full_logistics'],
              title: 'Complete Order',
            },
          },
        ],
      },
      {
        path: 'details',
        component: () => import(/* webpackChunkName: "fulfillment_requests_admin" */ '@/pages/fulfillment/admin.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'devices',
          permissions: ['view_order', 'full_logistics'],
          title: 'Fulfillment Admin',
        },
        children: [
          {
            name: 'orderTicketDetail',
            path: 'orderTicketDetail',
            component: () =>
              import(
                /* webpackChunkName: "fulfillment-orderTicketDetail" */ '@/pages/fulfillment/details/orderTicketDetail.vue'
              ),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['view_order', 'full_logistics'],
              title: 'Order Service Item Detail',
            },
          },
          {
            name: 'orderEntry',
            path: 'orderEntry',
            component: () =>
              import(/* webpackChunkName: "fulfillment-orderEntry" */ '@/pages/fulfillment/details/orderEntry.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['view_order', 'full_logistics'],
              title: 'Order Entry (v1)',
            },
          },
          {
            name: 'orderEntryV2',
            path: 'orderEntryV2',
            component: () =>
              import(/* webpackChunkName: "fulfillment-orderEntryV2" */ '@/pages/fulfillment/details/orderEntryV3.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['view_order', 'full_logistics'],
              title: 'Order Entry (v2)',
            },
          },
          {
            name: 'orderEntryV3',
            path: 'orderEntryV3',
            component: () =>
              import(/* webpackChunkName: "fulfillment-orderEntryV3" */ '@/pages/fulfillment/details/orderEntryV3.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['view_order', 'full_logistics'],
              title: 'Order Entry (v3)',
            },
          },
          {
            name: 'orderReturn',
            path: 'orderReturn',
            component: () =>
              import(/* webpackChunkName: "fulfillment-orderReturn" */ '@/pages/fulfillment/details/orderReturn.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['view_order', 'full_logistics'],
              title: 'Order Return',
            },
          },
        ],
      },
    ],
  },
];
