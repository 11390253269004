export default [
  {
    path: '/devices',
    name: 'devices',
    component: () => import('@/pages/assets/dashboard.vue'),
    meta: {
      public: false,
      onlyWhenLoggedOut: false,
      permissions: ['view_asset', 'full_logistics'],
      title: 'Devices',
    },
    children: [
      {
        path: 'admin',
        component: () => import(/* webpackChunkName: "assets_admin" */ '@/pages/assets/admin.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          permissions: ['full_logistics'],
          title: 'Devices',
        },
        children: [
          {
            // path deprecated redirect to Monitored Devices page
            name: 'assets-deprecated',
            path: 'assets-deprecated',
            redirect: () => {
              return 'administration/tenant/setup/assets_monitoring';
            },
          },
          {
            name: 'assetDetailNew',
            path: 'assetDetailNew',
            component: () => import(/* webpackChunkName: "assets_assetDetail" */ '@/pages/assets/assetDetail_new.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: '',
              permissions: ['view_asset', 'full_logistics'],
              title: 'Asset Detail',
            },
          },
          {
            name: 'hardwareDetail',
            path: 'hardwareDetail',
            component: () =>
              import(/* webpackChunkName: "assets_hardwareDetail" */ '@/pages/assets/hardwareDetail.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'devices',
              permissions: ['view_asset', 'full_logistics'],
              title: 'Hardware Detail',
            },
          },
        ],
      },
    ],
  },
];
