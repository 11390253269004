import axios from 'axios';
import { compareVersions } from 'compare-versions';
import * as packageMeta from '../../package.json';

const useVersionCheck = true;

// inspiration: https://medium.com/@codemonk/automatic-reload-of-client-after-deploying-in-vue-js-91c120f85f0e

export const refreshPageMixin = {
  data() {
    return {
      currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}', // This will get replaced on build with the actual file hash
      hashChanged: false, // Flag to indicate if the hash has changed
      newHash: '', // Placeholder for the new hash value fetched from the server
      currentVersion: import.meta.env.VITE_VUE_APP_VERSION || packageMeta.version || '1.0.0', // The current application version from environment variables or package metadata, fallback to '1.0.0'
      newVersion: '0.0.1', // Placeholder for the new version fetched from the server
    };
  },
  created() {
    // console.log('Component created, initializing version check...');
    // If running in a local development environment, overwrite the current hash for testing purposes
    if (window.location.hostname === 'localhost') {
      // console.log('Running in localhost, overwriting hash for development environment.');
      this.overwriteHashForDevEnv();
    }
    // Initialize the version checking process
    this.initVersionCheck();
  },
  methods: {
    // Only needed for capturing the hash when running locally
    async overwriteHashForDevEnv() {
      try {
        // console.log('Fetching version.json for development environment.');
        // Fetch version.json to get the current hash and version in development
        const fileResponse = await axios.get(`/version.json?t=${new Date().getTime()}`);
        if (fileResponse && fileResponse.data && fileResponse.data.hash) {
          // console.log('Version.json fetched successfully:', fileResponse.data);
          this.currentHash = fileResponse.data.hash; // Set the current hash from the response
          this.currentVersion = packageMeta.version; // Set the current version from package metadata
        }
      } catch (error) {
        // console.error('Error getting version.json:', error);
        // Use a consistent fallback hash for debugging purposes in case of an error
        this.currentHash = 'fallback_hash_for_debugging';
        this.currentVersion = packageMeta.version;
      }
    },
    // Initialize version check with a default frequency of 15 minutes
    async initVersionCheck(frequency = 1000 * 60 * 15) {
      try {
        // console.log('Performing initial version check...');
        // Perform an initial version check immediately
        await this.checkVersion();
      } catch (error) {
        // console.error('Error during initial version check:', error);
      }
      // Create a loop to periodically check for version updates
      const versionCheckLoop = async () => {
        try {
          // console.log('Performing periodic version check...');
          await this.checkVersion();
        } catch (error) {
          // console.error('Error during version check loop:', error);
        }
        // Schedule the next version check after the specified frequency
        setTimeout(versionCheckLoop, frequency);
      };
      versionCheckLoop();
    },
    // Check if the version or hash has changed
    async checkVersion() {
      try {
        // console.log('Checking version by fetching latest version.json...');
        // Fetch the latest version.json file to compare hashes or versions
        const fileResponse = await axios.get(`/version.json?t=${new Date().getTime()}`);

        // Ensure that the response contains valid data before updating properties
        if (fileResponse && fileResponse.data) {
          this.newHash = fileResponse.data.hash || this.currentHash;
          this.newVersion = fileResponse.data.version || this.currentVersion;
        } else {
          // console.warn('version.json response is missing expected data.');
          this.newHash = this.currentHash;
          this.newVersion = this.currentVersion;
        }

        // console.log('Fetched new version information:', {
        //   newHash: this.newHash,
        //   newVersion: this.newVersion,
        // });

        // Determine if the hash or version has changed based on the `useVersionCheck` flag
        if (useVersionCheck) {
          if (this.currentVersion && this.newVersion && this.currentVersion !== '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            this.hashChanged = this.hasVersionChanged(this.currentVersion, this.newVersion);
          } else {
            // console.warn('Skipping version comparison due to undefined or placeholder version values.');
            this.hashChanged = false;
          }
        } else {
          this.hashChanged = this.hasHashChanged(this.currentHash, this.newHash);
        }

        // console.log('Version/hash change detected:', this.hashChanged);

        // If a change is detected, reload the application
        if (this.hashChanged) {
          // console.log('Hash/version changed. Reloading application...');
          this.reloadApp();
        }
      } catch (error) {
        //console.error('Error checking version');
        // Handle errors such as network issues
        if (!error.response) {
          this.errorStatus = 'Error: Network Error';
        } else {
          this.errorStatus = error.response?.data.message || error.message;
        }
      }
    },

    // Compare two version strings to determine if an update is required
    hasVersionChanged(current, newVer) {
      // console.log('Comparing versions:', { current, newVer });
      if (!current || !newVer || current === '{{POST_BUILD_ENTERS_HASH_HERE}}' || newVer === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
        //console.error('Invalid version values for comparison:', { current, newVer });
        return false;
      }
      /****
             * Example usage of compareVersions:
             *  compareVersions('11.1.1', '10.0.0'); //  1
             *  compareVersions('10.0.0', '10.0.0'); //  0
             *  compareVersions('10.0.0', '11.1.1'); // -1
             */
      const result = compareVersions(current, newVer) === -1;
      //console.log('Version comparison result (is current older?):', result);
      return result; // Return true if the current version is older
    },

    // Compare the current and new hashes to determine if the application should be reloaded
    hasHashChanged(currentHash, newHash) {
      //console.log('Comparing hashes:', { currentHash, newHash });
      // Always return false for localhost to prevent unnecessary reloads during development
      if (window.location.hostname === 'localhost') {
        //console.log('Running in localhost, skipping hash change check.');
        return false;
      }

      // If the current hash is not set or is the placeholder, assume a change has occurred
      if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
        //console.log('Current hash is not set or is placeholder, assuming change occurred.');
        return true;
      }

      // Return true if the hashes do not match
      const result = currentHash !== newHash;
      //console.log('Hash comparison result (do hashes differ?):', result);
      return result;
    },

    // Reload the application to apply updates
    reloadApp() {
      //console.log('Updating current hash and version before reload:', {
      //  newHash: this.newHash,
      //  newVersion: this.newVersion,
      //});
      this.currentHash = this.newHash; // Update the current hash with the new value
      this.currentVersion = this.newVersion; // Update the current version with the new value
      //console.log('Reloading application due to version/hash change.');
      window.location.reload(); // Reload the page to load the new version
    },

    // Close the update modal if no action is required
    closeUpdateModal() {
      //console.log('Closing update modal, resetting hashChanged flag.');
      this.hashChanged = false; // Reset the hash change flag
    },
  },
};