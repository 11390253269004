import { AuthO365Service } from './autho365.service';
import { Auth0Service } from './auth0.service';
import store from '@/store/state';
import ApiService from '@/api/index';

// import Vue from 'vue';
class AuthenticationService {
  static signInType = {
    popup: 'loginPopup',
    redirect: 'loginRedirect',
  };

  //auth0 = new Auth0Service();
  auth0 = null;
  authO365 = null;
  constructor() {
    this.auth0 = new Auth0Service();
    this.authO365 = new AuthO365Service();
  }

  async refreshToken() {
    const idpUser = store.getters['account/ssoToken'];
    console.log('idpUser', idpUser)
    if (idpUser) {
      console.log('refreshToken O365');
      await this.authO365.handleRedirectPromise();
      console.log('refreshToken O365 End');
    } else {
      console.log('refreshToken Auth0');
      return this.auth0.refreshToken('authentication.service.js refreshToken Call');
    }
  }

  async loginUser(target) {
    if (target == 'sso') {
      console.log('Login O365');
      let login = await this.authO365.signIn();
      return login;
    } else {
      console.log('Login Auth0');
      let login = await this.auth0.login();
      return login;
    }
  }

  async logoutUser() {
    const idpUser = store.getters['account/auth'];
    // console.log(idpUser);
    if (idpUser?.account) {
      console.log('Logout O365');
      await this.authO365.signOut();
    } else {
      console.log('Logout Auth0');
      await this.auth0.logout();   
    }
  }

  async isAuthenticationValid(routeTarget) {
    // console.log('isAuthenticationValid', routeTarget);
    const routeTargetURL = typeof routeTarget === 'string' ? routeTarget : '/';
    let valid = false;
    try {
      const isAuthenticated = store.getters['account/isAuthenticated']();
      // console.log('isAuthenticated', isAuthenticated);
      if (isAuthenticated) {
        const test = await ApiService.apiCore.get(`/auth/validate?to=${routeTargetURL}`).catch((err) => {
          if (err.message.includes('401')) {
            valid = false;
          }

          return false;
        });
        if (test && test.status == 200) {
          valid = true;
        }
      }
    } catch (err) {
      valid = false;
    }

    return valid;
  }
}

export { AuthenticationService };
