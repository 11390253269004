import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import account from '@/store/modules/account.module';
import alerts from '@/store/modules/alerts.module';
import metric from '@/store/modules/metric.module';
import azure from '@/store/modules/azure.module';
import vsa from '@/store/modules/vsa.module';
import hris from '@/store/modules/hris.module';
import itsm from '@/store/modules/itsm.module';
import StoreHelper from '@/common/dataStore/storeHelper';

const store = new StoreHelper();
['montra', 'via-vuex', 'via-vuexx', 'debugAuth'].forEach(function (key) {
  localStorage.removeItem(key);
});

const persistOptions = {
  key: 'via-vuex1',
  paths: ['drawer', 'account', 'help', 'helpQuery'],
  storage: {
    getItem: (key) => store.get(key),
    setItem: (key, value) => store.set(key, value),
    removeItem: (key) => store.remove(key),
  },
};

const persistCartOptions = {
  key: 'via-cart',
  paths: ['cart', 'cartItems', 'cartCompany', 'cartVersion'],
  storage: {
    getItem: (key) => store.get(key),
    setItem: (key, value) => store.set(key, value),
    removeItem: (key) => store.remove(key),
  },
};

export default createStore({
  modules: {
    account,
    alerts,
    metric,
    azure,
    vsa,
    hris,
    itsm,
  },
  plugins: [createPersistedState(persistOptions), createPersistedState(persistCartOptions)],
  state: {
    drawer: true,
    title: null,
    cart: false,
    cartItems: {
      items: null,
      total: 0,
    },
    cartCompany: null,
    cartVersion: null,
    help: false,
    helpQuery: null,
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    drawer(state, val) {
      state.drawer = val;
    },
    title(state, val) {
      state.title = val;
    },
    toggleCart(state) {
      state.cart = !state.cart;
    },
    cartOn(state) {
      state.cart = true;
    },
    addCartItems(state, newItem) {
      let index = -1;
      if (Array.isArray(state.cartItems)) {
        // Vue.$log.warn('state.cartItems was array...corrected');
        state.cartItems = {
          items: [],
          total: 0,
        };
      }

      // Vue.$log.warn('cartItems', state.cartItems);
      if (!Array.isArray(state.cartItems.items)) {
        // are we object - wtf, how.
        // Vue.$log.warn('cart was not an array...corrected');
        state.cartItems.items = [];
      } else {
        // Vue.$log.warn('cart was an array...as expected');
        state.cartItems.items = [].concat(state.cartItems.items);
      }

      if (state.cartItems.items?.length > 0) {
        // console.log(state.cartItems.items, newItem);
        index = state.cartItems.items.findIndex((e) => e.partId === newItem.partId);
      }

      let sns = newItem?.serialNumber?.split(',');

      if (index === -1) {
        newItem.numInCart = sns?.length || 1;
        state.cartItems.items.push(newItem);
      } else {
        state.cartItems.items[index].numInCart = sns?.length || state.cartItems.items[index].numInCart++;
      }

      let total = 0;
      this.state.cartItems.items.forEach((item) => {
        total = total + parseInt(item.numInCart);
      });

      this.state.cartItems.total = total;
      state.cartCompany = state.cartItems.items?.length > 0 ? state.cartItems.items[0].companyId : null;
    },
    updateCartItems(state, item) {
      const index = state.cartItems.items.findIndex((e) => e.partId === item.partId);

      if (index !== -1) {
        state.cartItems.items[index].numInCart = item.numInCart;
      }

      let total = 0;
      this.state.cartItems.items.forEach((item) => {
        total = total + parseInt(item.numInCart);
      });

      this.state.cartItems.total = total;
      state.cartCompany = state.cartItems.items?.length > 0 ? state.cartItems.items[0].companyId : null;
    },
    removeCartItems(state, item) {
      const index = state.cartItems.items.findIndex((e) => e.partId === item.partId);
      if (index !== -1) {
        state.cartItems.items.splice(index, 1);
      }

      let total = 0;
      this.state.cartItems.items.forEach((item) => {
        total = total + parseInt(item.numInCart);
      });

      this.state.cartItems.total = total;
      state.cartCompany = state.cartItems.items.length > 0 ? state.cartItems.items[0].companyId : null;
    },
    emptyCart(state, version) {
      state.cartItems = {
        items: [],
        total: 0,
      };
      state.cartCompany = null;
      state.cartVersion = version;
    },
    toggleHelp(state) {
      state.help = !state.help;
    },
    helpOn(state) {
      state.help = true;
    },
    helpQuery(state, val) {
      state.helpQuery = val;
    },
  },
  getters: {
    drawer: (state) => {
      return state.drawer;
    },
    cartItems: (state) => {
      return state.cartItems;
    },
    cart: (state) => {
      return state.cart;
    },
    cartCompany: (state) => {
      return state.cartCompany;
    },
    cartVersion: (state) => {
      return state.cartVersion;
    },
    help: (state) => {
      return state.help;
    },
    helpQuery: (state) => {
      return state.helpQuery;
    },
  },
});
