<template>
  <div class="alert-wrapper">
    <div v-for="(alert, index) in alerts" :key="index" class="clickable" @click="removeAlert(index)">
      <v-alert
        :id="alert.id"
        density="compact"
        class="alert-toaster"
        border="start"
        close-text="Close Alert"
        :color="alert.alertType"
        closable
        elevation="2"
      >
        {{ alert.alertText }} <small v-if="alert.alertCount > 1">({{ alert.alertCount }})</small>
      </v-alert>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ToasterComponent',
  computed: {
    alerts() {
      const alerts = this.$store.getters['alerts/alerts'];
      return alerts;
    },
  },
  mounted() {
    this.$store.dispatch('alerts/removeAllAlerts');
  },
  methods: {
    removeAlert(index) {
      this.$store.dispatch('alerts/removeAlert', index);
    },
  },
};
</script>
<style scoped>
.alert-wrapper {
  position: fixed;
  top: 80px;
  right: 15px;
  z-index: 999999999999999999;
}

.v-theme--light .alert-toaster .v-icon {
  color: white !important;
}

.alert-toaster {
  max-width: 500px;
  z-index: 99999;
}
</style>
