import apiCore from '@/api/api.common';
// import { Cache } from 'axios-extensions';
import store from '@/store/state';
import phoneTypes from '@/api/core/lookups/phoneTypes';
import emailTypes from '@/api/core/lookups/emailTypes';
import { update } from 'lodash';

const dftOptions = { useCache: false };

const repo = {
  apiCore: apiCore,
  users: getUsers,
  usersWithTenantName: usersWithTenantName,
  userByAuthId: userByAuthId,
  userByEmail: userByEmail,
  companies: getCompanies,
  contacts: getContacts,
  getContactById: getContactById,
  updateContact: updateContact,
  accountLocations: getAccountLocations,
  addUser: addUser,
  updateUser: updateUser,
  deleteUser: deleteUser,
  resetPassword,
  getAlertMessageLogs: getAlertMessageLogs,
  getVAlertMessageLogs: getVAlertMessageLogs,
  sendAlertMessage: sendAlertMessage,
  getAlertMessageLogStatusesByAlertMessageLogId: getAlertMessageLogStatusesByAlertMessageLogId,
  force401: force401,
  force500: force500,
  lookups: {
    phoneTypes: phoneTypes,
    emailTypes: emailTypes,
  },
  auth: {
    logs,
    hasMultipleCompanies,
    updateUserMeta,
  },
  postLog: postLog,
  postSlackMessage: postSlackMessage,
  wikijs: {
    getWikiPageById: getWikiPageById,
    searchWikiJSPage: searchWikiJSPage,
    getWikiPageByPath: getWikiPageByPath,
  },
  locations: {
    get: getLocations,
    getById: getLocationsById,
    post: postLocation,
    patch: patchLocation,
    delete: deleteLocation,
  },
  warehouseLocations: {
    get: getWarehouseLocations,
    getById: getWarehouseLocationsById,
    getItems: getWarehouseLocationItems,
    getLogs: getWarehouseLocationLogs,
    post: postWarehouseLocation,
    patch: patchWarehouseLocation,
    delete: deleteWarehouseLocation,
  },
  permissions: {
    getPermissions: getPermissions,
    getPermissionById: getPermissionById,
    patchPermission: patchPermission,
    getUsers: getUserPermissions,
    getGroups: getGroups,
  },
  roles: {
    getRoles: getRoles,
    getRoleById: getRoleById,
    patchRole: patchRole,
    postRole: postRole,
    getUserRoles: getUserRoles,
    assignUsersToRole: assignUsersToRole,
    removeUsersFromRole: removeUsersFromRole,
    getUserRolesById: getUserRolesById,
    putUserRoles: putUserRoles,
  },
  tenants: {
    get: getTenants,
    getTenantsWithActiveCompanies: getTenantsWithActiveCompanies,
    postTenants: postTenants,
  },
  integrations: {
    integration: {
      getIntegrations: getIntegrations,
      getIntegrationById: getIntegrationById,
      createIntegration: createIntegration,
      updateIntegration: updateIntegration,
      deleteIntegration: deleteIntegration,
    },
    definition: {
      getIntegrationDefinitions: getIntegrationDefinitions,
      getIntegrationDefinitionById: getIntegrationDefinitionById,
      createIntegrationDefinition: createIntegrationDefinition,
      updateIntegrationDefinition: updateIntegrationDefinition,
      deleteIntegrationDefinition: deleteIntegrationDefinition,      
    },
    mapping: {
      getIntegrationMappings: getIntegrationMappings,
      getIntegrationMappingById: getIntegrationMappingById,
      createIntegrationMapping: createIntegrationMapping,
      updateIntegrationMapping: updateIntegrationMapping,
      deleteIntegrationMapping: deleteIntegrationMapping,
    },
    logs: {
      getIntegrationLogs: getIntegrationLogs,
      deleteIntegrationLog: deleteIntegrationLog,
    },
    workflowTypes: {
      get: getIntegrationWorkflowTypes,
    },
    integrationKey: {
      get: getIntegrationKeys,
      post: createIntegrationKey,
      patch: patchIntegrationKey,
      delete: deleteIntegrationKey,
    },
    meta: {
      get: getIntegrationMeta,
    }
  },
};
function hasMultipleCompanies() {
  return store.state.account.hasMultipleCompanies;
}
function applyOpts(options) {
  return Object.assign(options || dftOptions);
}
function getContacts(options) {
  return apiCore.get('/core/contacts', applyOpts(options));
}
function getContactById(id) {
  return apiCore.get(`/core/contacts/${id}`);
}

function force401(options) {
  return apiCore.get('/force401', applyOpts(options));
}
function force500(options) {
  return apiCore.get('/force500', applyOpts(options));
}
function updateContact(payload) {
  const id = payload.id;
  return apiCore.put(`/core/contacts/${id}`, payload);
}

function getAccountLocations(options) {
  return apiCore.get('/core/contacts/accountLocations', applyOpts(options));
}
function getTenants(options) {
  return apiCore.get('/core/tenants', applyOpts(options));
}
function getTenantsWithActiveCompanies(options) {
  return apiCore.get('/core/tenants/withActiveCompanies', applyOpts(options));
}
function postTenants(payload) {
  return apiCore.post('/core/tenants', payload);
}
function getCompanies(options) {
  return apiCore.get('/core/companies', applyOpts(options));
}
function getUsers(options) {
  return apiCore.get('/core/users', applyOpts(options));
}
function usersWithTenantName(options) {
  return apiCore.get('/core/users/usersWithTenantName', applyOpts(options));
}
function userByAuthId(options) {
  return apiCore.get(`/core/users/authid/${options.auth0Id}`);
}
function userByEmail(options) {
  return apiCore.get(`/core/users/email/${options.email}`);
}
function resetPassword(payload) {
  return apiCore.post(`/core/auth0/users/resetPassword`, payload); // {"email": "user@domain.com"}
}
function addUser(userData) {
  const payload = {
    tenant: userData.tenant.name,
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    password: userData.password,
  };
  return apiCore.post(`/core/auth0/users`, payload);
}
function updateUser(userData) {
  let payload = {};

  if (userData.password) {
    //console.log("Update Password Workflow");
  }

  //console.log("Update User Workflow");
  payload = {
    tenant: userData.tenant.name,
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    isEnabled: userData.isEnabled,
  };
  ///  the user update API is WIP
  return apiCore.put(`/core/auth0/users`, payload);
}
function deleteUser(payload) {
  return apiCore.delete(`/core/auth0/users`, payload);
}
function updateUserMeta(payload) {
  return apiCore.put('/auth/updateconsent', payload);
}
function logs(options) {
  return apiCore.get('/core/users/logs', applyOpts(options));
}

function postLog(options) {
  const headers = { 'via-key': 'Vpr5$a!3&y0KeRcQhy8!' };
  return apiCore.post('/logs', applyOpts(options), { headers });
}

function postSlackMessage(options) {
  const headers = { 'via-key': 'Vpr5$a!3&y0KeRcQhy8!' };
  return apiCore.post('/slack/api', applyOpts(options), { headers });
}

function getAlertMessageLogs(options) {
  return apiCore.get('/core/automation/alertMessageLogs', applyOpts(options));
}

function getVAlertMessageLogs(options) {
  return apiCore.get('/core/automation/vAlertMessageLogs', applyOpts(options));
}

function getAlertMessageLogStatusesByAlertMessageLogId(id = 0, options) {
  return apiCore.get(`/core/automation/alertMessageLogStatusesByAlertMessageLogId/${id}`, applyOpts(options));
}

function sendAlertMessage(payload) {
  return apiCore.post('/core/automation/alertMessage', payload);
}

// WikiJS Integration
function getWikiPageById(id) {
  return apiCore.get(`/core/wikijs/page/${id}`);
}
function searchWikiJSPage(query) {
  return apiCore.get(`/core/wikijs/search/${query}`);
}
function getWikiPageByPath(path) {
  return apiCore.post(`/core/wikijs/page/path`, path);
}
// Locations
function getLocations(options) {
  return apiCore.get('/core/locations', applyOpts(options));
}
function getLocationsById(id) {
  return apiCore.get(`/core/locations/${id}`);
}
function postLocation(payload) {
  return apiCore.post('/core/locations', payload);
}
function patchLocation(payload) {
  return apiCore.patch(`/core/locations/${payload.id}`, payload);
}
function deleteLocation(id) {
  return apiCore.delete(`/core/locations/${id}`);
}


// Warehouse Locations
function getWarehouseLocations(payload) {
  return apiCore.get(`/core/locations/warehouse/binLocs`, applyOpts(payload));
}
function getWarehouseLocationsById(id, options) {
  return apiCore.get(`/core/locations/warehouse/binLocs/${id}`, applyOpts(options));
}
function getWarehouseLocationItems(id, options) {
  return apiCore.get(`/core/locations/warehouse/${id}/items`, applyOpts(options));
}
function getWarehouseLocationLogs(id, options) {
  return apiCore.get(`/core/locations/binLoc/logs/${id}`, applyOpts(options));
}

function postWarehouseLocation(id, payload) {
  return apiCore.post(`/core/fulfillment/warehouse/${id}/location`, payload);
}
function patchWarehouseLocation(id, payload) {
  return apiCore.patch(`/core/fulfillment/warehouse/${id}/location/${payload.id}`, payload);
}
function deleteWarehouseLocation(id, payload) {
  return apiCore.delete(`/core/fulfillment/warehouse/${id}/location/${payload}`);
}

//Permissions
function getUserPermissions(options) {
  return apiCore.get(`/core/permission/user`, applyOpts(options));
}
function getPermissions(options) {
  return apiCore.get(`/core/permission/permissions`, applyOpts(options));
}
function getPermissionById(id) {
  return apiCore.get(`/core/permission/permissions/${id}`);
}
function patchPermission(payload) {
  return apiCore.patch(`/core/permission/permissions/${payload.id}`, payload);
}

function getGroups(options) {
  return apiCore.get(`/core/permission/groups`, applyOpts(options));
}

// User Role Assignments
function putUserRoles(id, payload) {
  return apiCore.put(`/core/permission/user/${id}`, payload);
}


// Roles
function getRoles(options) {
  return apiCore.get(`/core/permission/roles`, applyOpts(options));
}
function getRoleById(id) {
  return apiCore.get(`/core/permission/roles/${id}`);
}
function patchRole(payload) {
  return apiCore.patch(`/core/permission/roles/${payload.id}`, payload);
}
function postRole(payload) {
  return apiCore.post(`/core/permission/roles`, payload);
}
function getUserRoles(options) {
  return apiCore.get(`/core/permission/userRoles`, applyOpts(options));
}

function getUserRolesById(id, options) {
  return apiCore.get(`/core/permission/user/${id}`, applyOpts(options));
}

function assignUsersToRole(id, payload) {
  return apiCore.post(`/core/permission/userRoles/${id}`, payload);
}
function removeUsersFromRole(id, payload) {
  return apiCore.delete(`/core/permission/userRoles/${id}`, payload);
}


// Integrations

// Integrations
function getIntegrations(options) {
  return apiCore.get(`/integration/admin/integrations`, applyOpts(options));
}

function getIntegrationById(id) {
  return apiCore.get(`/integration/admin/integrations/${id}`);
}

function createIntegration(payload) {
  return apiCore.post(`/integration/admin/integrations`, payload);
}

function updateIntegration(payload) {
  return apiCore.put(`/integration/admin/integrations/${payload.id}`, payload);
}

function deleteIntegration(id) {
  return apiCore.delete(`/integration/admin/integrations/${id}`);
}

// Integration Definitions

function getIntegrationDefinitions(options) {
  return apiCore.get(`/integration/admin/definition`, applyOpts(options));
}

function getIntegrationDefinitionById(id, payload) {
  return apiCore.get(`/integration/admin/definition/${id}`, payload);
}

function createIntegrationDefinition(payload) {
  return apiCore.post(`/integration/admin/definition`, payload);
}

function updateIntegrationDefinition(payload) {
  return apiCore.put(`/integration/admin/definition/${payload.id}`, payload);
}

function deleteIntegrationDefinition(id) {
  return apiCore.delete(`/integration/admin/definition/${id}`);
}

// Integration Mappings

function getIntegrationMappings(options) {
  return apiCore.get(`/integration/admin/mapping`, applyOpts(options));
}

function getIntegrationMappingById(id) {
  return apiCore.get(`/integration/admin/mapping/${id}`);
}

function createIntegrationMapping(payload) {
  return apiCore.post(`/integration/admin/mapping`, payload);
}

function updateIntegrationMapping(payload) {
  return apiCore.put(`/integration/admin/mapping/${payload.id}`, payload);
}

function deleteIntegrationMapping(id) {
  return apiCore.delete(`/integration/admin/mapping/${id}`);
}

// Integration Logs

function getIntegrationLogs(options) {
  return apiCore.get(`/integration/admin/integrationLog`, applyOpts(options));
}

function deleteIntegrationLog(id) {
  return apiCore.delete(`/integration/admin/integrationLog/${id}`);
}

// Integration Workflow Types
function getIntegrationWorkflowTypes(options) {
  return apiCore.get(`/integration/admin/workflows`, applyOpts(options));
}

// Integration Keys
function getIntegrationKeys(options) {
  return apiCore.get(`/integration/admin/keys`, applyOpts(options));
}

function createIntegrationKey(payload) {
  return apiCore.post(`/integration/admin/keys`, payload);
}

function patchIntegrationKey(payload) {
  return apiCore.patch(`/integration/admin/keys/${payload.id}`, payload);
}

function deleteIntegrationKey(id) {
  return apiCore.delete(`/integration/admin/keys/${id}`);
}


// Integration Meta
function getIntegrationMeta(query) {
  return apiCore.get(`/integration/admin/meta?query=${query}`);
}






export default repo;
