export default [
  { id: 0, name: 'Condensation', value: 'Condensation', category: ['Camera'] },
  { id: 1, name: 'Motherboard', value: 'Motherboard', category: ['VPU'] },
  { id: 2, name: 'Graphics Card', value: 'Graphics Card', category: ['VPU'] },
  { id: 3, name: 'Software', value: 'Software', category: ['VPU'] },
  { id: 4, name: 'NIC', value: 'NIC', category: ['VPU'] },
  {
    id: 5,
    name: 'Ethernet Connection',
    value: 'Ethernet Connection',
    category: ['Camera'],
  },
  {
    id: 6,
    name: 'Camera Focus/Quality',
    value: 'Camera Focus/Quality',
    category: ['Camera'],
  },

  { id: 7, name: 'RAM', value: 'RAM', category: ['VPU'] },
  { id: 8, name: 'CPU', value: 'CPU', category: ['VPU'] },

  { id: 9, name: 'Disk', value: 'Disk', category: ['VPU'] },
  {
    id: 10,
    name: 'Box Damage',
    value: 'Box Damage',
    category: ['VPU', 'Camera'],
  },
  {
    id: 11,
    name: 'Water Damage',
    value: 'Water Damage',
    category: ['VPU', 'Camera'],
  },

  { id: 12, name: 'Power Supply', value: 'Power Supply', category: ['VPU'] },
  {
    id: 13,
    name: 'Pixellot Dongle',
    value: 'Pixellot Dongle',
    category: ['VPU'],
  },

  {
    id: 14,
    name: 'Physical Damage',
    value: 'Physical Damage',
    category: ['VPU', 'Camera'],
  },

  { id: 15, name: 'Sound', value: 'Sound', category: ['Camera'] },
  {
    id: 16,
    name: 'Environmental',
    value: 'Environmental',
    category: ['VPU', 'Camera'],
  },
  {
    id: 17,
    name: 'Not supplied',
    value: 'Not supplied',
    category: ['VPU', 'Camera'],
  },
  { id: 18, name: 'Other', value: 'Other', category: ['VPU', 'Camera'] },
];
