<template>
  <div>
    <v-toolbar-items class="float-left">
      <v-menu v-model="dialog" :close-on-content-click="false" absolute>
        <template #activator="{ props }">
          <v-btn v-bind="props" id="appUser" size="small" variant="text" rounded class="clickable" v-if="!isImpersonated">
            <avatar v-if="avatarImage" :size="40" :image="avatarImage" />
            <avatar v-else :fullname="user.name" :size="40" />
          </v-btn>
          <v-btn v-bind="props" id="appUser" size="small" variant="text" rounded class="clickable" v-else>
            <avatar :size="40" color="red" fullname="Imp" />
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item v-if="isImpersonated" key="theme" rel="noopener" @click="clearImpersonation()">
            <v-list-item-title>
              <v-icon class="mr-2 side-nav-icon">fal fa-user-secret</v-icon>Exit Impersonation
            </v-list-item-title>
          </v-list-item>
          <v-list-item key="theme" rel="noopener" @click="toggleTheme()">
            <v-list-item-title>
              <span v-if="theme === 'light'">
                <v-icon class="mr-2 side-nav-icon">fal fa-moon</v-icon>Dark mode
              </span>
              <span v-if="theme !== 'light'">
                <v-icon class="mr-2 side-nav-icon">fal fa-sun</v-icon>Light mode
              </span>
            </v-list-item-title>
          </v-list-item>
          <div v-for="(item, index) in items" :key="index">
            <v-list-group
              v-if="item.parent"
              :value="item.open"
              collapse-icon="fal fa-caret-down"
              expand-icon="fal fa-caret-right"
              style="flex: auto"
              @click="navClick(item)"
            >
              <template #activator="{ props }">
                <v-list-item
                  v-bind="props"
                  :prepend-icon="item.icon"
                  :title="item.title"
                  style="justify-content: left; justify-items: start"
                ></v-list-item>
              </template>
              <v-list-item
                v-for="child in item.items"
                :key="child.name"
                :class="{ sub: child.sub }"
                :prepend-icon="child.icon"
                :title="child.title"
                style="padding-inline: 40px !important"
                @click="navClick(child)"
              >
              </v-list-item>
            </v-list-group>
            <v-list-item v-else :key="index" rel="noopener" @click="navClick(item)">
              <v-list-item-title>
                <v-icon class="mr-2 side-nav-icon">
                  {{ item.icon }}
                </v-icon>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </div>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </div>
</template>
<script>
import { RepositoryFactory } from '@/api/repositoryFactory';
import config from '@/common/config';
import Avatar from 'vue-avatar-component';
import store from '@/store/state';
const repoAdmin = RepositoryFactory.get('admin');
const eixAdmin = RepositoryFactory.get('eix');
const IMAGE_STORE_URI = config.storage.EIX_PROFILE_STORAGE_URI;
import UserMenu from '@/api/appUserMenuFlat';
// import { useTheme } from 'vuetify';

export default {
  name: 'AppUser',
  components: { Avatar },
  props: {
    refresh: { type: Function, required: false, default: () => {} },
  },
  data: () => ({
    loading: true,
    dialog: false,
    account: {},
    eixUserInfo: {},
    eixLoading: true,
  }),
  computed: {
    photoVer() {
      return store.getters['account/photoVer'];
    },
    items() {
      return UserMenu.load(this.user);
    },
    user() {
      let user = store.getters['account/user'];
      if (!user) {
        user = {
          name: 'Unknown',
          nickname: 'anonymous',
          picture: 'http://1.gravatar.com/avatar/a9ea323cd7b93a395e27a0af24116252',
          roles: '',
          features: '',
        };
      }
      return user;
    },
    avatarImage() {
      let avatarImageUrl = false;
      if (this.isImpersonated) {
        const hasImg = this.eixUserInfo;
        avatarImageUrl = hasImg
          ? `${IMAGE_STORE_URI}${this.account?.tenant?.name.toLowerCase()}/${this.eixUserInfo.o365UserId}.jpg?cb=${
              this.photoVer
            }`
          : false;
      } else {
        const hasImg = this.eixUserInfo;
        if (this.account && this.account.tenant) {
          avatarImageUrl = hasImg
            ? `${IMAGE_STORE_URI}${this.account?.tenant?.name.toLowerCase()}/${this.eixUserInfo.o365UserId}.jpg?cb=${
                this.photoVer
              }`
            : false;
        }
      }

      return avatarImageUrl;
    },
    tenant() {
      const user = this.user;
      let tenant = '';
      if (!user) {
        tenant = '';
      } else {
        const sortedCompanies = user.tenant.companies.sort((a, b) => a.id - b.id); // sort by id ascending
        tenant = sortedCompanies[0].accountName;
      }
      return tenant;
    },
    toolbarColor() {
      return this.$vuetify.options.extra.mainNav;
    },
    isImpersonated() {
      return store.getters['account/isImpersonated'];
    },
    theme() {
      return localStorage.getItem('via-theme') || 'light';
    },
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getData(this.id);
    } else {
      if (!this.isImpersonated) {
        this.account = store.getters['account/loggedInUser'];
        // show current users profile by default
        this.getData(this.account.o365UserId);
      } else {
        this.account = store.getters['account/user'];
        // console.log(this.account)
        this.getData(this.account?.o365UserId);
      }
    }
  },
  methods: {
    clearImpersonation() {
      this.$store.dispatch('account/clearImpersonation', '');
    },
    toggleTheme() {
      let newValue = localStorage.getItem('via-theme') === 'light' ? 'dark' : 'light';
      // theme.global.name.value = theme?.global?.current?.value?.dark ? 'light' : 'dark';
      localStorage.setItem('via-theme', newValue);
      this.refresh();
    },
    navClick(item) {
      //console.log('handle_fn',item.target);
      if (item.fn_name && this[item.fn_name]) {
        this[item.fn_name]();
      }
      if (item.route_name) {
        const target = { name: item.route_name, query: { now: Date.now() } };
        this.$router.push(target);
        this.dialog = false;
      }
    },
    async logout() {
      this.$router.push({ name: 'logout' });
    },
    clear() {      
      let cnt = 0;
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.includes('meta-')) {
          localStorage.removeItem(key);
          cnt++;
        }
      }
      if (cnt > 0) {
        console.log('clearing local storage for meta');
        location.reload();
        this.$store.dispatch('alerts/addAlert', {
          alertText: 'Local Storage Cleared',
          alertType: 'success',
          delay: 15000,
        });
      } else {
        console.log('no meta keys found');
        this.$store.dispatch('alerts/addAlert', {
          alertText: 'No Meta to Clear',
          alertType: 'warning',
          delay: 15000,
        });
      }
      this.dialog = false;
    },
    expire() {
      // only for debug
      repoAdmin.force401();
      repoAdmin.force401();
      return;
      //return store.dispatch("account/refreshToken");
    },
    timeout() {
      // only for debug
      repoAdmin.force500();
      return;
    },
    getData(id) {
      const filter = `o365UserId eq '${id}'`;
      const selColumns = 'o365UserId,o365TenantId,meta';
      const req = {
        params: { $select: selColumns, $filter: filter, $top: 1, $skip: 0 },
      };
      eixAdmin
        .getUser(req)
        .then(({ data }) => {
          if (data?.Results?.length > 0) {
            this.eixUserInfo = data.Results.pop();
          }
        })
        .finally(() => {
          this.eixLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.sub {
  background: rgba(0, 0, 0, 0.4);
}
.sub .v-list-item__title {
  font-size: 0.8em;
}
.side-nav-icon {
  font-size: 16px;
}
.linkholder {
  width: 100%;
  padding: 10px 0px 5px 0px;
  text-align: center;
  font-size: 12px;
}
.impersonationWrapper {
  float: left;
  width: 80%;
  overflow: hidden;
}
.tenantIcon {
  height: 100%;
  float: left;
  margin-top: 2px;
}
.tenantName {
  width: 100%;
}
.tenantAction {
  width: 100%;
  color: #4b8ed8;
  font-size: 10px;
}
</style>
