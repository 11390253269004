// changed from RepositoryFactory.get patter to direct in order to support jest testing
import vsaRepo from '@/api/core/vsaRepository';

const state = {
  vsaAssetCountByType: {
    TotalRecords: 0,
    Results: [
      {
        assetTypeId: '1001',
        assetTypeName: 'Linux',
        rootName: 'Computer',
        total: 1,
      },
      {
        assetTypeId: '1002',
        assetTypeName: 'Mac',
        rootName: 'Computer',
        total: 1,
      },
    ],
    LastSync: '2019-01-01T19:20:06.794Z',
  },
};

// Typically, only call mutations from Actions
// ** Mutations are Synchronous **
const mutations = {
  SET_VSAASSETCOUNTBYTYPE(state, value) {
    state.vsaAssetCountByType = value;
  },
};

const actions = {
  loadVsaAssetCountByType({ commit }) {
    vsaRepo.assetCountByType().then((resp) => {
      if (resp && resp.status === 200) {
        commit('SET_VSAASSETCOUNTBYTYPE', resp.data);
      }
    });
  },
};

const getters = {
  vsaAssetCountByType: (state) => {
    return state.vsaAssetCountByType;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
