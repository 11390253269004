export default [
  { id: 0, name: 'Z240', value: 'Z240' },
  { id: 1, name: 'EliteDesk 800 G4', value: 'EliteDesk 800 G4' },
  { id: 2, name: 'S2V1', value: 'S2V1' },
  { id: 3, name: 'S1', value: 'S1' },
  { id: 4, name: 'Z1', value: 'Z1' },
  { id: 4, name: 'Z2', value: 'Z2' },
  { id: 5, name: 'S2V2', value: 'S2V2' },
  { id: 6, name: 'Z440', value: 'Z440' },
  { id: 7, name: 'Other', value: 'Other' },
  { id: 8, name: 'S2V3', value: 'S2V3' },
  { id: 9, name: 'S2V4', value: 'S2V4' },
];
