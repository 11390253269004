const state = {
  itsmStatus: false,
  itsmStartTime: null,
};
const mutations = {
  TOGGLE_STATUS(state) {
    state.itsmStatus = !!state.itsmStatus;
  },
  ENABLE_STATUS(state) {
    const now = new Date();
    state.itsmStatus = true;
    state.itsmStartTime = now.toISOString();
  },
  DISABLE_STATUS(state) {
    state.itsmStatus = false;
  },
};

// Actions: arbitrary asynchronous operations.
const actions = {
  toggleStatus({ commit }, alert) {
    commit('TOGGLE_STATUS', alert);
  },
  enableStatus({ commit }, alert) {
    commit('ENABLE_STATUS', alert);
  },
  disableStatus({ commit }, alert) {
    commit('DISABLE_STATUS', alert);
  },
};

const getters = {
  itsmStatus: (state) => {
    return state.itsmStatus;
  },
  itsmStartTime: (state) => {
    return state.itsmStartTime;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
