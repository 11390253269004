export default [
  { id: 1, name: 'Linux' },
  { id: 2, name: 'Windows' },
  { id: 3, name: 'Other' },
  { id: 4, name: 'Server' },
  { id: 5, name: 'NIC' },
  { id: 6, name: 'Unclassified' },
  { id: 7, name: 'Graphics Card' },
  { id: 8, name: 'MAC' },
  { id: 9, name: 'Router' },
  { id: 10, name: 'Camera' },
];
