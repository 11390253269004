export default [
  { id: 1, name: 'Email' },
  { id: 2, name: 'In-Person' },
  { id: 3, name: 'Monitoring System' },
  { id: 4, name: 'On Site' },
  { id: 5, name: 'Other' },
  { id: 6, name: 'Phone' },
  { id: 7, name: 'Recurring' },
  { id: 8, name: 'Verbal' },
  { id: 9, name: 'Voice Mail' },
];
