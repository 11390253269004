import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import config from '@/common/config';

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: config.sso.CLIENT_ID,
    authority: config.sso.AUTHORITY,
    redirectUri: window.location.origin + config.sso.REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: window.location.origin + '/logout' // Must be registered as a SPA redirectURI on your app registration
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
  system: {
      loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
              if (containsPii) {
                  return;
              }
              switch (level) {
                  case LogLevel.Error:
                      console.error(message);
                      return;
                  case LogLevel.Info:
                      // console.info(message);
                      return;
                  case LogLevel.Verbose:
                      // console.debug(message);
                      return;
                  case LogLevel.Warning:
                      console.warn(message);
                      return;
                  default:
                      return;
              }
          },
          logLevel: LogLevel.Verbose
      }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    prompt: 'select_account',
    scopes: config.sso.SCOPES,
};

// Add here the endpoints for MS Graph API services you would like to use.
// export const graphConfig = {
//   graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
// };