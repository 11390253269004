import apiCore from '@/api/api.common';

// import { Cache } from 'axios-extensions';

// const FIVE_MINUTES = 1000 * 60 * 5;
// const CAPACITY = 100;
// const localCache = new Cache({ ttl: FIVE_MINUTES, max: CAPACITY });
const dftOptions = { useCache: true };

const repo = {
  apiCore: apiCore,
  agents: agents,
  assets: assets,
  assetById: assetById,
  alarmsByAgentId: alarmsByAgentId,
  byOSType: byOSType,
  byProductName: byProductName,
  totalDiskSpace: totalDiskSpace,
  assetCountByType: assetCountByType,
  auditWithPercentFreeSpace: auditWithPercentFreeSpace,
  alarms: alarms,
  alarmSeriesByDate: alarmSeriesByDate,
  audits: audits,
  auditsByAgentId: auditsByAgentId,
  countAlarmByAgent: countAlarmByAgent,
  graphs: {
    alarmCountChart: alarmCountChart,
    assetCountByTypeChart: assetCountByTypeChart,
  },
};

function applyOpts(options) {
  return Object.assign(options || dftOptions);
}

function byOSType(options) {
  return apiCore.get('/core/vsa/asset/byOSType', applyOpts(options));
}
function byProductName(options) {
  return apiCore.get('/core/vsa/audit/byProductName', applyOpts(options));
}
function totalDiskSpace(options) {
  return apiCore.get('/core/vsa/audit/totaldiskspace', applyOpts(options));
}
function agents(options) {
  return apiCore.get('/core/vsa/agent', applyOpts(options));
}
function assets(options) {
  return apiCore.get('/core/vsa/asset', applyOpts(options));
}
function assetById(agentId, options) {
  return apiCore.get(`/core/vsa/agent/logs/alarm/${agentId}`, applyOpts(options));
}
function alarmsByAgentId(agentId, options) {
  return apiCore.get(`/core/vsa/agent/logs/alarm/${agentId}`, applyOpts(options));
}
async function assetCountByType(options) {
  return apiCore.get(`/core/vsa/asset/countByType`, applyOpts(options));
}
function auditWithPercentFreeSpace(options) {
  return apiCore.get('/core/vsa/audit/withPercentFreeSpace', applyOpts(options));
}
function alarms(options) {
  return apiCore.get('/core/vsa/agent/getAlarms', applyOpts(options));
}
function audits() {}
function auditsByAgentId(agentId) {
  const opts = {
    params: {
      $filter: `agentGuid eq '${agentId}'`,
      $select: 'freeSpace,usedSpace,totalSize,numberOfDrives',
      $orderBy: 'freeSpace asc',
    },
  };
  //Object.assign(opts, options || {});
  return apiCore.get('/core/vsa/audit', applyOpts(opts));
}
/**
 * Returns last 30 day history of alarms, by date and total events
 * @param {*} options 
 * Results.series: [{
        "timestamp": "2019-08-28T00:00:00.000Z",
        "total": 5
      },
      {
        "timestamp": "2019-08-29T00:00:00.000Z",
        "total": 5
      }]
 */
function alarmSeriesByDate(options) {
  return apiCore.get('/core/vsa/agent/getAlarmSeriesByDate', applyOpts(options));
}
function countAlarmByAgent(options) {
  return apiCore.get('/core/vsa/agent/countAlarmsByAgent', applyOpts(options));
}

/************************
 * Graph/Chart helpers
 *************************/

/**
 * call Montra { params: { $interval: this.interval, $dates: this.dates }}
 */
async function alarmCountChart(options) {
  const series = [
    {
      name: 'count',
      data: [],
    },
  ];

  const resp = await alarmSeriesByDate(options); // make data query
  if (resp && resp.status === 200) {
    //shape the data for the line chart with two series of data
    const coll = resp.data.Results.series;
    const alarm = series[0];
    for (let i = 0; i < coll.length; i++) {
      const row = coll[i];
      alarm.data.push({ x: new Date(row.timestamp).getTime(), y: row.total });
    }
  }
  return series;
}

async function assetCountByTypeChart(options) {
  const chartData = {
    series: [],
    categories: [],
  };
  //const resp = this.$store.getters["vsa/vsaAssetCountByType"];
  const resp = await assetCountByType(options); // make data query
  if (resp && resp.status === 200) {
    const coll = resp.data.Results;
    chartData.categories = coll.map((i) => {
      return i.assetTypeName;
    });
    //return [{data:totals}];
    const totals = coll.map((i) => {
      return i.total;
    }); // convert to string
    chartData.series = [{ data: totals }];
    chartData.totalRecords = resp.data.TotalRecords;
  }
  return chartData;
}

export default repo;
