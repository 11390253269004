export default [
  {
    path: '/administration',
    name: 'administration',
    component: () => import('@/pages/people/dashboard.vue'),
    props: true,
    meta: {
      public: false,
      onlyWhenLoggedOut: false,
      title: 'Administration',
      permissions: [],
    },
    children: [
      {
        path: 'global',
        name: 'global',
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'administration',
          permissions: [],
          title: 'Global Administration',
        },
        children: [
          {
            name: 'global_tenants',
            path: 'tenants',
            component: () => import(/* webpackChunkName: "eix_admin" */ '@/pages/administration/tenants.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'global',
              permissions: ['super_admin'],
              title: 'Tenants Admin',
            },
          },
          {
            name: 'global-setup-item-selected',
            path: 'setup/:configitem',
            component: () => import(/* webpackChunkName: "eix_admin" */ '@/pages/people/eix/setup.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'global',
              permissions: [ 'full_tenant', 'admin_tenant'],
              title: 'Global Configuration',
            },
          },
        ],
      },
      {
        path: 'tenant-shipping',
        name: 'tenant-shipping',
        component: () => import(/* webpackChunkName: "eix_admin" */ '@/pages/administration/shipping.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'administration',
          permissions: [ 'admin_tenant', 'full_tenant'],
          title: 'Shipping',
        },
      },
      {
        path: 'tenant',
        name: 'tenant',
        component: () => import(/* webpackChunkName: "administration_tenant" */ '@/pages/people/eix/setup.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'administration',
          permissions: [],
          title: 'Tenant Administration',
        },
        children: [
          {
            name: 'assets_monitoring',
            path: 'setup/assets_monitoring',
            component: () => import(/* webpackChunkName: "eix_admin" */ '@/pages/people/eix/setup.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'tenant',
              permissions: ['view_asset', 'full_logistics'],
              title: 'Monitored Assets',
            },
          },
          {
            name: 'assets_returns',
            path: 'setup/assets_returns',
            component: () => import(/* webpackChunkName: "eix_admin" */ '@/pages/people/eix/setup.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'tenant',
              permissions: [],
              title: 'Returns Inventory',
            },
          },
          {
            name: 'assets',
            path: 'setup/assets',
            component: () => import(/* webpackChunkName: "eix_admin" */ '@/pages/people/eix/setup.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'tenant',
              permissions: ['view_asset', 'full_logistics'],
              title: 'All Assets',
            },
          },
          {
            name: 'tenant-setup-item-selected',
            path: 'setup/:configitem',
            component: () => import(/* webpackChunkName: "eix_admin" */ '@/pages/people/eix/setup.vue'),
            props: true,
            meta: {
              public: false,
              onlyWhenLoggedOut: false,
              parent: 'tenant',
              permissions: [ 'admin_workforce', 'admin_logistics', 'full_logistics', 'admin_tenant', 'full_tenant', 'it_admin_workforce'],
              title: 'Tenant Configuration',
            },
          },
        ],
      },
    ],
  },
];
