import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, fa } from 'vuetify/iconsets/fa';

export default createVuetify({
  application: {
    footer: 0,
    insetFooter: 0,
  },
  treeShake: true,
  theme: {
    // options: {
    //   customProperties: true,
    // },
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          base: '#EEEEEE',
          primary: '#00679e',
          secondary: '#EE5320',
          error: '#F62536',
          warning: '#F68325',
          success: '#4CAF50',
          info: '#00679e',
          'lighten-5': '#eceff1',
          'lighten-4': '#cfd8dc',
          'lighten-3': '#b0bec5',
          'lighten-2': '#90a4ae',
          'lighten-1': '#78909c',
          'darken-1': '#546e7a',
          'darken-2': '#455a64',
          'darken-3': '#37474f',
          'darken-4': '#263238',
          default: '#424242',
          default_dark: '#222222',
          default_light: '#444444',
          primary_dark: '#00679e',
          primary_light: '#4b8ed8',
        },
      },
      dark: {
        dark: true,
        colors: {
          base: '#dedede',
          primary: '#00679e',
          secondary: '#EE5320',
          error: '#F62536',
          warning: '#F68325',
          success: '#4CAF50',
          info: '#66CDFF',
          'lighten-5': '#eceff1',
          'lighten-4': '#cfd8dc',
          'lighten-3': '#b0bec5',
          'lighten-2': '#90a4ae',
          'lighten-1': '#78909c',
          'darken-1': '#546e7a',
          'darken-2': '#455a64',
          'darken-3': '#37474f',
          'darken-4': '#263238',
          default: '#424242',
          default_dark: '#222222',
          default_light: '#444444',
          primary_dark: '#00679e',
          primary_light: '#4b8ed8',
        },
      },
    },
  },
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
  defaults: {
    VDataTable: {
      itemsPerPageOptions: [
        { value: 10, title: '10' },
        { value: 25, title: '25' },
        { value: 50, title: '50' },
        { value: 100, title: '100' }
      ],
    },
  }
});
