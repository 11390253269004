/**
 * number formatting helper functions
 * can be used in vue filters
 * see: http://numeraljs.com/
 */
import numeral from 'numeral';

/**
 * apply a Numeral.js formatting string to an input value
 * @param {Number} val input value to be changed
 * @param {String} [format='0,0'] Numeral.js format string
 * @return {String} formatted output string
 *  * Usage
 * {{ 10000.1234 | '0.00' }}  -> Return: 10000.123
 * {{ 100.1234 | '00000' }} -> Return: 00100
 * {{ 10 | '000.00' }} -> 010.00
 * {{ 1001 | '$ 0,0[.]00' }} -> $ 1,001
 * {{ 100  |  '0b'   }} ->  100B
 */
export default (val, format = '0.0') => {
  return numeral(val).format(format);
};
