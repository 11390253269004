export default [
  {
    id: 0,
    name: 'Out of Warranty',
    value: 'Out of Warranty',
    category: ['VPU', 'Camera'],
    state: ['Expired'],
    isActive: true,
  },
  {
    id: 1,
    name: 'Physical Damage',
    value: 'Physical Damage',
    category: ['VPU', 'Camera'],
    state: ['Expired', 'Unable to Repair'],
    isActive: true,
  },
  {
    id: 2,
    name: 'USB Missing',
    value: 'USB Missing',
    category: ['VPU', 'Camera'],
    state: ['Expired'],
    isActive: true,
  },
  {
    id: 2,
    name: 'Lost or Stolen',
    value: 'Lost or Stolen',
    category: ['VPU', 'Camera'],
    state: ['Expired'],
    isActive: true,
  },
];
