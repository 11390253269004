export default [
  {
    path: '/people',
    name: 'people',
    component: () => import('@/pages/people/dashboard.vue'),
    meta: {
      public: false,
      onlyWhenLoggedOut: false,
      title: 'People',
      permissions: ['view_people', 'full_workforce'],
    },
    children: [
      {
        name: 'eixsearch',
        path: 'directory',
        component: () => import(/* webpackChunkName: "eix_search" */ '@/pages/people/eix/search.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'people',
          permissions: ['view_people', 'onboard', 'offboard', 'workforce', 'full_workforce', 'it_admin_workforce', 'admin_workforce', 'edit_people'],
          title: 'People Directory',
        },
      },
      {
        name: 'eixUserProfile',
        path: 'profile',
        component: () => import(/* webpackChunkName: "eix_profile" */ '@/pages/people/eix/profile.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'people',
          permissions: ['view_people', 'full_workforce'],
          title: 'People Profile',
        },
      },
      {
        name: 'eixUserProfileAdmin',
        path: 'profile/admin',
        component: () => import(/* webpackChunkName: "eix_profile" */ '@/pages/people/eix/profile.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'people',
          permissions: ['view_people', 'full_workforce'],
          title: 'People Admin',
        },
      },
      {
        name: 'eixadmin',
        path: 'admin',
        component: () => import(/* webpackChunkName: "eix_admin" */ '@/pages/people/eix/admin.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'people',
          permissions: ['full_workforce', 'admin_workforce', 'it_admin_workforce'],
          title: 'People Admin',
        },
      },
      {
        name: 'admin-item-selected',
        path: 'admin/:configitem',
        component: () => import(/* webpackChunkName: "eix_admin" */ '@/pages/people/eix/admin.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'people',
          permissions: ['full_workforce', 'admin_workforce', 'it_admin_workforce'],
          title: 'People Admin Config',
        },
      },
      {
        name: 'admin-setup-item-selected',
        path: 'setup/:configitem',
        component: () => import(/* webpackChunkName: "eix_admin" */ '@/pages/people/eix/setup.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'people',
          permissions: ['full_workforce', 'admin_workforce', 'it_admin_workforce'],
          title: 'People Admin Setups',
        },
      },
      {
        path: 'onboarding',
        name: 'onboarding',
        component: () => import(/* webpackChunkName: "people_onboarding" */ '@/pages/people/onboarding.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'people',
          permissions: ['onboard', 'full_workforce'],
          title: 'Onboarding',
        },
        children: [],
      },
      {
        path: 'offboarding',
        name: 'offboarding',
        component: () => import(/* webpackChunkName: "people_offboarding" */ '@/pages/people/offboarding.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'people',
          permissions: ['offboard', 'full_workforce' ],
          title: 'Offboarding',
        },
        children: [],
      },
      {
        path: 'messaging',
        name: 'messaging',
        component: () => import(/* webpackChunkName: "people_messaging" */ '@/pages/people/messaging_new.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'people',
          permissions: ['workforce', 'full_workforce'],
          title: 'Workforce Alerts',
        },
        children: [],
      },
      {
        path: 'messaging_status',
        name: 'messaging_status',
        component: () => import(/* webpackChunkName: "people_messaging" */ '@/pages/people/messaging_status.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'people',
          permissions: ['workforce', 'full_workforce'],
          title: 'Workforce Alerts Status',
        },
        children: [],
      },
    ],
  },
];
