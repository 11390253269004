const state = {
  hrisStatus: false,
  hrisStartTime: null,
};
const mutations = {
  TOGGLE_STATUS(state) {
    state.hrisStatus = !!state.hrisStatus;
  },
  ENABLE_STATUS(state) {
    const now = new Date();
    state.hrisStatus = true;
    state.hrisStartTime = now.toISOString();
  },
  DISABLE_STATUS(state) {
    state.hrisStatus = false;
  },
};

// Actions: arbitrary asynchronous operations.
const actions = {
  toggleStatus({ commit }, alert) {
    commit('TOGGLE_STATUS', alert);
  },
  enableStatus({ commit }, alert) {
    commit('ENABLE_STATUS', alert);
  },
  disableStatus({ commit }, alert) {
    commit('DISABLE_STATUS', alert);
  },
};

const getters = {
  hrisStatus: (state) => {
    return state.hrisStatus;
  },
  hrisStartTime: (state) => {
    return state.hrisStartTime;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
