import apiCore from '@/api/api.common';
import { get } from 'lodash';
// import { Cache } from 'axios-extensions';

// const FIVE_MINUTES = 1000 * 60 * 5;
// const CAPACITY = 100;
// const personalizedCache = new Cache({ ttl: FIVE_MINUTES, max: CAPACITY });
const dftOptions = { useCache: false };

const repo = {
  apiCore: apiCore,
  tags: {
    get: function (options) {
      return apiCore.get('/core/tag', applyOpts(options));
    },
    getWithCounts: function (options) {
      return apiCore.get('/core/tag/counts', applyOpts(options));
    },
    post: function (dto) {
      return apiCore.post('/core/tag', dto);
    },
    put: function (id, dto) {
      return apiCore.put(`/core/tag/${id}`, dto);
    },
    delete: function (id) {
      return apiCore.delete(`/core/tag/${id}`, {});
    },
  },
  tagDetails: {
    get: function (id, options) {
      return apiCore.get(`/core/tag/detail/${id}`, applyOpts(options));
    },
    post: function (dto) {
      return apiCore.post(`/core/tag/detail/`, dto);
    },
    put: function (id, dto) {
      return apiCore.put(`/core/tag/detail/${id}`, dto);
    },
    delete: function (id) {
      return apiCore.delete(`/core/tag/detail/${id}`, {});
    },
  },
  dashboardLayoutByRouteName: dashboardLayoutByRouteName,
  addDashboardLayout: addDashboardLayout,
  patchDashboardLayout: patchDashboardLayout,
  deleteDashboardLayout: deleteDashboardLayout,
  setDashboardLayoutDefault: setDashboardLayoutDefault,
};

function applyOpts(options) {
  return Object.assign(options || dftOptions);
}

function dashboardLayoutByRouteName(routeName) {
  const options = { useCache: false, params: { routeName: routeName } };
  return apiCore.get('/core/users/dashboardByRouteName', applyOpts(options));
}

/** See if this make sense - passing both dto and default object as chain update */
function addDashboardLayout(dto) {
  return apiCore.post(`/core/users/dashboardLayout`, dto);
}
function patchDashboardLayout(dashboardLayoutId, dto) {
  return apiCore.patch(`/core/users/dashboardLayout/${dashboardLayoutId}`, dto);
}
function deleteDashboardLayout(dashboardLayoutId) {
  return apiCore.delete(`/core/users/dashboardLayout/${dashboardLayoutId}`, {});
}

function setDashboardLayoutDefault(dto) {
  /** dto is expected as
    {
        dashboardId: number;
        dashboardLayoutId: number;
        userId: number;
    }
     * */
  return apiCore.post('/core/users/setUserDashboardLayoutDefault', dto);
}

export default repo;
