// changed from RepositoryFactory.get patter to direct in order to support jest testing
import bmsRepo from '@/api/core/bmsRepository';
//import o365Repo from '@/api/core/o365Repository';
import metricRepo from '@/api/core/metricRepository';
import vsaRepo from '@/api/core/vsaRepository';
import eixRepo from '@/api/core/eixRepository';
import alertRepo from '@/api/core/alertRepository';
import ApiService from '@/api/index';
const state = {
  avgResponseTimes: {
    TotalRecords: 353,
    Results: {
      total: 1,
      avgFirstMinutes: 1.2,
      avgResolutionMinutes: 1.2,
      avgResolutionHours: 1.2,
      avgPauseMinutes: 1.2,
      series: [
        { avgResolutionMinutes: 1, avgPauseMinutes: 1 },
        { avgResolutionMinutes: 1, avgPauseMinutes: 1 },
        { avgResolutionMinutes: 1, avgPauseMinutes: 1 },
      ],
    },
    LastSync: '2019-01-01T19:20:06.794Z',
  },
  activeStatusCountByAccount: {
    TotalRecords: 0,
    Results: {
      total: 0,
      open: 0,
      closed: 0,
      new: 0,
      pending: 0,
      merged: 0,
      hasMetSla: 0,
      firstresponseOverdue: 0,
      overdue: 0,
      totalInteractions: 0,
      avgFirstResponseMinutes: 0,
      avgResolutionResponseMinutes: 0,
      avgPauseMinutes: 0,
      series: [],
    },
    LastSync: '2019-01-01T19:20:06.794Z',
  },
  deviceCounts: {
    TotalRecords: 30,
    Meta: {
      Name: 'HardwareAssets',
      Namespace: 'ServiceDesk',
      Description: 'Hardware Asset Counts',
    },
    Results: {
      avgTotal: 0,
      currentTotal: 0,
      series: [
        {
          timestamp: '2019-08-23T00:00:00.000Z',
          total: 1,
        },
        {
          timestamp: '2019-08-23T00:00:00.000Z',
          total: 1,
        },
      ],
    },
    LastSync: '2019-01-01T19:20:06.794Z',
  },
  openPriorityCounts: {
    TotalRecords: 0,
    Results: {
      total: 0,
      p1: 0,
      p2: 0,
      p3: 0,
      p4: 0,
      p5: 0,
    },
  },
  assetAlarmCounts: {
    TotalRecords: 30,
    Results: {
      total: 0,
      series: [
        {
          timestamp: '2019-08-23T00:00:00.000Z',
          total: 0,
        },
        {
          timestamp: '2019-08-23T00:00:00.000Z',
          total: 0,
        },
      ],
    },
    LastSync: '2019-01-01T19:20:06.794Z',
  },
  alertCounts: {
    TotalRecords: 12,
    Results: {
      device: 0,
      security: 0,
      series: [
        {
          timestamp: '2019-08-23T00:00:00.000Z',
          deviceCount: 0,
          securityCount: 0,
        },
      ],
      LastSync: '2019-01-01T19:20:06.794Z',
    },
  },
  eixPeopleInfo: {
    employeeCount: 0, // using getUser TotalRecords (TODO filter later?)
    emailCount: 0,
    fileCount: 0,
  },
  eixOnboarding: {
    pending: 0,
    new: 0,
  },
  eixOffboarding: {
    pending: 0,
    new: 0,
    scheduled: 0,
  },
};

// Typically, only call mutations from Actions
// ** Mutations are Synchronous **
const mutations = {
  SET_ACTIVESTATUSCOUNTBYACCOUNT(state, value) {
    if (value) {
      state.activeStatusCountByAccount = value;
    }
  },
  SET_AVGRESPONSETIMES(state, value) {
    if (value) {
      state.avgResponseTimes = value;
    }
  },
  SET_DEVICECOUNTS(state, value) {
    if (value) {
      state.deviceCounts = value;
    }
  },
  SET_OPENPRIORITYCOUNTS(state, value) {
    if (value) {
      state.openPriorityCounts = value;
    }
  },
  SET_ASSETALARMCOUNTS(state, value) {
    if (value) {
      state.assetAlarmCounts = value;
    }
  },
  SET_ALERTCOUNTS(state, value) {
    if (value) {
      state.alertCounts = value;
    }
  },
  SET_EMPLOYEEINFO(state, value) {
    if (value) {
      state.eixPeopleInfo = value;
    }
  },
  SET_ONBOARDINGINFO(state, value) {
    if (value) {
      state.eixOnboarding = value;
    }
  },
  SET_OFFBOARDINGINFO(state, value) {
    if (value) {
      state.eixOffboarding = value;
    }
  },
};

const actions = {
  /**
   *
   * @param {*} param0
   */
  loadActiveStatusCountByAccount({ commit }) {
    bmsRepo.getActiveCounts({ params: { $expand: 'series' } }).then((resp) => {
      if (resp && resp.status === 200) {
        commit('SET_ACTIVESTATUSCOUNTBYACCOUNT', resp.data); // returns
      }
    });
  },
  /**
   * Returns a summary of Service Item Average Response times from a given date
   * { "total": 65, "avgFirstMinutes": 81.21538461538462,"avgResolutionMinutes": 272.2923076923077,"avgPauseMinutes": 1245.24,"series": [...]}
   * @param {*} param
   */
  loadAvgResponseTimes({ commit }) {
    bmsRepo
      .avgResolutionMinutes({
        params: { $interval: 'days', $dates: 30, $expand: 'series' },
      })
      .then((resp) => {
        if (resp && resp.status === 200) {
          commit('SET_AVGRESPONSETIMES', resp.data); // returns
        }
      });
  },
  loadDeviceCounts({ commit }) {
    //metricRepo.getRMMDeviceCounts({ params: { $expand: 'series' } }) getHardwareAssetCounts
    metricRepo.getHardwareAssetCounts({ params: { $expand: 'series' } }).then((resp) => {
      if (resp && resp.status === 200) {
        commit('SET_DEVICECOUNTS', resp.data); // returns
      }
    });
  },
  loadOpenPriorityCounts({ commit }) {
    bmsRepo.openPriorityCounts({ params: {} }).then((resp) => {
      if (resp && resp.status === 200) {
        commit('SET_OPENPRIORITYCOUNTS', resp.data); // returns
      }
    });
  },
  async loadEmployeeInfo({ commit }) {
    const data = {
      employeeCount: 0,
      emailCount: 0,
      fileCount: 0,
    };
    data.employeeCount = await eixRepo
      .getUserCount({
        params: {
          $filter: `accountEnabled eq true and isLicensed eq true and isServiceAccount eq false`,
        },
      })
      .then((resp) => {
        if (resp && resp.status === 200 && resp.data && resp.data.TotalRecords) {
          return resp.data.TotalRecords || 0;
        }
        return 0;
      });

    // Does your average user need to know this?
    /* data.emailCount = await o365Repo.reports.getOutlookActivityCard()
      .then(resp => {
        if (resp && resp.status === 200 && resp.data) {
          return resp.data.totalMailboxes;
        }
        return 0;
      });

    data.fileCount = await o365Repo.reports.getOneDriveActivityCard()
      .then(resp => {
        if (resp && resp.status === 200) {
          return resp.data.fileCount;
        }
      }); */

    commit('SET_EMPLOYEEINFO', data); // returns
  },
  async loadOnboardingInfo({ commit, rootGetters }) {
    const data = {
      new: 0,
      pending: 0,
    };
    const isAuth = rootGetters['account/isAuthenticated'](); // special - call getter as function - avoids cache issues;
    if (isAuth) {
      await ApiService.apiCore.get('/core/eix/onboard/count/status').then((resp) => {
        if (resp && resp.status === 200) {
          const rows = resp.data.Results || [];
          rows.forEach((i) => {
            data[i.status.toLowerCase()] = i.count;
          });
        }
      });
      commit('SET_ONBOARDINGINFO', data); // returns
    }
  },
  async loadOffboardingInfo({ commit, rootGetters }) {
    const data = {
      new: 0,
      pending: 0,
    };
    const isAuth = rootGetters['account/isAuthenticated'](); // special - call getter as function - avoids cache issues;
    if (isAuth) {
      await ApiService.apiCore.get('/core/eix/offboard/count/status').then((resp) => {
        if (resp && resp.status === 200) {
          const rows = resp.data.Results || [];
          rows.forEach((i) => {
            data[i.status.toLowerCase()] = i.count;
          });
        }
      });
      commit('SET_OFFBOARDINGINFO', data); // returns
    }
  },
  async loadAssetAlarmCounts({ commit }) {
    const data = {
      TotalRecords: 0,
      Results: {
        total: 0,
        series: [
          {
            timestamp: '2019-08-23T00:00:00.000Z',
            total: 0,
          },
          {
            timestamp: '2019-08-23T00:00:00.000Z',
            total: 0,
          },
        ],
      },
      LastSync: '2019-01-01T19:20:06.794Z',
    };
    data.Results.total = await vsaRepo
      .assets({ params: { $filter: 'alarmCount gt 0' } }) // could also use vsaRepo.alarms({params: {$filter: 'monitorAlarmState_id eq 1'}})
      .then((resp) => {
        if (resp && resp.status === 200) {
          return resp.data.TotalRecords; // returns total count TotalRecords
        }
      });
    data.Results.series = await vsaRepo.alarmSeriesByDate({ params: { $interval: 'week', $days: 12 } }).then((resp) => {
      if (resp && resp.status === 200) {
        return resp.data.Results.series; // returns series
      }
    });

    commit('SET_ASSETALARMCOUNTS', data); // returns
  },
  async loadAlertCounts({ commit }) {
    const data = {
      TotalRecords: 0,
      Results: {
        activeDeviceCount: 0,
        activeSecurityCount: 0,
        series: [],
      },
      LastSync: '2019-01-01T19:20:06.794Z',
    };
    await alertRepo
      .activeCounts({ params: {} }) //
      .then((resp) => {
        if (resp && resp.status === 200) {
          Object.assign(data.Results, resp.data.Results || {});
        }
      });
    await alertRepo
      .alertSeriesByDate({ params: { $interval: 'week', $dates: 6 } }) //
      .then((resp) => {
        if (resp && resp.status === 200) {
          data.Results.series = resp.data.Results.series || [];
          data.TotalRecords = resp.data.TotalRecords;
        }
      });
    commit('SET_ALERTCOUNTS', data); // returns
  },
};

const getters = {
  activeStatusCountByAccount: (state) => {
    return state.activeStatusCountByAccount;
  },
  avgResponseTimes: (state) => {
    return state.avgResponseTimes;
  },
  deviceCounts: (state) => {
    return state.deviceCounts;
  },
  openPriorityCounts: (state) => {
    return state.openPriorityCounts;
  },
  assetAlarmCounts: (state) => {
    return state.assetAlarmCounts;
  },
  alertCounts: (state) => {
    return state.alertCounts;
  },
  eixPeopleInfo: (state) => {
    return state.eixPeopleInfo;
  },
  eixOnboardingInfo: (state) => {
    return state.eixOnboarding;
  },
  eixOffboardingInfo: (state) => {
    return state.eixOffboarding;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
