/**
 *
 * @param {*} number
 * @param {*} digits
 * Usage: {{ rawValue | percentage([digit]) }}
 * [digit] is the number of digits to keep after decimal.
 */
function percentage(number, digits) {
  let result;

  if (digits === null || digits === undefined) {
    digits = 2;
  }

  digits = parseInt(digits);

  if (number === null || number === '' || isNaN(number)) {
    result = '-';
  } else {
    result = Math.round(number * Math.pow(10, digits) * 100) / Math.pow(10, digits) + '%';
  }

  return result;
}

export { percentage };
