<template>
  <div class="d-flex justify-center align-center mx-auto" color="red" height="30">
    <span class="text-white mr-5">Impersonating: {{ tenant }}</span>
    <v-btn rounded size="x-small" color="primary" @click="clearImpersonation()"> Exit View </v-btn>
  </div>
</template>
<script>
export default {
  name: 'ImpersonaterComponent',
  computed: {
    tenant() {
      const user = this.$store.getters['account/user'];
      let tenant = '';
      if (!user) {
        tenant = '';
      } else {
        // Fixes bug where Company View Name doesn't match who they selected in dropdown
        //tenant = user.tenant.companies.length > 1 ? user.tenant.name :  user.tenant.companies[0].accountName
        tenant = user.name;
      }
      return tenant;
    },
  },
  methods: {
    clearImpersonation() {
      this.$store.dispatch('account/clearImpersonation', '');
    },
  },
};
</script>
