/**
 * Filter to prettify bytes
 * @param {*} bytes
 * @param {*} decimals
 * @param {*} kib
 * Usage
 * {{ 15364878 | bytes }}  -> Return: 15.36 MB
 * {{ 15364878 | bytes(1) }} -> Return: 15.4 MB
 * {{ 15364878 | bytes(1, true) }} -> 14.7 MiB
 * {{ 478144 | mbytes(1) }} -> 478.7 GB
 */
function byte(bytes, decimals, kib) {
  kib = kib || false;
  if (bytes === 0) return '0 Bytes';
  if (isNaN(parseFloat(bytes)) && !isFinite(bytes)) return 'Not an number';
  const k = kib ? 1024 : 1000;
  const dm = decimals || 2;
  const sizes = kib
    ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function mbyte(mbytes, decimals = 2, kib = false) {
  kib = kib || false;
  if (mbytes === 0) return '0 Bytes';
  if (isNaN(parseFloat(mbytes)) && !isFinite(bytes)) return 'Not an number';
  const bytes = mbytes * 1048576; // MB to Bytes
  const k = kib ? 1024 : 1000;

  const dm = decimals;
  const sizes = kib
    ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export { byte, mbyte };
