export default [
  {
    id: 33712,
    name: 'New',
    description: 'New',
    statusType: 'New',
    statusTypeId: 0,
    slaEvent: '',
    slaEventId: null,
    isActive: true,
    statusOrder: 0,
    createdBy: null,
    createdOn: null,
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 33713,
    name: 'Pending Customer',
    description: 'Waiting For Customer',
    statusType: 'Waiting For Customer',
    statusTypeId: 1,
    slaEvent: 'Waiting For Customer',
    slaEventId: 1,
    isActive: true,
    statusOrder: 1,
    createdBy: null,
    createdOn: null,
    modifiedBy: 55921,
    modifiedOn: '2019-05-01T15:23:58.45',
  },
  {
    id: 33714,
    name: 'Completed',
    description: 'Completed',
    statusType: 'Completed',
    statusTypeId: 2,
    slaEvent: 'Resolved',
    slaEventId: 2,
    isActive: true,
    statusOrder: 6,
    createdBy: null,
    createdOn: null,
    modifiedBy: 55921,
    modifiedOn: '2019-05-01T15:21:37.727',
  },
  {
    id: 33715,
    name: 'In Progress',
    description: 'In Progress',
    statusType: 'Custom',
    statusTypeId: 3,
    slaEvent: 'First Response',
    slaEventId: 0,
    isActive: true,
    statusOrder: 2,
    createdBy: null,
    createdOn: null,
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 33716,
    name: 'Escalated',
    description: 'Escalated',
    statusType: 'Custom',
    statusTypeId: 3,
    slaEvent: 'First Response',
    slaEventId: 0,
    isActive: true,
    statusOrder: 3,
    createdBy: null,
    createdOn: null,
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 33717,
    name: 'Assigned',
    description: 'Assigned',
    statusType: 'Custom',
    statusTypeId: 3,
    slaEvent: 'First Response',
    slaEventId: 0,
    isActive: true,
    statusOrder: 4,
    createdBy: null,
    createdOn: null,
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 34288,
    name: 'Pending Vendor',
    description: 'Pauses SLA while waiting for Vendor response',
    statusType: 'Custom',
    statusTypeId: 3,
    slaEvent: 'Waiting For Customer',
    slaEventId: 1,
    isActive: true,
    statusOrder: 5,
    createdBy: 55921,
    createdOn: '2019-05-01T15:20:46.163',
    modifiedBy: 55921,
    modifiedOn: '2019-05-01T15:21:51.337',
  },
  {
    id: 34289,
    name: 'Customer Responded',
    description: '',
    statusType: 'Custom',
    statusTypeId: 3,
    slaEvent: 'First Response',
    slaEventId: 0,
    isActive: true,
    statusOrder: 7,
    createdBy: 55921,
    createdOn: '2019-05-01T15:22:30.493',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 34290,
    name: 'Merged - Completed',
    description: 'Service item merged into another open service item',
    statusType: 'Custom',
    statusTypeId: 3,
    slaEvent: 'Resolved',
    slaEventId: 2,
    isActive: true,
    statusOrder: 8,
    createdBy: 55921,
    createdOn: '2019-05-01T15:23:12.98',
    modifiedBy: null,
    modifiedOn: null,
  },
  {
    id: 35030,
    name: 'Scheduled',
    description: 'Service is Scheduled for a Date',
    statusType: 'Custom',
    statusTypeId: 3,
    slaEvent: 'Waiting For Customer',
    slaEventId: 1,
    isActive: true,
    statusOrder: 9,
    createdBy: 55921,
    createdOn: '2019-05-20T11:48:06.393',
    modifiedBy: null,
    modifiedOn: null,
  },
];
