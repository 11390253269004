import apiCore from '@/api/api.common';
// import { Cache } from 'axios-extensions';
// import util from "@/util";

// const SIXTY_MINUTES = 1000 * 60 * 60;
// const CAPACITY = 100;
// const localCache = new Cache({ ttl: SIXTY_MINUTES, max: CAPACITY });
const dftOptions = { useCache: true };

const repo = {
  apiCore: apiCore,
  dashboardLayout: dashboardLayout,
  getUserLogs: getUserLogs,
  namedFilters: {
    context: {
      get: getContext,
      post: postContext,
      patch: patchContext,
      delete: deleteContext,
    },
    filter: {
      get: getFilter,
      namedFilterCounts: namedFilterCounts,
      post: postFilter,
      patch: patchFilter,
      delete: deleteFilter,
    },
  },
};

function applyOpts(options) {
  return Object.assign(options || dftOptions);
}

function dashboardLayout(options) {
  return apiCore.get('/core/users/dashboardLayout', applyOpts(options));
}

function getUserLogs(options) {
  return apiCore.get('/core/users/logs', applyOpts(options));
}

/* Filter Context */
function getContext(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/users/namedContext', applyOpts(optionsObj));
}
function postContext(payload) {
  return apiCore.post('/core/users/namedContext', payload);
}
function patchContext(id, payload) {
  return apiCore.patch(`/core/users/namedContext/${id}`, payload);
}
function deleteContext(id) {
  return apiCore.delete(`/core/users/namedContext/${id}`);
}

/* Filters */
function getFilter(options) {
  const optionsObj = { ...options };
  optionsObj['useCache'] = false;
  return apiCore.get('/core/users/namedFilter', applyOpts(optionsObj));
}

function namedFilterCounts(options) {
  return apiCore.get('/core/users/namedFilterCounts', applyOpts(options));
}

function postFilter(payload) {
  return apiCore.post('/core/users/namedFilter', payload);
}
function patchFilter(id, payload) {
  return apiCore.patch(`/core/users/namedFilter/${id}`, payload);
}
function deleteFilter(id) {
  return apiCore.delete(`/core/users/namedFilter/${id}`);
}

export default repo;
