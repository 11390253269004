import azureRepo from '@/api/core/azureRepository';
import o365Repo from '@/api/core/o365Repository';

const state = {
  advisorRecommendations: {
    TotalRecords: 1,
    Results: {
      cost: {
        Low: 1,
        High: 0,
        Medium: 0,
        resources: 1,
      },
      security: {
        Low: 1,
        High: 0,
        Medium: 0,
        resources: 1,
      },
      performance: {
        Low: 1,
        High: 0,
        Medium: 0,
        resources: 1,
      },
      highAvailability: {
        Low: 1,
        High: 0,
        Medium: 0,
        resources: 0,
      },
      series: [],
    },
    LastSync: '2019-01-01T19:20:06.794Z',
  },
  securityScore: {
    TotalRecords: 353,
    Results: [
      {
        createdDateTime: '2019-08-02T00:00:00Z',
        currentScore: 100,
        averageComparativeScores: [
          { createdDateTime: '2019-08-02T00:00:00Z', averageScore: 0 },
          { createdDateTime: '2019-08-02T00:00:00Z', averageScore: 0 },
          { createdDateTime: '2019-08-02T00:00:00Z', averageScore: 0 },
        ],
      },
      {
        createdDateTime: '2019-09-02T00:00:00Z',
        currentScore: 100,
        averageComparativeScores: [
          { createdDateTime: '2019-09-02T00:00:00Z', averageScore: 0 },
          { createdDateTime: '2019-09-02T00:00:00Z', averageScore: 0 },
          { createdDateTime: '2019-09-02T00:00:00Z', averageScore: 0 },
        ],
      },
    ],
    LastSync: '2019-01-01T19:20:06.794Z',
  },
};

// Typically, only call mutations from Actions
// ** Mutations are Synchronous **
const mutations = {
  SET_ADVISORRECOMMENDATIONS(state, value) {
    state.advisorRecommendations = value;
  },
  SET_SECURITYSCORE(state, value) {
    state.securityScore = value;
  },
};

const actions = {
  /**
   * Returns a summary of Security Tasks
   * { "High": 7, "Medium": 2, "Low": 4}
   */
  loadAdvisorRecommendations({ commit }) {
    azureRepo
      .advisorList({ params: {} })
      //.get("/management/advisor", { params: {} })
      .then((resp) => {
        if (resp && resp.status === 200) {
          //const series = Object.values(resp.data);
          //const seriesTotal = series.reduce((sum, item) => sum + item, 0);
          commit('SET_ADVISORRECOMMENDATIONS', resp.data); // returns
        }
      });
  },
  /*
   * Returns array of current 0365 scores by date
   * value:[{"createdDateTime": "2019-08-02T00:00:00Z","currentScore": 124,"averageComparativeScores": [{"basis": "AllTenants", averageScore: 33}]} ...]
   */
  loadSecurityScores({ commit }) {
    o365Repo
      .securityScore({ params: { $top: 15 } })
      //.get("/azure/o365/security/securityscore", { params: { $top: 15 } })
      .then((resp) => {
        if (resp && resp.status === 200) {
          commit('SET_SECURITYSCORE', resp.data);
        }
      });
  },
};

const getters = {
  advisorRecommendations: (state) => {
    return state.advisorRecommendations;
  },
  securityScores: (state) => {
    return state.securityScore;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
