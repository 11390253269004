<template>
  <v-navigation-drawer v-model="cart" location="right" disable-resize-watcher>
    <v-card rounded="0">
      <v-card-title>
        <span>Cart</span>
        <div class="flex-grow-1" />
        <v-btn variant="text" @click="toggleCart">
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        :key="isChanged"
        :style="{
          height: isImpersonated ? 'calc(100vh - 200px)' : 'calc(100vh - 170px)',
          overflow: 'auto',
        }"
      >
        <div v-for="(item, index) in cartItems" :key="item.id" class="mb-3 cartItem">
          <v-divider v-if="index > 0" class="pt-3 pb-1" />
          <div class="d-flex mb-2">
            <v-img
              lazy-src="/static/img/part.png"
              :src="rowImageValue(item)"
              height="50"
              width="50"
              class="mr-3 flex-grow-0"
            />
            <div>
              {{ item.partName }}<br />
              <small>{{ item.partNumber }}</small>
            </div>
          </div>
          <div class="d-flex justify-space-between align-center">
            <v-text-field
              v-model="item.numInCart"
              density="compact"
              variant="outlined"
              type="number"
              class="thin-select"
              min="0"
              max="99"
              step="1"
              hide-details
              :disabled="item.isSerialized && item?.serialNumber?.length > 0"
              v-if="item.type !== 'asset'"
              @change="changeCart(item)"
            />
            <v-spacer />
            <v-btn variant="text" size="x-small" color="warning" @click="removeFromCart(item)"> Delete </v-btn>
          </div>
          <div class="mt-3" v-if="item.isSerialized && !item?.serialNumber && hasSerials(item)">
            <v-btn
              density="compact"
              size="small"
              variant="text"
              prepend-icon="fal fa-tasks"
              hide-details
              @click="openModal(item)"
              >Specify Unit Selection</v-btn
            >
          </div>
          <div class="mt-3" v-if="item.isSerialized && item?.serialNumber">
            <div class="clickable" @click="openModal(item)">
              <v-icon class="mr-2">fal fa-edit</v-icon> {{ item.serialNumber.split(',').length }} serials selected
            </div>
          </div>
        </div>
        <div v-if="route.name !== 'createOrder'" class="cart-bottom clickable" @click="explore">
          <v-btn variant="text" size="x-small" :disabled="busy" class="continue-shopping-button">
            Continue Shopping
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions>
        <small>Total: {{ cartItemsTotal }}</small>
        <div class="flex-grow-1" />
        <v-btn variant="text" color="info" :disabled="isCheckout || cartItemsTotal === 0 || busy" @click="checkout">
          Checkout
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialog" max-width="600">
      <v-card v-if="!loading">
        <v-card-title
          ><span class="ellipsis">Select: {{ item.partName }}</span></v-card-title
        >
        <v-card-text>
          <div class="d-flex align-center">
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              prepend-inner-icon="fal fa-search"
              label="Search"
              single-line
              hide-details
              density="compact"
              variant="outlined"
              class="mb-4"
            ></v-text-field>
          </div>
          <v-alert v-if="search !== ''" variant="outlined" density="compact" type="warning" class="mb-4">
            List is being filtered.
          </v-alert>
          <v-data-table
            :headers="[{ title: 'Select Serial Number(s)', value: 'serialNumber' }]"
            :items-per-page-options="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' }
            ]"
            v-model="serialNumber"
            :search="search"
            :items="serialNumbers"
            item-value="serialNumber"
            density="compact"
            show-select
            :key="isChanged"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Close</v-btn>
          <v-btn text color="primary" @click="addToCart()">Add to Cart</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store/state';
import config from '@/common/config';
import { useRoute } from 'vue-router';
import { RepositoryFactory } from '@/api/repositoryFactory.js';

const repo = RepositoryFactory.get('fulfillment');
const IMAGE_STORE_URI = config.storage.FULFILLMENT_STORAGE_URI;

export default {
  name: 'AppCart',
  data: () => ({
    cartVal: false,
    isChanged: 0,
    route: {},
    dialog: false,
    serialNumber: [],
    search: '',
    busy: false,
  }),
  computed: {
    isImpersonated() {
      return this.$store.getters['account/isImpersonated'];
    },
    cartItemObj() {
      return store.state.cartItems || {};
    },
    cartItems() {
      let items = store.state.cartItems.items;
      if (items) {
        items.map((item) => {
          if (!item?.selectSN) {
            item.selectSN = false;
          }
          if (!item.sns) {
            item.sns = [];
          }
          if (!item.selectedSNs) {
            item.selectedSNs = [];
          }
        });
      }
      return items || [];
    },
    cartItemsTotal() {
      return store.state.cartItems.total || 0;
    },
    cart: {
      get() {
        return store.state.cart || false;
      },
      set(value) {
        this.cartVal = value;
      },
    },
    isCheckout() {
      return this.route.name === 'completeOrder';
    },
  },
  watch: {
    cartItemsTotal: {
      handler() {
        this.isChanged++;
      },
      deep: true,
    },
  },
  mounted() {
    this.route = useRoute();
  },
  methods: {
    hasSerials(item) {
      if (item.type === 'asset') {
        return false;
      }
      if (!item.serialNumbers) {
        return false;
      }
      if (
        item.serialNumbers[0] === undefined ||
        JSON.stringify(item.serialNumbers[0]) === '[]' ||
        JSON.stringify(item.serialNumbers[0]) === '[null]'
      ) {
        return false;
      }
      let sns = item?.serialNumbers[0]?.split(',');
      if (sns === undefined) {
        return false;
      }
      return sns.length > 0;
    },
    addToCart() {
      this.dialog = false;
      // add to cart
      store.commit('cartOn'); // open Cart Nav
      this.item.serialNumber = this.serialNumber.join(',');
      store.commit('addCartItems', this.item);
    },
    async openModal(item) {
      this.search = '';
      this.item = item;
      this.serialNumber = [];
      await this.getSNs(item).then(() => {
        if (item.serialNumber) {
          this.serialNumber = item.serialNumber.split(',');
        }
        this.dialog = true;
      });
    },
    async getSNs(item) {
      this.loading = true;
      await repo.order.availableSerials(item.partId).then((resp) => {
        this.serialNumbers = [];
        resp?.data?.Results?.available.forEach((sn) => {
          this.serialNumbers.push({ serialNumber: sn });
        });
        this.loading = false;
      });
    },
    toggleCart() {
      store.commit('toggleCart');
    },
    explore() {
      this.busy = true;
      this.$router.push({ name: 'createOrder' });
      this.busy = false;
    },
    checkout() {
      this.busy = true;
      this.$router.push({ name: 'completeOrder' });
      this.busy = false;
    },
    rowImageValue(item) {
      return `${IMAGE_STORE_URI}${item.accountCode.toLowerCase()}/${item.partId}.jpg` || '/static/img/part.png';
    },
    changeCart(item) {
      if (item.numInCart === '0') {
        store.commit('removeCartItems', item);
      } else {
        store.commit('updateCartItems', item);
      }
      this.isChanged++;
    },
    removeFromCart(item) {
      store.commit('removeCartItems', item);
      this.isChanged++;
    },
  },
};
</script>

<style scoped lang="scss">
.thin-select {
  font-size: 0.75rem;
  max-width: 65px;
}
.cart-bottom {
  position: absolute;
  bottom: 67px;
  width: 100%;
  margin: -15px;
  background-color: #00679e !important;
  color: white;
  .continue-shopping-button {
    margin: 0 auto;
    display: block;
    color: white;
  }
}
</style>
