export default [
  {
    path: '/logistics',
    name: 'logistics',
    props: true,
    meta: {
      public: false,
      onlyWhenLoggedOut: false,
      title: 'Logistics',
      permissions: [ 'view_item', 'view_inventory', 'full_logistics'],
    },
    children: [
      {
        path: 'receiving',
        name: 'receiving',
        component: () => import('@/pages/logistics/receiving.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'logistics',
          permissions: ['view_receiving', 'edit_receiving', 'create_receiving', 'delete_receiving', 'full_logistics'],
          title: 'Receiving',
        },
      },
      {
        path: 'warehouse',
        name: 'warehouse',
        component: () => import('@/pages/logistics/warehouses.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'logistics',
          permissions: [ 'view_inventory', 'full_logistics'],
          title: 'Warehouse',
        },
      },
      {
        path: 'inventory',
        name: 'inventory',
        component: () => import(/* webpackChunkName: "logistics_warehouse" */ '@/pages/logistics/inventory.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'logistics',
          permissions: [ 'view_item', 'view_inventory', 'full_logistics'],
          title: 'Inventory',
        },
      },
      {
        name: 'inventory_details',
        path: 'inventory/:partId',
        component: () => import('@/pages/logistics/inventory_detail.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'inventory',
          permissions: [ 'view_item', 'view_inventory', 'full_logistics'],
          title: 'Inventory Details',
        },
      },
      {
        path: 'binloc',
        name: 'binloc',
        component: () => import('@/pages/logistics/binlocs.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'logistics',
          permissions: [ 'view_inventory', 'full_logistics'],
          title: 'Bin Loc',
        },
      },
      {
        name: 'binloc_details',
        path: 'binloc/:binlocId',
        component: () => import('@/pages/logistics/binloc_detail.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'binloc',
          permissions: [ 'view_inventory', 'full_logistics'],
          title: 'Bin Loc Details',
        },
      },
      {
        path: 'logs',
        name: 'inventory_logs',
        component: () => import('@/pages/logistics/inventory_logs.vue'),
        props: true,
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
          parent: 'logistics',
          permissions: [ 'view_inventory', 'full_logistics'],
          title: 'Bin Loc',
        },
      },
    ],
  },
];
