export default [
  {
    id: 25398,
    name: 'P1',
    description: 'Highest Priority',
    createdBy: 2,
    createdOn: '2014-07-23T12:39:12.343',
    modifiedBy: 55921,
    modifiedOn: '2019-05-01T15:15:20.663',
  },
  {
    id: 25395,
    name: 'P2',
    description: 'High',
    createdBy: 1,
    createdOn: '2014-08-01T20:58:20.967',
    modifiedBy: 55921,
    modifiedOn: '2019-05-20T11:52:39.72',
  },
  {
    id: 25397,
    name: 'P3',
    description: 'Medium',
    createdBy: 1,
    createdOn: '2014-08-01T20:58:34.22',
    modifiedBy: 55921,
    modifiedOn: '2019-05-20T11:53:08.83',
  },
  {
    id: 25396,
    name: 'P4',
    description: 'Low',
    createdBy: 1,
    createdOn: '2014-08-01T20:58:08.143',
    modifiedBy: 55921,
    modifiedOn: '2019-05-20T11:53:27.627',
  },
  {
    id: 25399,
    name: 'P5',
    description: 'Very Low',
    createdBy: 1,
    createdOn: '2014-08-01T15:58:36.983',
    modifiedBy: 55921,
    modifiedOn: '2019-05-20T11:53:46.643',
  },
];
