export default [
  {
    path: '/dashboard/main',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/dashboard/main.vue'),
    meta: {
      public: false, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
      parent: 'dashboard',
      title: 'Dashboard',
    },
  },
  {
    path: '/dashboard/devices',
    name: 'dash_devices',
    component: () => import(/* webpackChunkName: "dash_devices" */ '@/pages/dashboard/devices.vue'),
    meta: {
      public: false, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
      parent: 'devices',
      permissions: ['view_asset', 'full_logistics'],
      title: 'Devices Dashboard',
    },
  },
  {
    path: '/dashboard/device_security',
    name: 'dash_device_security',
    component: () => import(/* webpackChunkName: "device_security" */ '@/pages/dashboard/device_security.vue'),
    meta: {
      public: false, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
      parent: 'security',
      permissions: [ 'device_security', 'view_asset', 'full_logistics'],
      title: 'Device Security Dashboard',
    },
  },
  {
    path: '/dashboard/rma',
    name: 'dash_rma',
    component: () => import(/* webpackChunkName: "device_security" */ '@/pages/dashboard/rma.vue'),
    meta: {
      public: false, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
      parent: 'devices',
      permissions: ['view_rma', 'full_logistics'],
      title: 'Fulfillment Dashboard',
    },
  },
  {
    path: '/dashboard/rma_status',
    name: 'dash_rma_status',
    component: () => import(/* webpackChunkName: "rma_status" */ '@/pages/dashboard/rma_status.vue'),
    meta: {
      public: false, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
      parent: 'devices',
      permissions: ['view_rma', 'full_logistics'],
      title: 'Returns Dashboard',
    },
  },
  {
    path: '/dashboard/rma_inventory',
    name: 'dash_rma_inventory',
    component: () => import(/* webpackChunkName: "rma_inventory" */ '@/pages/dashboard/rma_dash_inventory.vue'),
    meta: {
      public: false, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
      parent: 'devices',
      permissions: ['view_rma', 'full_logistics'],
      title: 'Returns Inventory Dashboard',
    },
  },
];
