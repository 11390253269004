export default [
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/pages/maintenance/dashboard.vue'),
    meta: {
      public: false, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
      permissions: [],
      title: 'Maintenance Dashboard',
    },
    children: [
      {
        path: 'admin',
        component: () => import('@/pages/maintenance/admin.vue'),
        meta: {
          public: false, // Allow access to even if not logged in
          onlyWhenLoggedOut: false,
          permissions: [ 'full_workforce', 'impersonate_tenant', 'admin_tenant', 'full_tenant'],
          title: 'Admin',
        },
        children: [
          {
            name: 'impersonate',
            path: 'impersonate',
            component: () =>
              import(/* webpackChunkName: "maint_impersonate" */ '@/pages/maintenance/admin/impersonate.vue'),
            meta: {
              public: false, // Allow access to even if not logged in
              onlyWhenLoggedOut: false,
              parent: 'security',
              permissions: [ 'impersonate_tenant'],
              title: 'Impersonation',
            },
          },
          {
            name: 'users',
            path: 'users',
            component: () => import(/* webpackChunkName: "maint_users" */ '@/pages/maintenance/admin/users_new.vue'),
            meta: {
              public: false, // Allow access to even if not logged in
              onlyWhenLoggedOut: false,
              parent: 'security',
              permissions: ['full_workforce', 'admin_tenant', 'full_tenant'],
              title: 'Users',
            },
          },
          {
            name: 'profile',
            path: 'profile',
            component: () => import(/* webpackChunkName: "maint_profile" */ '@/pages/maintenance/admin/profile.vue'),
            meta: {
              public: false, // Allow access to even if not logged in
              onlyWhenLoggedOut: false,
              parent: 'security',
              permissions: ['view_people'],
              title: 'Profile',
            },
          },
          {
            name: 'employees',
            path: 'employees',
            component: () =>
              import(/* webpackChunkName: "maint_profile" */ '@/pages/maintenance/admin/employees_new.vue'),
            meta: {
              public: false, // Allow access to even if not logged in
              onlyWhenLoggedOut: false,
              parent: 'security',
              permissions: ['full_workforce', 'admin_tenant', 'full_tenant'],
              title: 'Employees',
            },
          },
        ],
      },
    ],
  },
];
