import config from '@/common/config';
import store from '@/store/state';
import ApiService from '@/api/index';

import { Auth0Client } from '@auth0/auth0-spa-js';

class Auth0Service {
  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.handleRedirectCallback = this.handleRedirectCallback.bind(this);
    this.getUser = this.getUser.bind(this);
    this.auth0 = new Auth0Client({
      domain: config.auth.AUTH0_DOMAIN, // e.g., auth.montra.io
      clientId: config.auth.AUTH0_CLIENT_ID, // e.g., i473732832832cfgajHYEUqiqwq
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: `${window.location.origin}/callback`, // e.g., http://localhost:8080/callback
        audience: config.auth.AUTH0_API_AUDIENCE, // e.g., https://meetupapi.com
        response_type: 'token id_token', // at a min, need a token responseType
        scope: 'openid profile email ',
      },
      leeway: 30, // A value in seconds; leeway to allow for clock skew with regard to ID Token expiration times.
    });
  }

  /** Callback handler from auth0.authorize() method call
   * @returns {Promise}
   */
  async handleAuthentication() {
    const isAuthenticated = await this.auth0.isAuthenticated();
    console.log('isAuthenticated', isAuthenticated);

    // if(isAuthenticated) {
    //   const user = await this.auth0.getUser();
    //   console.log(user);
    //   let reqPath = store.getters['account/requestedPath'];
    //   console.log('ReqPath', reqPath);
    //   if (!reqPath || (reqPath && reqPath.length == 1)) {
    //     reqPath = store.getters['account/defaultHref'];
    //   }
    //   store.dispatch('account/resetRequestedPath', null); // reset path
    //   return reqPath;
    // }

    let user = null;

    try {
      await this.auth0.getTokenSilently({
        detailedResponse: true,
      }).then(async (authResp) => {
        console.log('Auth0 Resp', authResp);
        
        let user = await this.processToken(authResp);

        if (user) {
          let reqPath = store.getters['account/requestedPath'];
          console.log('ReqPath', reqPath);
          if (!reqPath || (reqPath && reqPath.length == 1)) {
            reqPath = store.getters['account/defaultHref'];
          }
          store.dispatch('account/resetRequestedPath', null); // reset path
          return reqPath;
        }
      });
    } catch (error) {
      console.log('handleAuthentication error', error);
      throw error;
    }
  }
  async refreshToken(source) {
    // called after getting a 401 from the api
    console.log('Refresh Token call received from ', source || 'account.module.js refreshToken');
  }
  async processToken(auth) {
    console.log('processToken');
    // returns Montra user
    let user = null; // this is what we'll return
    // We add the access token as an authorization header for our Axios requests to our API      
    store.commit('account/SET_USER_AUTH', auth, true);
    try {      
      user = await this.loadUser();
      return user;
    } catch (err) {
      console.log('Process Token Error', err);
    }
  }

  async loadUser() {
    console.log('Load User')
    try {
      // This call doesn't work in QA (and maybe Prod for some reason)
      const user = await ApiService.apiCore.get('auth/user', { params: { source: 'auth0' } }).then((authResp) => {
        console.log('Load User Resp', authResp);
        if (authResp && authResp.status === 200) {
          console.log('AuthResp status 200',)
          return authResp.data; // echo what api received (JWT) or decrypted idToken
        } else {
          console.log('AuthResp status not 200', authResp)
          throw new Error(`ValidateLogin ${authResp}`);
        }
      });

      await ApiService.apiCore.get(`/core/permission/user/${user.id}?$expand=[userRoles,permissions]`).then((resp) => {
        if (resp && resp.status === 200) {
          if (resp.data) {
            const { permissions, userRoles } = resp.data.Results;
            user.permissionsString = permissions?.map((p) => p.permissionCode).toString();
            if (user.permissions != permissions) {
              user.permissions = permissions;
            }
            if (user.userRoles != userRoles) {
              user.userRoles = userRoles;
            }
          }
        }
      });

      user.picture = user.picture || 'https://placeimg.com/250/250/people';
      // if (state.user.length > 1) {
      //     return state.user[0];
      // } else {
      //     store.commit("account/SET_USER", user);
      //     return user;
      // }
      store.commit('account/SET_USER', user);
      return user;
    } catch (err) {
      console.log('Load User Error', err);
    }
    
  }
  /**
   * Typically called from Vuex store "account/loginUser" via router beforeEach validation
   */
  async login() {
    let resp = await this.auth0.loginWithRedirect();
    console.log('Login Response:', resp);
    if(!resp) {
      console.log('Login failed');
      return false;
    } else {
      return true;
    }
  }

  /**
   * Typically called from Vuex store "account/logoutUser" from user profile menue
   */
  async logout() {
    // auth0 api change - defined return to uri
    console.log('returnTo: ' + window.location.origin)
    await this.auth0.logout({
      logoutParams: {
        returnTo: `${window.location.origin}`
      }
    });
  }

  async handleRedirectCallback() {
    return await this.auth0.handleRedirectCallback();
  }

  async getUser() {
    return await this.auth0.getUser();
  }
}

export { Auth0Service };
