import config from '@/common/config';
import axios from 'axios';

const core = {
  baseUrl: config.api.uri,

  query(resource, params) {
    return this.axios.get(resource, params).catch((error) => {
      // console.log(`ApiService ${error}`);
      throw new Error(`ApiService ${error}`);
    });
  },
  CancelToken: null,
  axios: null,
  get(resource, config) {
    const options = Object.assign({ crossdomain: true }, config || {});
    return this.axios.get(`${resource}`, options).catch((error) => {
      if (axios.isCancel(error)) {
        // console.log(`GET Request ${error}`); // Programmatic cancel request called with axios e.g. token.cancel('message');
      } else {
        // console.log(`ApiService ${error}`);
        // throw new Error(`ApiService ${error}`);
      }
    });
  },

  post(resource, params, headers) {
    return this.axios.post(`${resource}`, params, headers);
  },

  update(resource, slug, params) {
    return this.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return this.axios.put(resource, params);
  },

  patch(resource, params) {
    return this.axios.patch(resource, params);
  },

  /**
   * axiox.delete does support a request body. 
   * It accepts two parameters: url and optional config. 
   * You can use config.data to set the response body as follows:
      axios.delete(url, { data: { foo: "bar" } });
   * @param {*} resource 
   * @param {*} body 
   */
  delete(resource, body) {
    const config = { data: body };
    return this.axios.delete(resource, config).catch((error) => {
      // console.log(`ApiService ${error}`);
      throw new Error(`ApiService ${error}`);
    });
  },
};

export default core;
