const state = {
  alerts: [],
};

const getExistingAlertIndex = (alertList) => (newAlert) =>
  alertList.findIndex((el) => {
    return el.alertText === newAlert.alertText && el.alertType === newAlert.alertType;
  });

/**
 * Alert Types:
 * success
 * info
 * error
 * warning
 */
// Typically, only call mutations from Actions
// ** Mutations are Synchronous **
const mutations = {
  ADD_ALERT(state, alert) {
    state.alerts.push(alert);
  },
  UPDATE_ALERT(state, { alert, existingAlertIndex }) {
    state.alerts[existingAlertIndex].timeoutId = alert.timeoutId;
    state.alerts[existingAlertIndex].alertCount = alert.alertCount;
  },
  REMOVE_ALERT(state, index) {
    state.alerts.splice(index, 1);
  },
  REMOVE_ALL_ALERTS(state) {
    state.alerts = [];
  },
};

// Actions: arbitrary asynchronous operations.
const actions = {
  addAlert({ commit, state }, alert) {
    // check for a existing / visible copy of this alert
    const existingAlertIndex = getExistingAlertIndex(state.alerts)(alert);
    // this one isn't being shown yet so add it
    if (existingAlertIndex < 0) {
      // set timeout first so we can add it to the alert
      const timeoutId = setTimeout(() => {
        commit('REMOVE_ALERT', state.alerts.length - 1);
      }, alert.delay ?? 8000);

      commit('ADD_ALERT', { ...alert, timeoutId, alertCount: 1 });
    } else {
      // clear the timeout on the current alert
      const existingAlert = state.alerts[existingAlertIndex];
      // console.log(existingAlert, existingAlertIndex)
      if (existingAlert && existingAlert.timeoutId) {
        clearTimeout(existingAlert.timeoutId);
      }
      // console.log(existingAlert.alertCount);

      const timeoutId = setTimeout(() => {
        commit('REMOVE_ALERT', existingAlertIndex);
      }, alert.delay ?? 8000);

      commit('UPDATE_ALERT', {
        existingAlertIndex,
        alert: {
          ...alert,
          alertCount: existingAlert.alertCount + 1,
          timeoutId,
        },
      });
    }
  },
  removeAlert({ commit }, index) {
    commit('REMOVE_ALERT', index);
  },
  removeAllAlerts({ commit }) {
    commit('REMOVE_ALL_ALERTS');
  },
};

const getters = {
  alerts: (state) => {
    return state.alerts;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
