/* These are BMS Hardware Device State items - 
taken from List Values: https://bms.kaseya.com/api/system/lists/60
The id & value will be used on any updates to BMS.HardwareAsset.State fields
DO NOT change or add an id value w/o verifying the value from api call to bms
*/
export default [
  { id: 1, name: 'Expired', value: 'Expired', isActive: true },
  { id: 2, name: 'In Repair', value: 'In Repair', isActive: true },
  { id: 6, name: 'Assigned', value: 'Assigned', isActive: true },
  { id: 7, name: 'Provisioned', value: 'Provisioned', isActive: true },
  { id: 8, name: 'In Stock', value: 'In Stock', isActive: false },
  { id: 9, name: 'Pending Vendor', value: 'Pending Vendor', isActive: true },
  {
    id: 10,
    name: 'Unable to Repair',
    value: 'Unable to Repair',
    isActive: true,
  },
  { id: 11, name: 'In-Repair', value: 'In-Repair', isActive: true },
  { id: 999, name: 'Not Found', value: 'Not Found', isActive: true },
];
